/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriptionPlanCloudBackup { 
    /**
     * Charge rate for storing one VM in backup on a cloud repository, per month.
     */
    vmCloudBackupsPrice?: number;
    /**
     * Charge rate for storing backup data of one server computer on a cloud repository, per month.
     */
    serverCloudBackupsPrice?: number;
    /**
     * Charge rate for storing backup data for one workstation computer on a cloud repository, per month.
     */
    workstationCloudBackupsPrice?: number;
    /**
     * Type of charge rate for storage space on a cloud repository.
     */
    cloudRepositorySpaceUsageAlgorithm?: SubscriptionPlanCloudBackup.CloudRepositorySpaceUsageAlgorithmEnum;
    /**
     * Charge rate for one GB or TB of allocated storage space on a cloud repository.
     */
    cloudRepositoryAllocatedSpacePrice?: number;
    /**
     * Measurement units of allocated storage space on a cloud repository.
     */
    cloudRepositoryAllocatedSpaceUnits?: SubscriptionPlanCloudBackup.CloudRepositoryAllocatedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of consumed storage space on a cloud repository.
     */
    cloudRepositoryConsumedSpacePrice?: number;
    /**
     * Measurement units of consumed storage space on a cloud repository.
     */
    cloudRepositoryConsumedSpaceUnits?: SubscriptionPlanCloudBackup.CloudRepositoryConsumedSpaceUnitsEnum;
    /**
     * Amount of storage space that can be consumed by backup files for free.
     */
    freeCloudRepositoryConsumedSpace?: number;
    /**
     * Measurement units of storage space that can be consumed by backup files for free.
     */
    freeCloudRepositoryConsumedSpaceUnits?: SubscriptionPlanCloudBackup.FreeCloudRepositoryConsumedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of data downloaded from a cloud repository
     */
    backupDataTransferOutPrice?: number;
    /**
     * Measurement units of data downloaded from a cloud repository.
     */
    backupDataTransferOutUnits?: SubscriptionPlanCloudBackup.BackupDataTransferOutUnitsEnum;
    /**
     * Charge rate for cloud repository space that is consumed by deleted backup files.
     */
    insiderProtectionUsedSpacePrice?: number;
    /**
     * Measurement units for cloud repository space that is consumed by deleted backup files.
     */
    insiderProtectionUsedSpaceUnits?: SubscriptionPlanCloudBackup.InsiderProtectionUsedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of consumed performance tier space.
     */
    performanceTierUsedSpacePrice?: number;
    /**
     * Measurement units of consumed performance tier space.
     */
    performanceTierUsedSpaceUnits?: SubscriptionPlanCloudBackup.PerformanceTierUsedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of consumed capacity tier space.
     */
    capacityTierUsedSpacePrice?: number;
    /**
     * Measurement units of consumed capacity tier space.
     */
    capacityTierUsedSpaceUnits?: SubscriptionPlanCloudBackup.CapacityTierUsedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of consumed archive tier space.
     */
    archiveTierUsedSpacePrice?: number;
    /**
     * Measurement units of consumed archive tier space.
     */
    archiveTierUsedSpaceUnits?: SubscriptionPlanCloudBackup.ArchiveTierUsedSpaceUnitsEnum;
}
export namespace SubscriptionPlanCloudBackup {
    export type CloudRepositorySpaceUsageAlgorithmEnum = 'Allocated' | 'Consumed' | 'Granular';
    export const CloudRepositorySpaceUsageAlgorithmEnum = {
        Allocated: 'Allocated' as CloudRepositorySpaceUsageAlgorithmEnum,
        Consumed: 'Consumed' as CloudRepositorySpaceUsageAlgorithmEnum,
        Granular: 'Granular' as CloudRepositorySpaceUsageAlgorithmEnum
    };
    export type CloudRepositoryAllocatedSpaceUnitsEnum = 'GB' | 'TB';
    export const CloudRepositoryAllocatedSpaceUnitsEnum = {
        GB: 'GB' as CloudRepositoryAllocatedSpaceUnitsEnum,
        TB: 'TB' as CloudRepositoryAllocatedSpaceUnitsEnum
    };
    export type CloudRepositoryConsumedSpaceUnitsEnum = 'GB' | 'TB';
    export const CloudRepositoryConsumedSpaceUnitsEnum = {
        GB: 'GB' as CloudRepositoryConsumedSpaceUnitsEnum,
        TB: 'TB' as CloudRepositoryConsumedSpaceUnitsEnum
    };
    export type FreeCloudRepositoryConsumedSpaceUnitsEnum = 'GB' | 'TB';
    export const FreeCloudRepositoryConsumedSpaceUnitsEnum = {
        GB: 'GB' as FreeCloudRepositoryConsumedSpaceUnitsEnum,
        TB: 'TB' as FreeCloudRepositoryConsumedSpaceUnitsEnum
    };
    export type BackupDataTransferOutUnitsEnum = 'GB' | 'TB';
    export const BackupDataTransferOutUnitsEnum = {
        GB: 'GB' as BackupDataTransferOutUnitsEnum,
        TB: 'TB' as BackupDataTransferOutUnitsEnum
    };
    export type InsiderProtectionUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const InsiderProtectionUsedSpaceUnitsEnum = {
        GB: 'GB' as InsiderProtectionUsedSpaceUnitsEnum,
        TB: 'TB' as InsiderProtectionUsedSpaceUnitsEnum
    };
    export type PerformanceTierUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const PerformanceTierUsedSpaceUnitsEnum = {
        GB: 'GB' as PerformanceTierUsedSpaceUnitsEnum,
        TB: 'TB' as PerformanceTierUsedSpaceUnitsEnum
    };
    export type CapacityTierUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const CapacityTierUsedSpaceUnitsEnum = {
        GB: 'GB' as CapacityTierUsedSpaceUnitsEnum,
        TB: 'TB' as CapacityTierUsedSpaceUnitsEnum
    };
    export type ArchiveTierUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const ArchiveTierUsedSpaceUnitsEnum = {
        GB: 'GB' as ArchiveTierUsedSpaceUnitsEnum,
        TB: 'TB' as ArchiveTierUsedSpaceUnitsEnum
    };
}