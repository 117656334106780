import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import { WizardStep, WizardSummary } from '@veeam-vspc/components';
import { UserRoles } from 'core/enums';
import { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';
import { ManagedCompaniesMode } from 'features/RolesAndUsers/wizards/AdminUser/enums/managed-companies-mode';
import { WizardStepData } from '@veeam-vspc/components/src/index';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const Summary = ({ data }: WizardStepData<SSORuleModel>) => {
    const langs = useLangs();
    const roleMap = {
        [UserRoles.PortalReadonlyOperator]: langs.PORTAL_READONLY_OPERATOR,
        [UserRoles.PortalOperator]: langs.PORTAL_OPERATOR,
        [UserRoles.PortalAdministrator]: langs.PORTAL_ADMINISTRATOR,
    };

    return (
        <StepLayout
            title={langs.SUMMARY}
            description={langs.REVIEW_RULE_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${langs.RULE_INFO}:`,
                        fields: [
                            {
                                label: `${langs.NAME}:`,
                                value: data.model.name
                            },
                        ]
                    },
                    {
                        title: `${langs.ROLE}:`,
                        fields: [
                            {
                                label: `${langs.ROLE}:`,
                                value: roleMap[data.model.role]
                            },
                        ]
                    },
                    {
                        title: `${langs.COMPANIES}:`,
                        fields: [
                            {
                                label: `${langs.COMPANIES}:`,
                                value: data.selectAllCompanies === ManagedCompaniesMode.AllCompanies || data.model.role === UserRoles.PortalAdministrator ? langs.ALL : data.selectedCompanies.length
                            },
                        ]
                    },
                    {
                        title: `${langs.CONDITIONS}:`,
                        fields: [
                            {
                                label: `${langs.NAME}:`,
                                value: data.model.organizationMappingSourceClaimType,
                            },
                            {
                                label: `${langs.PARAMETERS}:`,
                                value: data.model.additionalMappings?.length ?? 0,
                            },
                        ]
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: (data) => <Summary {...data} />,
    id: 'summary',
});
