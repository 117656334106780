import React from 'react';
import type { AppStorage } from '@veeam-vspc/core';
import { AppStorageProvider } from '@veeam-vspc/components';

export interface Props {
    appStorage: AppStorage; // @TODO When finish switching to full React App, should create AppStorage instance here NOT pass it
    children: React.ReactElement;
}

/**
 * @description Should be under NotificationLayout, because in future will show dialog about version change
 */
export const AppStorageLayout = ({ appStorage, children }: Props) => (
    <AppStorageProvider appStorage={appStorage}>
        {children}
    </AppStorageProvider>
);
