/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface JobScheduleWindowDay { 
    /**
     * Days when job runs are permitted.
     */
    day: JobScheduleWindowDay.DayEnum;
    /**
     * Hours when job runs are permitted. > Empty array indicates that job runs are denied during the whole day. 
     */
    hours?: Array<number>;
}
export namespace JobScheduleWindowDay {
    export type DayEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const DayEnum = {
        Sunday: 'Sunday' as DayEnum,
        Monday: 'Monday' as DayEnum,
        Tuesday: 'Tuesday' as DayEnum,
        Wednesday: 'Wednesday' as DayEnum,
        Thursday: 'Thursday' as DayEnum,
        Friday: 'Friday' as DayEnum,
        Saturday: 'Saturday' as DayEnum
    };
}