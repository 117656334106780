/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MonthlyOrWeeklyScheduleWithDaySettings } from './monthlyOrWeeklyScheduleWithDaySettings';

export interface WindowsAdvancedScheduleSettings { 
    /**
     * Week days on which creation of synthetic full backups is scheduled > The `null` value indicates that periodic creation of synthetic full backups is disabled. 
     */
    syntheticFullOnDays?: Array<WindowsAdvancedScheduleSettings.SyntheticFullOnDaysEnum>;
    /**
     * Scheduling settings for periodically created active full backups. > The `null` value indicates that periodic creation of active full backups is disabled. 
     */
    activeFullSettings?: MonthlyOrWeeklyScheduleWithDaySettings;
}
export namespace WindowsAdvancedScheduleSettings {
    export type SyntheticFullOnDaysEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const SyntheticFullOnDaysEnum = {
        Sunday: 'Sunday' as SyntheticFullOnDaysEnum,
        Monday: 'Monday' as SyntheticFullOnDaysEnum,
        Tuesday: 'Tuesday' as SyntheticFullOnDaysEnum,
        Wednesday: 'Wednesday' as SyntheticFullOnDaysEnum,
        Thursday: 'Thursday' as SyntheticFullOnDaysEnum,
        Friday: 'Friday' as SyntheticFullOnDaysEnum,
        Saturday: 'Saturday' as SyntheticFullOnDaysEnum
    };
}