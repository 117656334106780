/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Credentials } from './credentials';
import { UserBackupResourceInput } from './userBackupResourceInput';
import { UserProfile } from './userProfile';

export interface UserInput { 
    /**
     * UID assigned to a user.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid: string;
    /**
     * User role in Veeam Service Provider Console.
     */
    role: UserInput.RoleEnum;
    /**
     * User MFA policy status.
     */
    mfaPolicyStatus?: UserInput.MfaPolicyStatusEnum;
    /**
     * User profile.
     */
    profile: UserProfile;
    /**
     * User credentials.
     */
    credentials: Credentials;
    /**
     * Backup resource configured for a subtenant account.
     */
    backupResource?: UserBackupResourceInput;
}
export namespace UserInput {
    export type RoleEnum = 'Unknown' | 'PortalAdministrator' | 'PortalOperator' | 'PortalReadonlyOperator' | 'SiteAdministrator' | 'CompanyLocationAdministrator' | 'CompanyLocationUser' | 'CompanyOwner' | 'CompanyAdministrator' | 'CompanyInvoiceAuditor' | 'CompanySubtenant' | 'ResellerOwner' | 'ResellerOperator' | 'ResellerUser' | 'ResellerInvoiceAuditor' | 'ResellerAdministrator';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        PortalAdministrator: 'PortalAdministrator' as RoleEnum,
        PortalOperator: 'PortalOperator' as RoleEnum,
        PortalReadonlyOperator: 'PortalReadonlyOperator' as RoleEnum,
        SiteAdministrator: 'SiteAdministrator' as RoleEnum,
        CompanyLocationAdministrator: 'CompanyLocationAdministrator' as RoleEnum,
        CompanyLocationUser: 'CompanyLocationUser' as RoleEnum,
        CompanyOwner: 'CompanyOwner' as RoleEnum,
        CompanyAdministrator: 'CompanyAdministrator' as RoleEnum,
        CompanyInvoiceAuditor: 'CompanyInvoiceAuditor' as RoleEnum,
        CompanySubtenant: 'CompanySubtenant' as RoleEnum,
        ResellerOwner: 'ResellerOwner' as RoleEnum,
        ResellerOperator: 'ResellerOperator' as RoleEnum,
        ResellerUser: 'ResellerUser' as RoleEnum,
        ResellerInvoiceAuditor: 'ResellerInvoiceAuditor' as RoleEnum,
        ResellerAdministrator: 'ResellerAdministrator' as RoleEnum
    };
    export type MfaPolicyStatusEnum = 'Unknown' | 'Disabled' | 'Enabled';
    export const MfaPolicyStatusEnum = {
        Unknown: 'Unknown' as MfaPolicyStatusEnum,
        Disabled: 'Disabled' as MfaPolicyStatusEnum,
        Enabled: 'Enabled' as MfaPolicyStatusEnum
    };
}