/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinuxDiscoveryCredentials { 
    /**
     * UID assigned to a set of credentials
     */
    readonly instanceUid?: string;
    /**
     * Username.
     */
    username: string;
    /**
     * Password.
     */
    password?: string;
    /**
     * Priority level of credentials.
     */
    priority?: number;
    /**
     * Credentials description.
     */
    description?: string;
    /**
     * SSH port that must be used to connect to a Linux server.
     */
    sshPort?: number;
    /**
     * Indicates whether a non-root account must be provided with root account privileges.
     */
    elevateAccountPrivileges?: boolean;
    /**
     * Indicates whether an account must be added to sudoers file.
     */
    addAccountToSudoersFile?: boolean;
    /**
     * Indicates whether the `su` command can be used instead of the `sudo` command.
     */
    useSuIfsudoFails?: boolean;
    /**
     * Password for a root account.
     */
    rootPassword?: string;
    /**
     * SSH private key.
     */
    sshPrivateKey?: string;
    /**
     * Passphrase for the private key.
     */
    passphrase?: string;
    /**
     * Type of Linux credentials.
     */
    type: LinuxDiscoveryCredentials.TypeEnum;
}
export namespace LinuxDiscoveryCredentials {
    export type TypeEnum = 'Unknown' | 'LinuxBased' | 'LinuxCertificate';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        LinuxBased: 'LinuxBased' as TypeEnum,
        LinuxCertificate: 'LinuxCertificate' as TypeEnum
    };
}