/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupWanAccelerator { 
    /**
     * UID assigned to a WAN accelerator.
     */
    readonly instanceUid?: string;
    /**
     * Name of a WAN accelerator.
     */
    readonly name?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * Version of a WAN accelerator service.
     */
    readonly version?: string;
    /**
     * UID assigned to a computer that performs a role of a WAN accelerator.
     */
    readonly hostUid?: string;
    /**
     * Name of a computer that performs a role of a WAN accelerator.
     */
    readonly hostName?: string;
    /**
     * Indicates whether a WAN accelerator service is outdated.
     */
    readonly isOutOfDate?: boolean;
    /**
     * WAN accelerator status.
     */
    readonly status?: BackupWanAccelerator.StatusEnum;
    /**
     * Indicates whether a WAN accelerator is used in the Veeam Cloud Connect infrastructure.
     */
    readonly isCloud?: boolean;
}
export namespace BackupWanAccelerator {
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Warning' | 'Error';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum
    };
}