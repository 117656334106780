/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsWorkstationJobEventTriggerSettings { 
    /**
     * Indicates whether a scheduled backup job must start when the user locks the computer.
     */
    backupOnLock?: boolean;
    /**
     * Indicates whether a scheduled backup job must start when the user working with the computer performs a logout operation.
     */
    backupOnLogOff?: boolean;
    /**
     * Indicates whether a scheduled backup job must start when the backup storage becomes available.
     */
    backupOnTargetConnection?: boolean;
    /**
     * Indicates whether Veeam Agent for Microsoft Windows must unmount the storage device after the backup job completes successfully. > Cannot be enabled if the `backupOnTargetConnection` property has the `false` value. 
     */
    ejectTargetOnBackupComplete?: boolean;
    /**
     * Minutely, hourly or daily interval between the backup job sessions.
     */
    backupNotOften?: number;
    /**
     * Measurement units for interval between the backup job sessions.
     */
    notOftenTimeUnit?: WindowsWorkstationJobEventTriggerSettings.NotOftenTimeUnitEnum;
}
export namespace WindowsWorkstationJobEventTriggerSettings {
    export type NotOftenTimeUnitEnum = 'Minutes' | 'Hours' | 'Days';
    export const NotOftenTimeUnitEnum = {
        Minutes: 'Minutes' as NotOftenTimeUnitEnum,
        Hours: 'Hours' as NotOftenTimeUnitEnum,
        Days: 'Days' as NotOftenTimeUnitEnum
    };
}