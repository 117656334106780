/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscoveryRuleFilter } from './discoveryRuleFilter';
import { DiscoveryRuleNotificationSettings } from './discoveryRuleNotificationSettings';
import { DiscoveryRuleScheduleSettings } from './discoveryRuleScheduleSettings';

export interface DiscoveryRule { 
    /**
     * UID assigned to a discovery rule.
     */
    readonly instanceUid?: string;
    /**
     * Name of a discovery rule
     */
    name: string;
    /**
     * UID assigned to a master agent.
     */
    masterAgentUid: string;
    /**
     * UID assigned to a location for which a discovery rule is configured.
     */
    readonly locationUid?: string;
    /**
     * UID assigned to a company for which a discovery rule is configured.
     */
    readonly companyUid?: string;
    /**
     * Type of guest OS.
     */
    readonly systemType?: DiscoveryRule.SystemTypeEnum;
    /**
     * Current status of a discovery rule.
     */
    readonly status?: DiscoveryRule.StatusEnum;
    /**
     * Date and time of the latest discovery session.
     */
    readonly lastRun?: Date;
    /**
     * Discovery filter.
     */
    filter?: DiscoveryRuleFilter;
    /**
     * Settings configured for email notifications about discovery results.
     */
    notificationSettings?: DiscoveryRuleNotificationSettings;
    /**
     * Discovery scheduling settings.
     */
    scheduleSettings?: DiscoveryRuleScheduleSettings;
    /**
     * Number of discovered computers.
     */
    readonly totalComputersCount?: number;
    /**
     * Number of online computers.
     */
    readonly onlineComputersCount?: number;
    /**
     * Number of offline computers.
     */
    readonly offlineComputersCount?: number;
}
export namespace DiscoveryRule {
    export type SystemTypeEnum = 'Unknown' | 'Windows' | 'Linux';
    export const SystemTypeEnum = {
        Unknown: 'Unknown' as SystemTypeEnum,
        Windows: 'Windows' as SystemTypeEnum,
        Linux: 'Linux' as SystemTypeEnum
    };
    export type StatusEnum = 'Unknown' | 'Created' | 'Running' | 'Success' | 'Canceled' | 'Failed' | 'CancellationRequested' | 'CancellationRequestFailed';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Created: 'Created' as StatusEnum,
        Running: 'Running' as StatusEnum,
        Success: 'Success' as StatusEnum,
        Canceled: 'Canceled' as StatusEnum,
        Failed: 'Failed' as StatusEnum,
        CancellationRequested: 'CancellationRequested' as StatusEnum,
        CancellationRequestFailed: 'CancellationRequestFailed' as StatusEnum
    };
}