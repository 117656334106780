/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ResponseError { 
    /**
     * Error message. > Every line break is represented by the `\\r\\n` control characters. 
     */
    message: string;
    /**
     * Error type.
     */
    type: ResponseError.TypeEnum;
    /**
     * Error code.
     */
    code: number;
}
export namespace ResponseError {
    export type TypeEnum = 'unspecified' | 'transport' | 'logical' | 'retryableLogical' | 'security';
    export const TypeEnum = {
        Unspecified: 'unspecified' as TypeEnum,
        Transport: 'transport' as TypeEnum,
        Logical: 'logical' as TypeEnum,
        RetryableLogical: 'retryableLogical' as TypeEnum,
        Security: 'security' as TypeEnum
    };
}