/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeploymentLogEntry } from './deploymentLogEntry';

export interface DeploymentLog { 
    /**
     * Percentage of deployment progress.
     */
    readonly completePercentage?: number;
    /**
     * Deployment status.
     */
    readonly status?: DeploymentLog.StatusEnum;
    /**
     * Log entry containing details about deployment process.
     */
    readonly logEntries?: Array<DeploymentLogEntry>;
}
export namespace DeploymentLog {
    export type StatusEnum = 'success' | 'failed' | 'warning';
    export const StatusEnum = {
        Success: 'success' as StatusEnum,
        Failed: 'failed' as StatusEnum,
        Warning: 'warning' as StatusEnum
    };
}