/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerJob { 
    /**
     * UID assigned to a job configured on a client Veeam Backup & Replication server.
     */
    readonly instanceUid?: string;
    /**
     * Name of a job configured on a client Veeam Backup & Replication server.
     */
    readonly name?: string;
    /**
     * UID assigned to a client Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server location.
     */
    readonly locationUid?: string;
    /**
     * UID assigned to a site.
     */
    readonly siteUid?: string;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid?: string;
    /**
     * Status of the latest job session. > Can be changed to Running or Stopping using the PATCH endpoint. 
     */
    status: BackupServerJob.StatusEnum;
    /**
     * Type of a job configured on a client Veeam Backup & Replication server.
     */
    readonly type?: BackupServerJob.TypeEnum;
    /**
     * Date and time when the latest job session started.
     */
    readonly lastRun?: Date;
    /**
     * Date and time when the latest job session ended.
     */
    readonly lastEndTime?: Date;
    /**
     * Duration of the latest job session, in seconds.
     */
    readonly lastDuration?: number;
    /**
     * Rate at which VM data was processed during the latest job session
     */
    readonly processingRate?: number;
    /**
     * Average time a job session takes to complete, in seconds.
     */
    readonly avgDuration?: number;
    /**
     * Total amount of data that was transferred to target during the latest job session, in bytes.
     */
    readonly transferredData?: number;
    /**
     * Bottleneck in the process of transferring the data from source to target.
     */
    readonly bottleneck?: BackupServerJob.BottleneckEnum;
    /**
     * Indicates whether a job schedule is enabled.  > Can be changed using the PATCH endpoint. 
     */
    isEnabled: boolean;
    /**
     * Type of a schedule configured for a job.
     */
    readonly scheduleType?: BackupServerJob.ScheduleTypeEnum;
    /**
     * Message that is displayed in case a backup job fails.  > Every line break is represented by the `\\r\\n` control characters. 
     */
    readonly failureMessage?: string;
    /**
     * Type of a target backup location.
     */
    readonly targetType?: BackupServerJob.TargetTypeEnum;
    /**
     * Name of a target backup location.
     */
    readonly destination?: string;
}
export namespace BackupServerJob {
    export type StatusEnum = 'Unknown' | 'None' | 'Idle' | 'Success' | 'Warning' | 'Failed' | 'Running' | 'Starting' | 'Stopping' | 'Enabling' | 'Disabling' | 'WaitingTape' | 'WaitingRepository';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        None: 'None' as StatusEnum,
        Idle: 'Idle' as StatusEnum,
        Success: 'Success' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Failed: 'Failed' as StatusEnum,
        Running: 'Running' as StatusEnum,
        Starting: 'Starting' as StatusEnum,
        Stopping: 'Stopping' as StatusEnum,
        Enabling: 'Enabling' as StatusEnum,
        Disabling: 'Disabling' as StatusEnum,
        WaitingTape: 'WaitingTape' as StatusEnum,
        WaitingRepository: 'WaitingRepository' as StatusEnum
    };
    export type TypeEnum = 'Unknown' | 'BackupVm' | 'ReplicationVM' | 'CopyVm' | 'CopyFile' | 'FileToTape' | 'BackupToTape' | 'BackupCopy' | 'ImmediatelyBackupCopy' | 'SqlLogBackup' | 'OracleLogBackup' | 'SureBackup' | 'AgentPolicy' | 'AgentBackupJob' | 'BackupFile' | 'BackupFileCopy' | 'AzureBackupJob' | 'AwsBackupJob';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        BackupVm: 'BackupVm' as TypeEnum,
        ReplicationVM: 'ReplicationVM' as TypeEnum,
        CopyVm: 'CopyVm' as TypeEnum,
        CopyFile: 'CopyFile' as TypeEnum,
        FileToTape: 'FileToTape' as TypeEnum,
        BackupToTape: 'BackupToTape' as TypeEnum,
        BackupCopy: 'BackupCopy' as TypeEnum,
        ImmediatelyBackupCopy: 'ImmediatelyBackupCopy' as TypeEnum,
        SqlLogBackup: 'SqlLogBackup' as TypeEnum,
        OracleLogBackup: 'OracleLogBackup' as TypeEnum,
        SureBackup: 'SureBackup' as TypeEnum,
        AgentPolicy: 'AgentPolicy' as TypeEnum,
        AgentBackupJob: 'AgentBackupJob' as TypeEnum,
        BackupFile: 'BackupFile' as TypeEnum,
        BackupFileCopy: 'BackupFileCopy' as TypeEnum,
        AzureBackupJob: 'AzureBackupJob' as TypeEnum,
        AwsBackupJob: 'AwsBackupJob' as TypeEnum
    };
    export type BottleneckEnum = 'Unknown' | 'Network' | 'None' | 'Proxy' | 'Source' | 'SourceWanAccelerator' | 'Target' | 'TargetWanAccelerator';
    export const BottleneckEnum = {
        Unknown: 'Unknown' as BottleneckEnum,
        Network: 'Network' as BottleneckEnum,
        None: 'None' as BottleneckEnum,
        Proxy: 'Proxy' as BottleneckEnum,
        Source: 'Source' as BottleneckEnum,
        SourceWanAccelerator: 'SourceWanAccelerator' as BottleneckEnum,
        Target: 'Target' as BottleneckEnum,
        TargetWanAccelerator: 'TargetWanAccelerator' as BottleneckEnum
    };
    export type ScheduleTypeEnum = 'Unknown' | 'NotScheduled' | 'Daily' | 'Monthly' | 'Periodically' | 'Continuously' | 'BackupWindow' | 'Chained';
    export const ScheduleTypeEnum = {
        Unknown: 'Unknown' as ScheduleTypeEnum,
        NotScheduled: 'NotScheduled' as ScheduleTypeEnum,
        Daily: 'Daily' as ScheduleTypeEnum,
        Monthly: 'Monthly' as ScheduleTypeEnum,
        Periodically: 'Periodically' as ScheduleTypeEnum,
        Continuously: 'Continuously' as ScheduleTypeEnum,
        BackupWindow: 'BackupWindow' as ScheduleTypeEnum,
        Chained: 'Chained' as ScheduleTypeEnum
    };
    export type TargetTypeEnum = 'Unknown' | 'Local' | 'Cloud';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        Local: 'Local' as TargetTypeEnum,
        Cloud: 'Cloud' as TargetTypeEnum
    };
}