import React from 'react';
import { NotificationResponse } from '@veeam-vspc/core';
import { NotificationDialogsTextKeys } from '@veeam-vspc/components';

import { useAppActions, useAppServices, useAppStore } from '../../hooks';
import { useLangs } from '../../../LangLayout/hooks';

export const BeforeAppDialogs = () => {
    const { notificationService } = useAppServices();
    const { openLogin, openApp } = useAppActions();
    const appStore = useAppStore();
    const { portalUser, message } = appStore;
    const langs = useLangs();

    Promise.resolve()
        .then(() => {
            if (portalUser && portalUser.companyStatus === RCOP.Enums.CompanyStatus.Disabled) {
                return notificationService.confirm(langs.COMPANY_STATE, langs.READ_ONLY_COMPANY_STATUS)
                    .then((btnKey: NotificationResponse) => btnKey === NotificationDialogsTextKeys.Ok ? Promise.resolve() : Promise.reject());
            }
        })
        .then(() => {
            if (message) {
                return notificationService.warning(langs.LOGIN, message);
            }
        })
        .then(() => openApp())
        .catch(() => openLogin());

    return <div />;
};
