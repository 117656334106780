/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { JobScheduleWindowDay } from './jobScheduleWindowDay';
import { WindowsContinuousScheduleSettings } from './windowsContinuousScheduleSettings';
import { WindowsDailyScheduleSettings } from './windowsDailyScheduleSettings';
import { WindowsMonthlyScheduleSettings } from './windowsMonthlyScheduleSettings';
import { WindowsPeriodicalScheduleSettings } from './windowsPeriodicalScheduleSettings';
import { WindowsServerJobRetrySettings } from './windowsServerJobRetrySettings';

export interface WindowsServerJobScheduleSettings { 
    /**
     * Type of periodicity.
     */
    scheduleType?: WindowsServerJobScheduleSettings.ScheduleTypeEnum;
    /**
     * Scheduling settings required for a daily running job.
     */
    dailyScheduleSettings?: WindowsDailyScheduleSettings;
    /**
     * Scheduling settings required for a monthly running job.
     */
    monthlyScheduleSettings?: WindowsMonthlyScheduleSettings;
    /**
     * Scheduling settings required to run a job repeatedly throughout a day.
     */
    periodicalScheduleSettings?: WindowsPeriodicalScheduleSettings;
    /**
     * Scheduling settings for a continuously running job. > The `null` value indicates that a job can be run at any time. 
     */
    continuousScheduleSettings?: WindowsContinuousScheduleSettings;
    /**
     * Automatic retry settings.
     */
    retrySettings?: WindowsServerJobRetrySettings;
    /**
     * Time interval within which a job must complete. > The `null` value indicates that a job can be run at any time. 
     */
    backupWindow?: Array<JobScheduleWindowDay>;
}
export namespace WindowsServerJobScheduleSettings {
    export type ScheduleTypeEnum = 'NotScheduled' | 'Daily' | 'Monthly' | 'Periodically' | 'Continuously';
    export const ScheduleTypeEnum = {
        NotScheduled: 'NotScheduled' as ScheduleTypeEnum,
        Daily: 'Daily' as ScheduleTypeEnum,
        Monthly: 'Monthly' as ScheduleTypeEnum,
        Periodically: 'Periodically' as ScheduleTypeEnum,
        Continuously: 'Continuously' as ScheduleTypeEnum
    };
}