import React from 'react';
import type { WizardStep, WizardStepData } from '@veeam-vspc/components';
import { CheckboxKit, WizardSummary } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import type { CompanyUserModel } from '../../interfaces/company-user-model';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { UserMfaStatuses } from '../../../Base/components/MultiFactorAuthentication/user-mfa-statuses';
import { ROLES } from '../../../../../../const';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { Unit } from '../../enums/unit';

const isLocationBlockAvailable = (role: PortalUserRoles) => role === PortalUserRoles.LocationUser
    || role === PortalUserRoles.LocationAdministrator
    || role === PortalUserRoles.Subtenant;

export const Summary: React.FC<WizardStepData<CompanyUserModel>> = ({ data, onDataChange }) => {
    const langs = useLangs();

    return (
        <StepLayout
            title={langs.SUMMARY}
            description={langs.REVIEW_USER_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${langs.USER_INFO}:`,
                        fields: [
                            {
                                label: `${langs.FIRST_NAME}:`,
                                value: data.firstName,
                            },
                            {
                                label: `${langs.LAST_NAME}:`,
                                value: data.lastName,
                            },
                            {
                                label: `${langs.EMAIL_ADDRESS}:`,
                                value: data.email,
                            },
                            {
                                label: `${langs.USERNAME}:`,
                                value: data.login || data.vcdUserName,
                            },
                        ],
                    },
                    {
                        title: `${langs.ROLE}:`,
                        fields: [
                            {
                                label: `${langs.ROLE}:`,
                                value: data.userRole,
                                transform: (value: PortalUserRoles) => ROLES[value],
                            },
                        ],
                    },
                    isLocationBlockAvailable(data.userRole) && {
                        title: `${langs.LOCATIONS}:`,
                        fields: [
                            {
                                label: `${langs.LOCATIONS}:`,
                                value: data.locations.map(location => location.name).join(', '),
                            },
                        ],
                    },
                    data.userRole === PortalUserRoles.Subtenant && {
                        title: `${langs.QUOTA}:`,
                        fields: [
                            {
                                label: `${langs.CLOUD_REPOSITORY_NAME}:`,
                                value: data.repositoryFriendlyName,
                            },
                            {
                                label: `${langs.USER_QUOTA}:`,
                                value: data.repositoryQuota,
                                transform: (repositoryQuota: number) => {
                                    if (data.isUnlimited) {
                                        return langs.UNLIMITED;
                                    }

                                    return `${repositoryQuota} ${data.repositoryQuotaUnit === Unit.GB ? langs.GB : langs.TB}`;
                                },
                            },
                        ],
                    },
                    {
                        title: `${langs.MULTI_FACTOR_AUTHENTICATION}:`,
                        fields: [
                            {
                                label: `${langs.MFA_STATUS}:`,
                                value: data.mfaPolicyStatus,
                                transform: (value: UserMfaStatuses) => value === UserMfaStatuses.Disabled ? langs.NOT_ENFORCED : langs.ENFORCED,
                            },
                        ],
                    },
                    {
                        renderBody: (data.userRole === PortalUserRoles.CompanyOwner || data.userRole === PortalUserRoles.CompanyInvoiceAuditor) &&
                            <CheckboxKit
                                checked={data.sendInvoice}
                                onChange={(isChecked) => {
                                    data.sendInvoice = isChecked;

                                    onDataChange(data);
                                }}
                            >
                                {langs.ENABLE_AUTOMATIC_DELIVERY_OF_INVOICES}
                            </CheckboxKit>,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    render: data => <Summary {...data} />,
});
