import React from 'react';
import { DayWrapper, DateTitle } from '../DayBase';
import { useStore } from '../../../../store/useStore';

interface Props {
    id: string;
}

export const NoData = ({ id }: Props) => {
    const store = useStore();
    const record = store.findById(id);

    return (
        <DayWrapper>
            <DateTitle>
                {record.displayDateForCell}
            </DateTitle>
        </DayWrapper>
    );
};
