import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import type {
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import {
    StackView,
    STACK_GAP,
    Combobox,
    STACK_DIRECTION,
    Form,
} from '@veeam-vspc/components';
import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';
import { UserRoles } from 'core/enums';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { getAdminDocUrl } from '../../../Base/helpers/getAdminDocUrl';
import { NoteBar } from 'components/layouts/NoteBar';

export const Role: React.FC<WizardStepData<SSORuleModel>> = ({ data, validationState, onDataChange }) => {
    const langs = useLangs();

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.ROLE}
                description={langs.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.role'
                        data={[
                            { value: UserRoles.PortalReadonlyOperator, text: langs.PORTAL_READONLY_OPERATOR },
                            { value: UserRoles.PortalOperator, text: langs.PORTAL_OPERATOR },
                            { value: UserRoles.PortalAdministrator, text: langs.PORTAL_ADMINISTRATOR },
                        ]}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={langs.ROLE}
                        inlineLabel
                    />
                    <NoteBar>
                        {data.model.role === UserRoles.PortalReadonlyOperator && <p>{langs.READ_ONLY_USERS_CAN_MONITOR_DATA}</p>}
                        {data.model.role === UserRoles.PortalOperator && <p>{langs.PORTAL_OPERATOR_CAN_PERFORM}</p>}
                        {data.model.role === UserRoles.PortalAdministrator && <p>{langs.PORTAL_ADMINISTRATOR_CAN_PERFORM}</p>}
                        <p>
                            {langs.CLICK} <a
                                href={getAdminDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {langs.HERE}
                            </a> {langs.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Role {...data}/>,
    id: 'role',
});
