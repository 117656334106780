/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedComputerManagedByBackupServer { 
    /**
     * UID assigned to a protected computer.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a backup server.
     */
    readonly backupServerUid?: string;
    /**
     * Protection group UIDs.
     */
    readonly protectionGroups?: Array<string>;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid?: string;
    /**
     * Hostname of a protected computer.
     */
    readonly name?: string;
    /**
     * Computer IP addresses.
     */
    readonly ipAddresses?: Array<string>;
    /**
     * Operating system installed on a protected computer.
     */
    readonly guestOs?: string;
    /**
     * Platform type of a protected computer.
     */
    readonly platformType?: ProtectedComputerManagedByBackupServer.PlatformTypeEnum;
    /**
     * Operation mode.
     */
    readonly operationMode?: ProtectedComputerManagedByBackupServer.OperationModeEnum;
    /**
     * Date of the latest restore point creation.
     */
    readonly latestRestorePointDate?: Date;
}
export namespace ProtectedComputerManagedByBackupServer {
    export type PlatformTypeEnum = 'Unknown' | 'Windows' | 'Linux' | 'Mac';
    export const PlatformTypeEnum = {
        Unknown: 'Unknown' as PlatformTypeEnum,
        Windows: 'Windows' as PlatformTypeEnum,
        Linux: 'Linux' as PlatformTypeEnum,
        Mac: 'Mac' as PlatformTypeEnum
    };
    export type OperationModeEnum = 'Unknown' | 'Server' | 'Workstation';
    export const OperationModeEnum = {
        Unknown: 'Unknown' as OperationModeEnum,
        Server: 'Server' as OperationModeEnum,
        Workstation: 'Workstation' as OperationModeEnum
    };
}