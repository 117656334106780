/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsOracleAccountSettings } from './windowsOracleAccountSettings';
import { WindowsPolicyPeriodicallyLogBackupSettings } from './windowsPolicyPeriodicallyLogBackupSettings';

export interface WindowsOracleTransactionLogHandlingSettings { 
    /**
     * Credentials of a user account that Veeam Agent for Microsoft Windows must use to connect to the Oracle database.
     */
    credentials?: WindowsOracleAccountSettings;
    /**
     * Archived log processing mode.
     */
    archivedLogsRetentionMode?: WindowsOracleTransactionLogHandlingSettings.ArchivedLogsRetentionModeEnum;
    /**
     * Amount of time after which archived logs must be deleted, in hours.  > Required for the `DeleteLogsOlderThanHours` archived log processing mode. 
     */
    backupLifeTimeHours?: number;
    /**
     * Maximum threshold for archived log file size, in GB. > If an archived log file exceeds the limitation, it is deleted. <br> > Required for the `DeleteLogsOverGb` archived log processing mode. 
     */
    backupSizeThresholdGb?: number;
    /**
     * Indicates whether Veeam Agent for Microsoft Windows must back up archive logs.
     */
    backupLogsPeriodically?: boolean;
    /**
     * Archive log backup settings.
     */
    periodicallyBackupSetting?: WindowsPolicyPeriodicallyLogBackupSettings;
}
export namespace WindowsOracleTransactionLogHandlingSettings {
    export type ArchivedLogsRetentionModeEnum = 'DoNotDeleteArchivedLogs' | 'DeleteLogsOlderThanHours' | 'DeleteLogsOverGb';
    export const ArchivedLogsRetentionModeEnum = {
        DoNotDeleteArchivedLogs: 'DoNotDeleteArchivedLogs' as ArchivedLogsRetentionModeEnum,
        DeleteLogsOlderThanHours: 'DeleteLogsOlderThanHours' as ArchivedLogsRetentionModeEnum,
        DeleteLogsOverGb: 'DeleteLogsOverGb' as ArchivedLogsRetentionModeEnum
    };
}