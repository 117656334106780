import React from 'react';
import type { PageWizardProps } from '@veeam-vspc/components';
import { PageWizard as PageWizardFromComponents, ThemeProvider } from '@veeam-vspc/components';

import { useAppServices } from 'views/layouts/AppLayout/hooks';

export const PageWizard = <T extends {}>(props: PageWizardProps<T>) => {
    const { portalService } = useAppServices();

    return (
        <ThemeProvider theme={portalService.getProductTheme()}>
            <PageWizardFromComponents
                hasBack
                onClose={() => document.dispatchEvent(new CustomEvent('RCOP/HIDE_PAGE_WIZARD'))}
                {...props}
            />
        </ThemeProvider>
    );
};
