/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CloudLicenseCounter { 
    /**
     * License type.
     */
    type: CloudLicenseCounter.TypeEnum;
    /**
     * License unit type.
     */
    readonly unitType: CloudLicenseCounter.UnitTypeEnum;
    /**
     * Number of units with rental licenses installed.
     */
    rentalUnits: number;
    /**
     * Number of units that were activated within the current calendar month.
     */
    newUnits: number;
    /**
     * Number of units that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    usedUnits: number;
    /**
     * Number of objects with rental licenses installed.
     */
    rentalCount: number;
    /**
     * Number of objects that were activated within the current calendar month.
     */
    newCount: number;
    /**
     * Number of objects that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    usedCount: number;
}
export namespace CloudLicenseCounter {
    export type TypeEnum = 'Unknown' | 'CC_Server_Backup' | 'CC_Workstation_Backup' | 'CC_VM_Backup' | 'CC_VM_Replica';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        CCServerBackup: 'CC_Server_Backup' as TypeEnum,
        CCWorkstationBackup: 'CC_Workstation_Backup' as TypeEnum,
        CCVMBackup: 'CC_VM_Backup' as TypeEnum,
        CCVMReplica: 'CC_VM_Replica' as TypeEnum
    };
    export type UnitTypeEnum = 'Unknown' | 'Instances' | 'Points';
    export const UnitTypeEnum = {
        Unknown: 'Unknown' as UnitTypeEnum,
        Instances: 'Instances' as UnitTypeEnum,
        Points: 'Points' as UnitTypeEnum
    };
}