/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Collation } from './collation';

export interface FilterExpression { 
    /**
     * Path to the required resource property.
     */
    property?: string;
    /**
     * Inner expressions. Can be used only with `and`, `or` and `not` operation.
     */
    items?: Array<FilterExpression>;
    /**
     * Filter operation.
     */
    operation: FilterExpression.OperationEnum;
    collation?: Collation;
    /**
     * Resource property value.
     */
    value?: any;
}
export namespace FilterExpression {
    export type OperationEnum = 'in' | 'contains' | 'subset' | 'superset' | 'equals' | 'notEquals' | 'lessThan' | 'lessThanOrEqual' | 'greaterThan' | 'greaterThanOrEqual' | 'exclusiveOr' | 'or' | 'and' | 'not';
    export const OperationEnum = {
        In: 'in' as OperationEnum,
        Contains: 'contains' as OperationEnum,
        Subset: 'subset' as OperationEnum,
        Superset: 'superset' as OperationEnum,
        Equals: 'equals' as OperationEnum,
        NotEquals: 'notEquals' as OperationEnum,
        LessThan: 'lessThan' as OperationEnum,
        LessThanOrEqual: 'lessThanOrEqual' as OperationEnum,
        GreaterThan: 'greaterThan' as OperationEnum,
        GreaterThanOrEqual: 'greaterThanOrEqual' as OperationEnum,
        ExclusiveOr: 'exclusiveOr' as OperationEnum,
        Or: 'or' as OperationEnum,
        And: 'and' as OperationEnum,
        Not: 'not' as OperationEnum
    };
}