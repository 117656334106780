import React from 'react';
import { DayType } from '../../enums/day-type';
import { NoData } from './components/NoData';
import { DayWithJobs } from './components/DayWithJobs';
import { DayWithoutJobs } from './components/DayWithoutJobs';
import { OutOfPeriod } from './components/OutOfPeriod';
import { openJobsDialog } from '../../interfaces/open-jobs-dialog';
import { useStore } from '../../store/useStore';

interface Props {
    id: string;
    openJobsDialog: openJobsDialog;
}

export const Day = ({ id, openJobsDialog }: Props) => {
    const store = useStore();
    const record = store.findById(id);

    switch (record.dayType) {
        case DayType.NoData:
            return <NoData id={id} />;
        case DayType.DayWithJobs:
            return <DayWithJobs id={id} openJobsDialog={openJobsDialog} />;
        case DayType.DayWithoutJobs:
            return <DayWithoutJobs id={id} />;
        case DayType.OutOfPeriod:
            return <OutOfPeriod id={id} />;
    }
};
