/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscoveryRuleCredentials } from './discoveryRuleCredentials';
import { DiscoveryRuleFilter } from './discoveryRuleFilter';
import { DiscoveryRuleNotificationSettings } from './discoveryRuleNotificationSettings';
import { DiscoveryRuleScheduleSettings } from './discoveryRuleScheduleSettings';
import { WindowsDiscoveryRuleDeploymentSettings } from './windowsDiscoveryRuleDeploymentSettings';

export interface WindowsActiveDirectoryBasedDiscoveryRuleInput { 
    /**
     * Name of an Active Directory discovery rule.
     */
    name: string;
    /**
     * UID assigned to a master agent.
     */
    masterAgentUid: string;
    /**
     * Number of days for which offline computers are skipped from discovery.
     */
    skipOfflineComputersDays?: number;
    /**
     * LDAP query that returns a list of computers to scan.
     */
    customQuery?: string;
    /**
     * Active Directory discovery method.
     */
    adMethod: WindowsActiveDirectoryBasedDiscoveryRuleInput.AdMethodEnum;
    /**
     * Use credentials specified in the master management agent configuration
     */
    useMasterManagementAgentCredentials?: boolean;
    /**
     * Credentials of an account with Local Administrator permissions on discovered computers.
     */
    accessAccount: DiscoveryRuleCredentials;
    /**
     * Discovery filter.
     */
    filter?: DiscoveryRuleFilter;
    /**
     * Settings configured for email notifications about discovery results.
     */
    notificationSettings?: DiscoveryRuleNotificationSettings;
    /**
     * Settings configured for Veeam backup agent deployment.
     */
    deploymentSettings?: WindowsDiscoveryRuleDeploymentSettings;
    /**
     * Discovery scheduling settings.
     */
    scheduleSettings?: DiscoveryRuleScheduleSettings;
}
export namespace WindowsActiveDirectoryBasedDiscoveryRuleInput {
    export type AdMethodEnum = 'Unknown' | 'Search' | 'Query' | 'Custom';
    export const AdMethodEnum = {
        Unknown: 'Unknown' as AdMethodEnum,
        Search: 'Search' as AdMethodEnum,
        Query: 'Query' as AdMethodEnum,
        Custom: 'Custom' as AdMethodEnum
    };
}