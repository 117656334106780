/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedComputerManagedByConsoleJob { 
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly backupAgentUid?: string;
    /**
     * UID assigned to a job that protects the computer.
     */
    readonly jobUid?: string;
    /**
     * Name of a job that protects the computer.
     */
    readonly jobName?: string;
    /**
     * Size of protected data, in bytes.
     */
    readonly sourceSize?: number;
    /**
     * Total size of all restore points, in bytes.
     */
    readonly totalRestorePointSize?: number;
    /**
     * Size of the latest restore point, in bytes.
     */
    readonly latestRestorePointSize?: number;
    /**
     * Number of restore points.
     */
    readonly restorePoints?: number;
    /**
     * Date of the latest restore point creation.
     */
    readonly latestRestorePointDate?: Date;
    /**
     * Type of a target repository.
     */
    readonly targetType?: ProtectedComputerManagedByConsoleJob.TargetTypeEnum;
}
export namespace ProtectedComputerManagedByConsoleJob {
    export type TargetTypeEnum = 'Unknown' | 'Local' | 'Cloud';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        Local: 'Local' as TargetTypeEnum,
        Cloud: 'Cloud' as TargetTypeEnum
    };
}