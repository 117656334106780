import React from 'react';
import type {
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';
import { UserRoles } from 'core/enums';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { getClientDocUrl } from '../../../Base/helpers/getClientDocUrl';
import { NoteBar } from 'components/layouts/NoteBar';


export const Role: React.FC<WizardStepData<SSORuleModel>> = ({ data, validationState, onDataChange }) => {
    const langs = useLangs();
    const roles = [
        { value: UserRoles.CompanyOwner, text: langs.COMPANY_OWNER },
        { value: UserRoles.CompanyAdministrator, text: langs.COMPANY_ADMINISTRATOR },
        { value: UserRoles.CompanyLocationAdministrator, text: langs.LOCATION_ADMINISTRATOR },
        { value: UserRoles.CompanyLocationUser, text: langs.LOCATION_USER },
        { value: UserRoles.CompanyInvoiceAuditor, text: langs.COMPANY_INVOICE_AUDITOR },
    ];

    if (RCOP.utils.Roles.isPortalAdmin()) {
        roles.push({ value: UserRoles.ResellerOwner, text: langs.SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR });
    }

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.ROLE}
                description={langs.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.role'
                        data={roles}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        size={CONTROL_SIZE.m}
                        label={langs.ROLE}
                        inlineLabel
                    />
                    <NoteBar>
                        {data.model.role === UserRoles.CompanyOwner && <p>{langs.COMPANY_OWNER_HAS_ACCESS_TO_ALL}</p>}
                        {data.model.role === UserRoles.CompanyAdministrator && <p>{langs.COMPANY_ADMINISTRATOR_HAS_ACCESS_TO_ALL_MONITORING}</p>}
                        {data.model.role === UserRoles.CompanyLocationAdministrator && <p>{langs.LOCATION_ADMINISTRATOR_HAS_ACCESS_TO_ALL_DATA}</p>}
                        {data.model.role === UserRoles.CompanyLocationUser && <p>{langs.LOCATION_USER_HAS_ACCESS_TO_THE_COMPANY}</p>}
                        {data.model.role === UserRoles.CompanyInvoiceAuditor && <p>{langs.COMPANY_INVOICE_AUDITOR_HAS_ACCESS}</p>}
                        {data.model.role === UserRoles.ResellerOwner && <p>{langs.SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR_OF_A_RESELLER}</p>}
                        <p>
                            {langs.CLICK} <a
                                href={getClientDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {langs.HERE}
                            </a> {langs.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Role {...data} />,
});
