/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxBaseCredentials } from './linuxBaseCredentials';

export interface LinuxPostgreSqlApplicationAwareProcessingSettings { 
    /**
     * PostgreSQL database processing type.
     */
    processingType?: LinuxPostgreSqlApplicationAwareProcessingSettings.ProcessingTypeEnum;
    /**
     * Credentials of a user account that Veeam Agent for Linux must use to connect to the PostgreSQL database.
     */
    credentials?: LinuxBaseCredentials;
    /**
     * Type of credentials format.
     */
    authType?: LinuxPostgreSqlApplicationAwareProcessingSettings.AuthTypeEnum;
}
export namespace LinuxPostgreSqlApplicationAwareProcessingSettings {
    export type ProcessingTypeEnum = 'DisableProcess' | 'TryProcess' | 'RequireSuccess';
    export const ProcessingTypeEnum = {
        DisableProcess: 'DisableProcess' as ProcessingTypeEnum,
        TryProcess: 'TryProcess' as ProcessingTypeEnum,
        RequireSuccess: 'RequireSuccess' as ProcessingTypeEnum
    };
    export type AuthTypeEnum = 'PSQLPassword' | 'PSQLPasswordFile' | 'PSQLPeer';
    export const AuthTypeEnum = {
        PSQLPassword: 'PSQLPassword' as AuthTypeEnum,
        PSQLPasswordFile: 'PSQLPasswordFile' as AuthTypeEnum,
        PSQLPeer: 'PSQLPeer' as AuthTypeEnum
    };
}