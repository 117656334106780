import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import type {
    WizardStep,
} from '@veeam-vspc/components';
import {
    Combobox,
    Form,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';
import type { AdminUserModel } from '../../interfaces/admin-user-model';
import { PrincipalRoleType } from '../../enums/principal-role-type';
import type { WizardStepData } from '@veeam-vspc/components/src/index';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { getAdminDocUrl } from '../../../Base/helpers/getAdminDocUrl';
import { NoteBar } from 'components/layouts/NoteBar';

export const Role: React.FC<WizardStepData<AdminUserModel>> = ({ data, validationState, onDataChange }) => {
    const langs = useLangs();
    const roles = [
        { value: PrincipalRoleType.ReadonlyUser, text: langs.PORTAL_READONLY_OPERATOR },
        { value: PrincipalRoleType.PortalOperator, text: langs.PORTAL_OPERATOR },
    ];

    if (RCOP.utils.Roles.isPortalAdmin()) {
        roles.push({ value: PrincipalRoleType.PortalAdministrator, text: langs.PORTAL_ADMINISTRATOR });
    }

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.ROLE}
                description={langs.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.roleType'
                        data={roles}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={langs.ROLE}
                        inlineLabel
                    />
                    <NoteBar>
                        {data.model.roleType === PrincipalRoleType.ReadonlyUser && <p>{langs.READ_ONLY_USERS_CAN_MONITOR_DATA}</p>}
                        {data.model.roleType === PrincipalRoleType.PortalOperator && <p>{langs.PORTAL_OPERATOR_CAN_PERFORM}</p>}
                        {data.model.roleType === PrincipalRoleType.PortalAdministrator && <p>{langs.PORTAL_ADMINISTRATOR_CAN_PERFORM}</p>}
                        <p>
                            {langs.CLICK} <a
                                href={getAdminDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {langs.HERE}
                            </a> {langs.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    render: data => <Role {...data} />,
});
