import type { IdentityProviderSettings } from 'swagger/models';

import type { PortalFormats, PortalLicense, PortalNotifications, PortalServiceTimeZone } from '../interfaces';
import { PortalUser } from '../../portal-user';
import type { LoginDataResponse } from '../../../services/portal/interfaces';
import { PortalFeatures } from '../../portal-features';
import { PortalDataParams } from '../interfaces';

export class PortalData {

    readonly appCreationDate: string;
    readonly success: boolean;
    readonly logged: boolean;
    readonly utcOffset: number;
    readonly version: string;
    readonly portalName: string;
    readonly isLocalAdmin: boolean;
    readonly tokenProlongationPeriod: number;
    readonly sessionExpirationTime: number;
    readonly webHostName: string;
    readonly currencySymbol: string;
    readonly isDebug: boolean;
    readonly backupType: number;
    readonly notifyVacCertificateNotInstalled: boolean;

    theme: string;
    locale: string;
    formats: PortalFormats;
    license: PortalLicense;
    licenseExpired: boolean;
    message: string | null;
    notifications: PortalNotifications;
    serviceTimeZone: PortalServiceTimeZone;
    identityProviders: IdentityProviderSettings[] | null;
    portalUser: PortalUser | null;
    portalFeatures: PortalFeatures | null;

    constructor(data: PortalDataParams = {} as PortalDataParams) {
        const {
            appCreationDate,
            success,
            utcOffset,
            version,
            colorScheme,
            portalName,
            formats,
            license,
            licenseExpired,
            message,
            notifications,
            serviceTimeZone,
            user,
            isLocalAdmin,
            logged,
            tokenProlongationPeriod,
            sessionExpirationTime,
            notifyVacCertificateNotInstalled,
            webHostName,
            currencySymbol,
            isDebug,
            backupType,
            identityProviders,
            featuresList,
        } = data;

        this.appCreationDate = appCreationDate;
        this.success = success || false;
        this.logged = logged || false;
        this.utcOffset = utcOffset || new Date().getTimezoneOffset();
        this.version = version || process.env.VERSION;
        this.portalName = portalName || 'Veeam Service Provider Console';
        this.isLocalAdmin = isLocalAdmin || false;
        this.tokenProlongationPeriod = tokenProlongationPeriod || 300;
        this.sessionExpirationTime = sessionExpirationTime || 3600;
        this.webHostName = webHostName || '';
        this.currencySymbol = currencySymbol || '';
        this.isDebug = isDebug || false;
        this.backupType = backupType;
        this.notifyVacCertificateNotInstalled = notifyVacCertificateNotInstalled || false;

        this.theme = colorScheme || 'blue';
        this.locale = 'en';
        this.formats = formats;
        this.license = license;
        this.licenseExpired = licenseExpired;
        this.message = message;
        this.notifications = notifications;
        this.serviceTimeZone = serviceTimeZone;
        this.identityProviders = identityProviders ?? null;
        this.portalFeatures = featuresList ? new PortalFeatures(featuresList) : null;
        this.portalUser = user ? new PortalUser(user) : null;
    }

}
