import { core } from 'core/core-module';
import type { SSORuleModel } from '../interfaces/sso-rule-model';
import type { IdentityProviderRoleMappingRule } from 'swagger/identityProviderRoleMappingRule';
import type { SSORuleProps } from '../interfaces/sso-rule-props';

interface SaveRuleParams {
    title: string;
    data: SSORuleModel;
    props: SSORuleProps;
    model: IdentityProviderRoleMappingRule;
}

export const saveRule = ({ title, data, props, model }: SaveRuleParams) => {
    const { identityProviderName } = data;
    const { organizationUid, isEdit } = props;

    if (isEdit) {
        return core.transportService.patch(
            `/organizations/${organizationUid}/identityProviders/${identityProviderName}/rules/${props.model.instanceUid}`,
            { original: props.model, current: model }
        )
            .then(() => props.onFinish())
            .catch(resp => RCOP.utils.MultiActionResult.handleRestErrors(resp, title).then(() => Promise.reject()));
    } else {
        return core.transportService.post(`/organizations/${organizationUid}/identityProviders/${identityProviderName}/rules`, model)
            .then(() => props.onFinish())
            .catch(resp => RCOP.utils.MultiActionResult.handleRestErrors(resp, title).then(() => Promise.reject()));
    }
};
