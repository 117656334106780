/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BrandingSettings {
    /**
     * Portal web address.
     */
    webUri: string;
    /**
     * Interface color scheme.
     */
    portalColorTheme?: BrandingSettings.PortalColorThemeEnum;
}
export namespace BrandingSettings {
    export type PortalColorThemeEnum = 'Unknown' | 'Blue' | 'Green' | 'Yellow' | 'Red' | 'Turquoise';
    export const PortalColorThemeEnum = {
        Unknown: 'Unknown' as PortalColorThemeEnum,
        Blue: 'Blue' as PortalColorThemeEnum,
        Green: 'Green' as PortalColorThemeEnum,
        Yellow: 'Yellow' as PortalColorThemeEnum,
        Red: 'Red' as PortalColorThemeEnum,
        Turquoise: 'Turquoise' as PortalColorThemeEnum
    };
}
