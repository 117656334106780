/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsGfsWeeklyRetentionSettings { 
    /**
     * Number of weeks during which restore points must not be modified or deleted.
     */
    keepWeeklyBackupsForWeeks: number;
    /**
     * Week day when Veeam Backup & Replication must assign the weekly GFS flag to a full restore point.
     */
    useFullBackupFrom?: WindowsGfsWeeklyRetentionSettings.UseFullBackupFromEnum;
}
export namespace WindowsGfsWeeklyRetentionSettings {
    export type UseFullBackupFromEnum = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
    export const UseFullBackupFromEnum = {
        Monday: 'Monday' as UseFullBackupFromEnum,
        Tuesday: 'Tuesday' as UseFullBackupFromEnum,
        Wednesday: 'Wednesday' as UseFullBackupFromEnum,
        Thursday: 'Thursday' as UseFullBackupFromEnum,
        Friday: 'Friday' as UseFullBackupFromEnum,
        Saturday: 'Saturday' as UseFullBackupFromEnum,
        Sunday: 'Sunday' as UseFullBackupFromEnum
    };
}