import React from 'react';

import { useAppServices } from '../../../AppLayout/hooks';
import { NotificationContext } from '../../stores';
import { useNotificationService } from '../../hooks';

export interface Props {
    children: React.ReactElement;
}

export const NotificationLayout = (props: Props) => {
    const { notificationService } = useAppServices();
    const { notifications } = useNotificationService(notificationService);

    return (
        <NotificationContext.Provider value={{ ...notifications }}>
            {props.children}
        </NotificationContext.Provider>
    );
};
