/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SharePointAccountSettings } from './sharePointAccountSettings';
import { WindowsJobScriptSettings } from './windowsJobScriptSettings';
import { WindowsOracleTransactionLogHandlingSettings } from './windowsOracleTransactionLogHandlingSettings';
import { WindowsSqlServerTransactionLogHandlingSettings } from './windowsSqlServerTransactionLogHandlingSettings';

export interface WindowsApplicationAwareProcessingSettings { 
    /**
     * Indicates whether the application-aware processing is enabled.
     */
    enabled?: boolean;
    /**
     * Application-aware processing type.
     */
    transactionLogProcessingMode?: WindowsApplicationAwareProcessingSettings.TransactionLogProcessingModeEnum;
    /**
     * Transaction log handling settings for Microsoft SQL Server.
     */
    sqlServerTransactionLogHandlingSettings?: WindowsSqlServerTransactionLogHandlingSettings;
    /**
     * Archived log handling settings for Oracle database.
     */
    oracleTransactionLogHandlingSettings?: WindowsOracleTransactionLogHandlingSettings;
    /**
     * Microsoft SharePoint account settings.
     */
    sharePointAccountSettings?: SharePointAccountSettings;
    /**
     * Execution settings for pre-freeze and post-thaw scripts.
     */
    scriptSettings?: WindowsJobScriptSettings;
}
export namespace WindowsApplicationAwareProcessingSettings {
    export type TransactionLogProcessingModeEnum = 'ProcessTransactionLogsWithJob' | 'PerformCopyOnly';
    export const TransactionLogProcessingModeEnum = {
        ProcessTransactionLogsWithJob: 'ProcessTransactionLogsWithJob' as TransactionLogProcessingModeEnum,
        PerformCopyOnly: 'PerformCopyOnly' as TransactionLogProcessingModeEnum
    };
}