import { GlobalFiltersData } from '../interfaces';
import type { VspcTransportBaseData } from '../../transport';

export class GlobalFiltersService {

    addGlobalFiltersToData<T extends VspcTransportBaseData>(data?: T): T & GlobalFiltersData {
        const isAdmin: boolean = RCOP?.CURRENT_USER && RCOP?.utils?.Roles?.isPortalOrSiteAdmin();
        const globalFilters: GlobalFiltersData = RCOP.globalFilters.getSubmitValues();

        if (data && data.disableGlobalFilters) {
            delete data.disableGlobalFilters;

            return data;
        }

        if (!isAdmin && globalFilters.ccsFilter) {
            // additionally delete a ccsFilter parameter if we aren't an admin
            delete globalFilters.ccsFilter;
        }

        if (Object.keys(globalFilters).length > 0) {
            return {
                ...data,
                ...globalFilters,
            };
        }

        return data;
    }

}
