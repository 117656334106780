/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LastAlarmActivation { 
    /**
     * UID assigned to the last alarm trigger.
     */
    readonly instanceUid?: string;
    /**
     * Date and time of the last alarm trigger.
     */
    readonly time?: Date;
    /**
     * Alarm status.
     */
    readonly status?: LastAlarmActivation.StatusEnum;
    /**
     * Cause of the alarm trigger. > Every line break is represented by the `\\r\\n` control characters. 
     */
    readonly message?: string;
    /**
     * Comment to the resolved alarm. > Every line break is represented by the `\\r\\n` control characters. 
     */
    readonly remark?: string;
}
export namespace LastAlarmActivation {
    export type StatusEnum = 'Unknown' | 'Resolved' | 'Info' | 'Warning' | 'Error' | 'Acknowledged';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Resolved: 'Resolved' as StatusEnum,
        Info: 'Info' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum,
        Acknowledged: 'Acknowledged' as StatusEnum
    };
}