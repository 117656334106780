import { RequestAdditionalParams, TransportActions, TransportActionsPreparedRequestData, TransportMethods, Operation } from '@veeam-vspc/core';

import { VspcTransportActionsServices, VspcTransportBaseData } from '../interfaces';
import { GlobalFiltersData } from '../../global-filters/interfaces';
import { GlobalFiltersService } from '../../global-filters';

export class VspcTransportActions extends TransportActions {

    protected readonly globalFiltersService: GlobalFiltersService;

    constructor({ globalFiltersService, ...rest }: VspcTransportActionsServices, config) {
        super(rest, config);

        this.globalFiltersService = globalFiltersService;
    }

    prepareRequest<T extends VspcTransportBaseData>(
        url: string,
        data?: T | Operation[],
        customOptions: RequestInit = {},
        params: RequestAdditionalParams = {},
    ): Promise<TransportActionsPreparedRequestData> {
        const vspcData: T | Operation[] = params.isRest && customOptions.method === TransportMethods.Patch
            ? data
            : this.globalFiltersService.addGlobalFiltersToData<T>(data as T);

        return super.prepareRequest<T & GlobalFiltersData>(url, vspcData, customOptions, params);
    }

    beforeRequest<T extends VspcTransportBaseData>(
        preparedRequestData: TransportActionsPreparedRequestData,
        data?: T | Operation[],
        params: RequestAdditionalParams = {},
    ): Promise<TransportActionsPreparedRequestData> {
        if (this.config.getIsAutoUpdateBlocked() && data && (data as T).rcopAutoUpdate) {
            return this.rejectAuthError();
        }

        if (data && !(data as T).rcopAutoUpdate) {
            this.setLastActiveTime();
        }

        return super.beforeRequest<T>(preparedRequestData, data, params);
    }

}
