/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ComputerInfo } from './computerInfo';

export interface ManagementAgent { 
    /**
     * UID assigned to a management agent.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a location to which a management agent belongs.
     */
    locationUid: string;
    /**
     * UID assigned to an organization to which a management agent belongs.
     */
    readonly organizationUid?: string;
    /**
     * Name of a computer on which a management agent is deployed.
     */
    readonly hostName?: string;
    /**
     * Date and time when a management agent on a computer sent the latest heartbeat.
     */
    readonly lastHeartbeatTime?: Date;
    /**
     * Version of a management agent deployed on a computer.
     */
    readonly version?: string;
    /**
     * Date and time when a computer was discovered.
     */
    readonly discoveryTime?: Date;
    /**
     * Additional information.
     */
    customNotes?: string;
    /**
     * Status of a management agent.
     */
    readonly status?: ManagementAgent.StatusEnum;
    /**
     * Role of a management agent.
     */
    readonly type?: ManagementAgent.TypeEnum;
    /**
     * Information about a computer on which a management agent is deployed.
     */
    readonly computerInfo?: ComputerInfo;
    /**
     * Connection status of a management agent.
     */
    readonly connectionStatus?: ManagementAgent.ConnectionStatusEnum;
    /**
     * Indicates whether computer reboot is required.
     */
    readonly isRebootRequired?: boolean;
    /**
     * Company owner user name that is used to connect a management agent to a cloud gateway.
     */
    readonly connectionAcount?: string;
    /**
     * Status of a management agent version.
     */
    readonly versionStatus?: ManagementAgent.VersionStatusEnum;
    /**
     * Role of a management agent.
     */
    readonly role?: ManagementAgent.RoleEnum;
}
export namespace ManagementAgent {
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Inaccessible' | 'Warning' | 'Updating' | 'Error' | 'Restarting' | 'Installation' | 'FailedToUpdate';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Inaccessible: 'Inaccessible' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Updating: 'Updating' as StatusEnum,
        Error: 'Error' as StatusEnum,
        Restarting: 'Restarting' as StatusEnum,
        Installation: 'Installation' as StatusEnum,
        FailedToUpdate: 'FailedToUpdate' as StatusEnum
    };
    export type TypeEnum = 'Unknown' | 'CloudConnect' | 'Client';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        CloudConnect: 'CloudConnect' as TypeEnum,
        Client: 'Client' as TypeEnum
    };
    export type ConnectionStatusEnum = 'Unknown' | 'Online' | 'Inaccessible' | 'Rejected';
    export const ConnectionStatusEnum = {
        Unknown: 'Unknown' as ConnectionStatusEnum,
        Online: 'Online' as ConnectionStatusEnum,
        Inaccessible: 'Inaccessible' as ConnectionStatusEnum,
        Rejected: 'Rejected' as ConnectionStatusEnum
    };
    export type VersionStatusEnum = 'Unknown' | 'UpToDate' | 'OutOfDate' | 'PatchAvailable';
    export const VersionStatusEnum = {
        Unknown: 'Unknown' as VersionStatusEnum,
        UpToDate: 'UpToDate' as VersionStatusEnum,
        OutOfDate: 'OutOfDate' as VersionStatusEnum,
        PatchAvailable: 'PatchAvailable' as VersionStatusEnum
    };
    export type RoleEnum = 'Unknown' | 'Master' | 'Client';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        Master: 'Master' as RoleEnum,
        Client: 'Client' as RoleEnum
    };
}