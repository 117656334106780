/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BackupServerJobObjectLastSession } from './backupServerJobObjectLastSession';

export interface BackupServerVmJobObject { 
    /**
     * UID assigned to a job.
     */
    readonly jobUid?: string;
    /**
     * UID assigned to a protected VM.
     */
    readonly instanceUid?: string;
    /**
     * Name of a VM.
     */
    readonly name?: string;
    /**
     * VM platform.
     */
    readonly platform?: BackupServerVmJobObject.PlatformEnum;
    /**
     * Reference ID of a VM.
     */
    readonly hierarchyRef?: string;
    /**
     * Indicates whether the VM is excluded from a job.
     */
    readonly isExcluded?: boolean;
    /**
     * Information about the latest job session.
     */
    readonly lastSession?: BackupServerJobObjectLastSession;
}
export namespace BackupServerVmJobObject {
    export type PlatformEnum = 'Unknown' | 'vSphere' | 'HyperV' | 'Vcd';
    export const PlatformEnum = {
        Unknown: 'Unknown' as PlatformEnum,
        VSphere: 'vSphere' as PlatformEnum,
        HyperV: 'HyperV' as PlatformEnum,
        Vcd: 'Vcd' as PlatformEnum
    };
}