/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AlarmObject { 
    /**
     * UID assigned to an object for which an alarm was triggered.
     */
    readonly instanceUid?: string;
    /**
     * Object type.
     */
    readonly type?: AlarmObject.TypeEnum;
    /**
     * UID assigned to a organization for which an alarm was triggered.
     */
    readonly organizationUid?: string;
    /**
     * UID assigned to a location for which an alarm was triggered.
     */
    readonly locationUid?: string;
    /**
     * UID assigned to a managed agent that is installed on an alarm object.
     */
    readonly managementAgentUid?: string;
    /**
     * Name of a computer for which an alarm was triggered.
     */
    readonly computerName?: string;
    /**
     * Name of an alarm object.
     */
    readonly objectName?: string;
}
export namespace AlarmObject {
    export type TypeEnum = 'Unknown' | 'ManagementAgent' | 'Location' | 'Company' | 'User' | 'Internal' | 'ObjectEntity' | 'DiscoveryRule' | 'Integrator' | 'Reseller' | 'Site' | 'ResellerCloudRepository';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        ManagementAgent: 'ManagementAgent' as TypeEnum,
        Location: 'Location' as TypeEnum,
        Company: 'Company' as TypeEnum,
        User: 'User' as TypeEnum,
        Internal: 'Internal' as TypeEnum,
        ObjectEntity: 'ObjectEntity' as TypeEnum,
        DiscoveryRule: 'DiscoveryRule' as TypeEnum,
        Integrator: 'Integrator' as TypeEnum,
        Reseller: 'Reseller' as TypeEnum,
        Site: 'Site' as TypeEnum,
        ResellerCloudRepository: 'ResellerCloudRepository' as TypeEnum
    };
}