import React from 'react';
import type { WizardStep } from '@veeam-vspc/components';

import { PageWizard } from 'components/wizards/PageWizard';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { getLoginInfoStep } from '../Base/components/LoginInfo';
import { getUserInfoStep } from '../Base/components/UserInfo';
import { getCompaniesStep } from './components/Companies';
import { getRoleStep } from './components/Role';
import { getSummaryStep } from './components/Summary';
import type { SPUserProps } from './interfaces/sp-user-props';
import type { SPUserModel } from './interfaces/sp-user-model';
import { getMultiFactorAuthStep } from '../Base/components/MultiFactorAuthentication';
import { ManagedCompaniesMode } from 'features/RolesAndUsers/wizards/SPUser/enums/managed-companies-mode';
import { saveUser } from 'features/RolesAndUsers/wizards/Base/helpers/saveUser';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const SPUser = (props: SPUserProps) => {
    const langs = useLangs();
    const data: SPUserModel = {
        description: '',
        firstName: '',
        lastName: '',
        email: '',
        login: '',
        userRole: PortalUserRoles.ServiceProviderAdministrator,
        sendInvoice: false,
        companies: [],
        selectAllCompanies: props.model?.userCompanyIds?.length > 0 ? ManagedCompaniesMode.CustomSelection : ManagedCompaniesMode.AllCompanies,
        ...props.model,
        password: '',
        confirmpassword: '',
        oldPassword: props.selfEdit ? '' : undefined,
        selfEdit: props.selfEdit,
    };
    const title = props.isEdit ? langs.EDIT_LOCAL_USER : langs.NEW_LOCAL_USER;

    return (
        <PageWizard
            title={title}
            deactivate={() => undefined}
            isEdit={props.isEdit}
            data={data}
            steps={[
                getUserInfoStep(langs.USER_INFO),
                getLoginInfoStep(langs.LOGIN_INFO),
                getRoleStep(langs.ROLE),
                getCompaniesStep(langs.COMPANIES),
                getMultiFactorAuthStep(langs.MULTI_FACTOR_AUTHENTICATION),
                getSummaryStep(langs.SUMMARY),
            ] as WizardStep<SPUserModel>[]}
            onFinish={({ data }) => {
                const requestParams: { user: SPUserModel; } = {
                    user: {
                        ...data,
                        userCompanyIds: data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                            ? data.companies.map(company => company.id)
                            : undefined,
                    },
                };

                if (data.userRole === PortalUserRoles.ServiceProviderOperator || data.userRole === PortalUserRoles.ServiceProviderUser) {
                    requestParams.user.assignAllResellerCompanies = data.selectAllCompanies === ManagedCompaniesMode.AllCompanies;
                    requestParams.user.userCompanyIds = data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                        ? data.companies.map(company => company.id)
                        : undefined;
                }

                return saveUser({ props, requestParams });
            }}
        />
    );
};
