/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsMonthlyScheduleCalendarSettings { 
    /**
     * Counting number of the week day.
     */
    weekDayNumber: WindowsMonthlyScheduleCalendarSettings.WeekDayNumberEnum;
    /**
     * Name of the week day.
     */
    dayOfWeek?: WindowsMonthlyScheduleCalendarSettings.DayOfWeekEnum;
    /**
     * Month.
     */
    months?: Array<WindowsMonthlyScheduleCalendarSettings.MonthsEnum>;
}
export namespace WindowsMonthlyScheduleCalendarSettings {
    export type WeekDayNumberEnum = 'First' | 'Second' | 'Third' | 'Fourth' | 'Last';
    export const WeekDayNumberEnum = {
        First: 'First' as WeekDayNumberEnum,
        Second: 'Second' as WeekDayNumberEnum,
        Third: 'Third' as WeekDayNumberEnum,
        Fourth: 'Fourth' as WeekDayNumberEnum,
        Last: 'Last' as WeekDayNumberEnum
    };
    export type DayOfWeekEnum = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
    export const DayOfWeekEnum = {
        Monday: 'Monday' as DayOfWeekEnum,
        Tuesday: 'Tuesday' as DayOfWeekEnum,
        Wednesday: 'Wednesday' as DayOfWeekEnum,
        Thursday: 'Thursday' as DayOfWeekEnum,
        Friday: 'Friday' as DayOfWeekEnum,
        Saturday: 'Saturday' as DayOfWeekEnum,
        Sunday: 'Sunday' as DayOfWeekEnum
    };
    export type MonthsEnum = 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec' | 'All';
    export const MonthsEnum = {
        Jan: 'Jan' as MonthsEnum,
        Feb: 'Feb' as MonthsEnum,
        Mar: 'Mar' as MonthsEnum,
        Apr: 'Apr' as MonthsEnum,
        May: 'May' as MonthsEnum,
        Jun: 'Jun' as MonthsEnum,
        Jul: 'Jul' as MonthsEnum,
        Aug: 'Aug' as MonthsEnum,
        Sep: 'Sep' as MonthsEnum,
        Oct: 'Oct' as MonthsEnum,
        Nov: 'Nov' as MonthsEnum,
        Dec: 'Dec' as MonthsEnum,
        All: 'All' as MonthsEnum
    };
}