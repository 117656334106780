import ReactTooltip from 'react-tooltip';
import React from 'react';
import { ReactComponent as ErrorStatusIcon } from 'images/error-status.svg';
import { ReactComponent as SuccessStatusIcon } from 'images/success-status.svg';
import { ReactComponent as WarningStatusIcon } from 'images/warning-status.svg';
import { CompaniesCount, Company, JobsCount, JobsState, MoreCompanies } from './Tooltip.styled';
import { useStore } from '../../store/useStore';

const xAxisTooltipOffset = -115; // approved design

interface Props {
    id: string;
}

export const Tooltip = ({ id }: Props) => {
    const store = useStore();
    const record = store.findById(id);
    const affectedCompanies = record.affectedCompanies;

    return (
        <ReactTooltip
            id={id}
            place='bottom'
            type='light'
            effect='float'
            offset={{ left: xAxisTooltipOffset }}
            className='customTheme'
        >
            <div>{record.displayDateForTooltip}</div>
            <JobsCount>Job Sessions ({record.allJobsCount})</JobsCount>
            <JobsState>
                <span><ErrorStatusIcon /> {record.failJobsCount} </span>
                <span><WarningStatusIcon /> {record.warningJobsCount} </span>
                <span><SuccessStatusIcon /> {record.successJobsCount} </span>
            </JobsState>
            {record.affectedCompanies.length > 0 &&
                <>
                    <CompaniesCount>Affected Companies ({affectedCompanies.length}):</CompaniesCount>
                    {affectedCompanies.splice(0, 10).map((company, index) => <Company key={index}>{company.name}</Company>)}
                    {affectedCompanies.length > 0 && <MoreCompanies>{affectedCompanies.length} more...</MoreCompanies>}
                </>
            }
        </ReactTooltip>
    );
};
