/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MachinesNetworkTrustOptions { 
    /**
     * Type of trusted computer selection.
     */
    trustOption: MachinesNetworkTrustOptions.TrustOptionEnum;
    /**
     * List of trusted computers required for the `KnownList` type of selection.
     */
    knownHostList?: string;
}
export namespace MachinesNetworkTrustOptions {
    export type TrustOptionEnum = 'Unknown' | 'All' | 'KnownList';
    export const TrustOptionEnum = {
        Unknown: 'Unknown' as TrustOptionEnum,
        All: 'All' as TrustOptionEnum,
        KnownList: 'KnownList' as TrustOptionEnum
    };
}