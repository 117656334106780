import React from 'react';
import { Day } from '../Day';
import { DaysWrapper } from './Days.styled';
import { Week } from '../Week';
import { useStore } from '../../store/useStore';
import { openJobsDialog } from '../../interfaces/open-jobs-dialog';
import { toJS } from 'mobx';

interface Props {
    openJobsDialog: openJobsDialog;
}

export const Days = ({ openJobsDialog }: Props) => {
    const store = useStore();
    const days = toJS(store.days);
    const getSevenDays = () => days.splice(0, 7);
    const daysElements: React.ReactNodeArray = [];

    while (days.length > 0) {
        daysElements.push(
            <Week key={Math.floor(days.length / 7)}>
                {getSevenDays().map(day => <Day openJobsDialog={openJobsDialog} key={day.id} id={day.id} />)}
            </Week>
        );
    }

    return <DaysWrapper>{daysElements}</DaysWrapper>;
};
