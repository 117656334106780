/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsMonthlyScheduleCalendarWithDaySettings } from './windowsMonthlyScheduleCalendarWithDaySettings';

export interface MonthlyOrWeeklyScheduleWithDaySettings { 
    /**
     * Scheduling settings for monthly full backup creation. > If the `weeklyOnDays` property is also provided, monthly schedule is ignored. 
     */
    monthlySettings?: WindowsMonthlyScheduleCalendarWithDaySettings;
    /**
     * Scheduling settings for weekly full backup creation. > If the `monthlySettings` property is also provided, it is ignored. 
     */
    weeklyOnDays?: Array<MonthlyOrWeeklyScheduleWithDaySettings.WeeklyOnDaysEnum>;
}
export namespace MonthlyOrWeeklyScheduleWithDaySettings {
    export type WeeklyOnDaysEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const WeeklyOnDaysEnum = {
        Sunday: 'Sunday' as WeeklyOnDaysEnum,
        Monday: 'Monday' as WeeklyOnDaysEnum,
        Tuesday: 'Tuesday' as WeeklyOnDaysEnum,
        Wednesday: 'Wednesday' as WeeklyOnDaysEnum,
        Thursday: 'Thursday' as WeeklyOnDaysEnum,
        Friday: 'Friday' as WeeklyOnDaysEnum,
        Saturday: 'Saturday' as WeeklyOnDaysEnum
    };
}