/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedCloudVirtualMachineBackup { 
    /**
     * UID assigned to a virtual machine.
     */
    readonly cloudVirtualMachineUid?: string;
    /**
     * UID assigned to a backup job.
     */
    readonly jobUid?: string;
    /**
     * Backup job type.
     */
    readonly backupType?: ProtectedCloudVirtualMachineBackup.BackupTypeEnum;
    /**
     * Location where backup chain resides.
     */
    readonly destination?: string;
    /**
     * Total size of all restore points, in bytes.
     */
    readonly totalRestorePointSize?: number;
    /**
     * Size of the latest restore point, in bytes.
     */
    readonly latestRestorePointSize?: number;
    /**
     * Number of restore points.
     */
    readonly restorePoints?: number;
    /**
     * Date of the latest restore point creation.
     */
    readonly latestRestorePointDate?: Date;
}
export namespace ProtectedCloudVirtualMachineBackup {
    export type BackupTypeEnum = 'Unknown' | 'Backup' | 'Snapshot' | 'RemoteSnapshot' | 'BackupCopy' | 'BackupToTape';
    export const BackupTypeEnum = {
        Unknown: 'Unknown' as BackupTypeEnum,
        Backup: 'Backup' as BackupTypeEnum,
        Snapshot: 'Snapshot' as BackupTypeEnum,
        RemoteSnapshot: 'RemoteSnapshot' as BackupTypeEnum,
        BackupCopy: 'BackupCopy' as BackupTypeEnum,
        BackupToTape: 'BackupToTape' as BackupTypeEnum
    };
}