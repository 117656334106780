/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServer { 
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly instanceUid?: string;
    /**
     * Name of a Veeam Backup & Replication server.
     */
    readonly name?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server location.
     */
    readonly locationUid?: string;
    /**
     * UID assigned to a management agent installed on a Veeam Backup & Replication server.
     */
    readonly managementAgentUid?: string;
    /**
     * Version of Veeam Backup & Replication installed on a server.
     */
    readonly version?: string;
    /**
     * Version of Veeam Backup & Replication with additional information on installed patch version.
     */
    readonly displayVersion?: string;
    /**
     * UID assigned to Veeam Backup & Replication installation.
     */
    readonly installationUid?: string;
    /**
     * Role of a Veeam Backup & Replication server.
     */
    readonly backupServerRoleType?: BackupServer.BackupServerRoleTypeEnum;
    /**
     * Backup server status.
     */
    readonly status?: BackupServer.StatusEnum;
}
export namespace BackupServer {
    export type BackupServerRoleTypeEnum = 'Unknown' | 'CloudConnect' | 'Client';
    export const BackupServerRoleTypeEnum = {
        Unknown: 'Unknown' as BackupServerRoleTypeEnum,
        CloudConnect: 'CloudConnect' as BackupServerRoleTypeEnum,
        Client: 'Client' as BackupServerRoleTypeEnum
    };
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Warning' | 'Inaccessible';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Inaccessible: 'Inaccessible' as StatusEnum
    };
}