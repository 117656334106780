import React from 'react';

import { DayWithoutJobsWrapper } from './DayWithoutJobs.styled';
import { useStore } from '../../../../store/useStore';
import { DateTitle } from '../DayBase';
import { Tooltip } from '../../../Tooltip';

interface Props {
    id: string;
}

export const DayWithoutJobs = ({ id }: Props) => {
    const store = useStore();
    const record = store.findById(id);

    return (
        <>
            <DayWithoutJobsWrapper
                data-for={id}
                data-tip={id}
            >
                <DateTitle>
                    {record.displayDateForCell}
                </DateTitle>
            </DayWithoutJobsWrapper>
            <Tooltip id={id} />
        </>
    );
};
