/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Organization { 
    /**
     * UID assigned to an organization.
     */
    readonly instanceUid?: string;
    /**
     * Name of an organization.
     */
    name: string;
    /**
     * Alias of an organization.
     */
    alias?: string;
    /**
     * Type of an organization.
     */
    readonly type?: Organization.TypeEnum;
    /**
     * Organization Tax ID.
     */
    taxId?: string;
    /**
     * Contact email address.
     */
    email?: string;
    /**
     * Telephone number of a primary contact of an organization.
     */
    phone?: string;
    /**
     * System ID assigned to an organization country of residence.
     */
    country?: number;
    /**
     * System ID assigned to a USA state where an organization is located.
     */
    state?: number;
    /**
     * City where an organization is located.
     */
    city?: string;
    /**
     * Street where an organization is located.
     */
    street?: string;
    /**
     *  Additional information about an organization.
     */
    notes?: string;
    /**
     * Postal code.
     */
    zipCode?: string;
    /**
     * Organization website.
     */
    website?: string;
    /**
     * ID of an organization used for 3rd party applications.
     */
    companyId?: string;
}
export namespace Organization {
    export type TypeEnum = 'Unknown' | 'Company' | 'Provider' | 'Reseller';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        Company: 'Company' as TypeEnum,
        Provider: 'Provider' as TypeEnum,
        Reseller: 'Reseller' as TypeEnum
    };
}