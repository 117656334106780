/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ComputerInfo } from './computerInfo';

export interface DiscoveredComputer { 
    /**
     * UID assigned to a discovered computer.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a rule used to discover a computer.
     */
    readonly ruleUid?: string;
    /**
     * UID assigned to a management agent installed on a discovered computer.
     */
    readonly managementAgentUid?: string;
    /**
     * Date and time when a computer was discovered.
     */
    readonly discoveredTime?: Date;
    /**
     * Status of Veeam backup agent installation on a discovered computer.
     */
    readonly backupAgentInstallationStatus?: DiscoveredComputer.BackupAgentInstallationStatusEnum;
    /**
     * Computer connection status. > If management agent is not installed on the computer, the connection status does not change after discovery.' 
     */
    readonly status?: DiscoveredComputer.StatusEnum;
    /**
     * Veeam backup agent version.
     */
    readonly backupAgentVersion?: string;
    /**
     * Veeam backup agent management status.
     */
    readonly backupAgentManagementStatus?: DiscoveredComputer.BackupAgentManagementStatusEnum;
    /**
     * Information about a discovered computer.
     */
    readonly info?: ComputerInfo;
}
export namespace DiscoveredComputer {
    export type BackupAgentInstallationStatusEnum = 'Unknown' | 'Installed' | 'NotInstalled';
    export const BackupAgentInstallationStatusEnum = {
        Unknown: 'Unknown' as BackupAgentInstallationStatusEnum,
        Installed: 'Installed' as BackupAgentInstallationStatusEnum,
        NotInstalled: 'NotInstalled' as BackupAgentInstallationStatusEnum
    };
    export type StatusEnum = 'Unknown' | 'Online' | 'Offline' | 'Error';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Online: 'Online' as StatusEnum,
        Offline: 'Offline' as StatusEnum,
        Error: 'Error' as StatusEnum
    };
    export type BackupAgentManagementStatusEnum = 'Unknown' | 'UnManaged' | 'ManagedByVBR' | 'ManagedByConsole';
    export const BackupAgentManagementStatusEnum = {
        Unknown: 'Unknown' as BackupAgentManagementStatusEnum,
        UnManaged: 'UnManaged' as BackupAgentManagementStatusEnum,
        ManagedByVBR: 'ManagedByVBR' as BackupAgentManagementStatusEnum,
        ManagedByConsole: 'ManagedByConsole' as BackupAgentManagementStatusEnum
    };
}