import React from 'react';
import type { FormErrors, WizardStep, WizardStepData } from '@veeam-vspc/components';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';

import type { CompanyUserModel } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/company-user-model';
import { StepLayout } from 'components/layouts/StepLayout';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { getClientDocUrl } from '../../../Base/helpers/getClientDocUrl';
import { NoteBar } from 'components/layouts/NoteBar';

export const stepValidate = (data: CompanyUserModel): FormErrors<CompanyUserModel> => {
    const validator = new FormValidator(data);

    validator.validate('userRole').number();

    return validator.result();
};

export const Role: React.FC<WizardStepData<CompanyUserModel>> = ({ data, validationState, onDataChange }) => {
    const langs = useLangs();
    const roles = [];

    if (RCOP.utils.Roles.isLocationAdmin()) {
        roles.push({ value: PortalUserRoles.LocationUser, text: langs.LOCATION_USER });
        roles.push({ value: PortalUserRoles.Subtenant, text: langs.SUBTENANT });
    } else {
        roles.push({ value: PortalUserRoles.CompanyAdministrator, text: langs.COMPANY_ADMINISTRATOR });
        roles.push({ value: PortalUserRoles.LocationAdministrator, text: langs.LOCATION_ADMINISTRATOR });
        roles.push({ value: PortalUserRoles.LocationUser, text: langs.LOCATION_USER });
        roles.push({ value: PortalUserRoles.CompanyInvoiceAuditor, text: langs.COMPANY_INVOICE_AUDITOR });
        roles.push({ value: PortalUserRoles.Subtenant, text: langs.SUBTENANT });
    }

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.ROLE}
                description={langs.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='userRole'
                        size={CONTROL_SIZE.m}
                        data={roles}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={langs.ROLE}
                        inlineLabel
                    />
                    <NoteBar>
                        {data.userRole === PortalUserRoles.CompanyAdministrator &&
                            <p>
                                {langs.COMPANY_ADMINISTRATOR_HAS_ACCESS_TO_ALL_MONITORING}
                            </p>
                        }
                        {data.userRole === PortalUserRoles.LocationAdministrator &&
                            <p>
                                {langs.LOCATION_ADMINISTRATOR_HAS_ACCESS_TO_ALL_DATA}
                            </p>
                        }
                        {data.userRole === PortalUserRoles.LocationUser &&
                            <p>
                                {langs.LOCATION_USER_HAS_ACCESS_TO_THE_COMPANY}
                            </p>
                        }
                        {data.userRole === PortalUserRoles.CompanyInvoiceAuditor &&
                            <p>
                                {langs.COMPANY_INVOICE_AUDITOR_HAS_ACCESS}
                            </p>
                        }
                        {data.userRole === PortalUserRoles.Subtenant &&
                            <p>
                                {langs.SUBTENANT_IS_A_USER_WHO_HAS_CLOUD_REPOSITORY}
                            </p>
                        }
                        <p>
                            {langs.CLICK} <a
                                href={getClientDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {langs.HERE}
                            </a> {langs.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    validate: ({ data }) => isValid(stepValidate, data),
    isHidden: ({ data, isEdit }) => {
        if (isEdit) {
            return true;
        }

        if (data.userRole === PortalUserRoles.CompanyOwner || data.userRole === PortalUserRoles.ServiceProviderGlobalAdministrator) {
            return true;
        }
    },
    render: data => <Role {...data} />,
});
