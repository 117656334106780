import React from 'react';

import { PageProps } from '../../interfaces';
import { PageLayout } from '../../../PageLayout';
import { ROLES_AND_USERS_PAGE, RolesAndUsersPage } from 'views/pages/RolesAndUsersPage';
import { WizardManagerProps } from 'views/pages/RolesAndUsersPage/components/WizardManager/WizardManager';

export interface RouterLayoutProps {
    pageName: string;
    pageProps?: PageProps;
}

export const RouterLayout = ({ pageName, pageProps }: RouterLayoutProps) => (
    <PageLayout>
        {(() => {
            switch (pageName) {
                case ROLES_AND_USERS_PAGE:
                    return <RolesAndUsersPage {...pageProps as WizardManagerProps} />;
                default:
                    return <div />;
            }
        })()}
    </PageLayout>
);
