import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import type {
    FormErrors,
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import {
    FieldLayout,
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    TextInput,
} from '@veeam-vspc/components';
import type { SSORuleModel } from '../../interfaces/sso-rule-model';
import { RulesList } from './components/RulesList';
import { useExternalFormApi } from '@veeam-vspc/components/src/form/hooks/useExternalFormApi';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppStore } from 'views/layouts/AppLayout/hooks';
import { RolesAndUsersWizardType } from '../../../../../../views/pages/RolesAndUsersPage/enums/roles-and-users-wizard-type';
import { NoteBar } from 'components/layouts/NoteBar';

export const conditionsStepValidate = (data: SSORuleModel): FormErrors<SSORuleModel> => {
    const validator = new FormValidator(data);

    validator.validate('model.organizationMappingSourceClaimType')
        .string()
        .maxLength(2048)
        .check((value) => {
            if (value === undefined || value.trim() === '') {
                return RCOP.Lang.THE_FIELD_IS_REQUIRED;
            }
        });

    if (data.model.additionalMappings) {
        data.model.additionalMappings.forEach((item, i) => {
            validator.validate(`model.additionalMappings[${i}].claimType`)
                .string()
                .maxLength(2048)
                .check((value) => {
                    if (value === undefined || value.trim() === '') {
                        return RCOP.Lang.THE_FIELD_IS_REQUIRED;
                    }
                });
            validator.validate(`model.additionalMappings[${i}].value`)
                .string()
                .maxLength(2048)
                .check((value) => {
                    if (value === undefined || value.trim() === '') {
                        return RCOP.Lang.THE_FIELD_IS_REQUIRED;
                    }
                });
        });
    }

    return validator.result();
};

const Conditions: React.FC<WizardStepData<SSORuleModel>> = (props) => {
    const langs = useLangs();
    const { data, validationState, onDataChange } = props;
    const formApi = useExternalFormApi<SSORuleModel>();
    const { portalUser } = useAppStore();

    return (
        <Form
            value={data}
            validate={conditionsStepValidate}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
        >
            <StepLayout
                title={langs.CONDITIONS}
                description={langs.CONFIGURE_MAPPING_CONDITIONS_FOR_THE_SELECTED_ROLE}
            >
                <StackView gap={STACK_GAP.m} direction={STACK_DIRECTION.column}>
                    <TextInput
                        name='model.organizationMappingSourceClaimType'
                        label={data.wizard === RolesAndUsersWizardType.CompanySSORule ? langs.SPECIFY_CLAIM_NAME_FROM :
                            `${langs.SPECIFY_CLAIM_NAME} «${portalUser.companyName}»`
                        }
                        placeholder={langs.CLAIM_NAME}
                    />
                    <NoteBar>
                        {langs.CLAIM_IS_AN_ATTRIBUTE}<br/>
                        {langs.FOR_OKTA}<br/>
                        {langs.FOR_KEYCLOAK_ADFS}<br/>
                    </NoteBar>
                    <FieldLayout label={`${langs.CONFIGURE_ADDITIONAL_MAPPING_CONDITIONS}:`}>
                        <RulesList
                            data={data}
                            formApi={formApi}
                        />
                    </FieldLayout>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getConditionsStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    validate: ({ data }) => isValid(conditionsStepValidate, data),
    render: data => <Conditions {...data} />,
    id: 'conditions',
});


