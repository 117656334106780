/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Collation } from './collation';

export interface SortExpression { 
    /**
     * Path to the required resource property.
     */
    property: string;
    /**
     * Direction specifier.
     */
    direction: SortExpression.DirectionEnum;
    collation?: Collation;
}
export namespace SortExpression {
    export type DirectionEnum = 'ascending' | 'descending';
    export const DirectionEnum = {
        Ascending: 'ascending' as DirectionEnum,
        Descending: 'descending' as DirectionEnum
    };
}