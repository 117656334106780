import { core } from 'core/core-module';

interface SaveUserParams<T> {
    props: {
        isEdit: boolean;
        onFinish: () => void;
    };
    requestParams: T;
    areAdminRoles?: boolean;
}

export const saveUser = <T>({ props, requestParams, areAdminRoles }: SaveUserParams<T>) => {
    if (areAdminRoles) {
        return core.transportService.request('AccountManagement/Save', requestParams)
            .then(() => props.onFinish());
    } else {
        return core.transportService.request(props.isEdit ? 'User/UpdateUser' : 'User/CreateUser', requestParams)
            .then(() => props.onFinish());
    }
};
