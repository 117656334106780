import { deepCopy, makeTwoDigit } from '@veeam-vspc/core';

import { DayJobsHeatMap } from '../interfaces/day-jobs-heat-map';
import { HeatMapDays } from './HeatMapDays';
import { Period } from '../enums/period';
import { MAX_HEAT_MAP_DAYS } from 'features/HeatMap/components/HeatMap/HeatMap.styled';

interface Props {
    days: DayJobsHeatMap[];
    beginDate: Date;
    endTime: Date;
    selectedPeriod: Period;
}

export const createStore = ({ days, beginDate, endTime, selectedPeriod }: Props) => {
    const makeDateStr = (d: Date) => `${d.getFullYear()}-${makeTwoDigit(d.getMonth() + 1)}-${makeTwoDigit(d.getDate())}T00:00:00Z`;
    const startDatesPlaceholders: DayJobsHeatMap[] = [];
    const middleDatesPlaceholders: DayJobsHeatMap[] = [];
    const endDatesPlaceholders: DayJobsHeatMap[] = [];
    const copiedDays = deepCopy(days);

    // 1. make necessary day stubs at the beginning (out of period, gray zone)
    const copiedBeginDate = new Date(beginDate.getTime());
    for (let i = copiedBeginDate.getDay() - 1; i >= 0; i -= 1) {
        startDatesPlaceholders.unshift({
            date: makeDateStr(new Date(copiedBeginDate.setDate(copiedBeginDate.getDate() - 1))),
            outOfPeriod: true,
            companies: [],
            failJobsCount: undefined,
            successJobsCount: undefined,
            warningJobsCount: undefined,
        });
    }

    // 2. make necessary day stubs in the middle, then they will be replaced by the real data from Backend
    for (let i = new Date(beginDate.getTime()); i <= endTime; i.setDate(i.getDate() + 1)) {
        middleDatesPlaceholders.push({
            date: makeDateStr(i),
            outOfPeriod: false,
            companies: [],
            failJobsCount: undefined,
            successJobsCount: undefined,
            warningJobsCount: undefined,
        });
    }

    // 3. make necessary day stubs in the end (out of period, gray zone)
    // @ts-ignore
    const daysCount = Math.floor((endTime - beginDate) / 86400000) + 1;
    const copiedEndTime = new Date(endTime.getTime());
    for (let i = 1; i <= (MAX_HEAT_MAP_DAYS - startDatesPlaceholders.length - daysCount); i += 1) {
        const endDatePlaceholder = new Date(copiedEndTime.setDate(copiedEndTime.getDate() + 1));

        endDatesPlaceholders.push({
            date: makeDateStr(endDatePlaceholder),
            outOfPeriod: !(selectedPeriod === Period.CurrentMonth && endTime.getMonth() === endDatePlaceholder.getMonth()),
            companies: [],
            failJobsCount: undefined,
            successJobsCount: undefined,
            warningJobsCount: undefined,
        });
    }

    middleDatesPlaceholders.forEach((placeholder, index) => {
        const result = copiedDays.find(item => item.date === placeholder.date); // if we find the match

        if (result) {
            middleDatesPlaceholders[index] = result; // replace a placeholder by the real data
        }
    });

    const result = [...startDatesPlaceholders, ...middleDatesPlaceholders, ...endDatesPlaceholders];

    // 4. delete all timezones stamps, we don't need them anymore
    result.forEach((day) => {
        day.date = day.date.replace('Z', '');
    });

    // 5. and finally pack the result to the observable object
    return new HeatMapDays(result);
};
