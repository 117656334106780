/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsPolicyPeriodicallyLogBackupSettings { 
    /**
     * Frequency for archived logs backup, in minutes.
     */
    backupLogsEveryMin?: number;
    /**
     * Type of a retention policy for archived logs.
     */
    backupRetentionMode?: WindowsPolicyPeriodicallyLogBackupSettings.BackupRetentionModeEnum;
    /**
     * Number of days for which archived logs are kept.
     */
    keepBackupsOnlyLastDays?: number;
}
export namespace WindowsPolicyPeriodicallyLogBackupSettings {
    export type BackupRetentionModeEnum = 'UntilBackupIsDeleted' | 'KeepLastDays';
    export const BackupRetentionModeEnum = {
        UntilBackupIsDeleted: 'UntilBackupIsDeleted' as BackupRetentionModeEnum,
        KeepLastDays: 'KeepLastDays' as BackupRetentionModeEnum
    };
}