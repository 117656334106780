import { makePercentFromNumber } from '@veeam-vspc/core';

import { calcRoundingPercents } from 'core/utils/calc-rounding-percents';
import { JobStatus } from '../../interfaces/job-status';

interface Percents {
    [key: string]: [string, string]; // [a boosted percent value, a real percent values]
}

/**
 * It returns correct percent rounding values
 */
export const calcPercents = (numbers: number[], minPercentValue: number = 14): Percents => {
    if (numbers.every(number => number === 0)) {
        return { success: ['', ''], warning: ['', ''], fail: ['', ''] };
    }

    /**
     * Step 1. Calc rounded percentages.
     */
    const roundingPercents = calcRoundingPercents(numbers);

    /**
     * Step 2. Find values which are less than the minimum limit and boost it up.
     */
    const [successJobsCount, warningJobsCount, failJobsCount] = numbers;
    const [success, warning, fail] = roundingPercents;
    const percentsArray: JobStatus[] = [
        { status: 'success', value: success, isTrueZero: !successJobsCount, realValue: success },
        { status: 'warning', value: warning, isTrueZero: !warningJobsCount, realValue: warning },
        { status: 'fail', value: fail, isTrueZero: !failJobsCount, realValue: fail },
    ];

    percentsArray.sort((a: JobStatus, b: JobStatus) => b.value - a.value);

    percentsArray.forEach((percent: JobStatus, index: number) => {
        if (percent.value < minPercentValue && !percent.isTrueZero) {
            let remain = minPercentValue - percent.value; // a number which we should add to reach our minimum limit
            percentsArray[index].value += remain;

            let indexArray = 0;
            while (remain > 0) { // iterates our array again and again while remain > 0
                if (indexArray === percentsArray.length) { //
                    indexArray = 0; // start at the beginning of the array
                }

                // skips zero values and values are already equivalent or smaller than then minimum limit
                if (percentsArray[indexArray].isTrueZero || percentsArray[indexArray].value <= minPercentValue) {
                    indexArray++;
                } else {
                    percentsArray[indexArray++].value -= 1; // we found a suitable value
                    remain--;
                }
            }
        }
    });

    // Step 3. Returns a result as an object for ease of use
    const successResult = percentsArray.find(percent => percent.status === 'success');
    const warningResult = percentsArray.find(percent => percent.status === 'warning');
    const failResult = percentsArray.find(percent => percent.status === 'fail');

    return {
        success: [makePercentFromNumber(successResult.value), makePercentFromNumber(successResult.realValue)],
        warning: [makePercentFromNumber(warningResult.value), makePercentFromNumber(warningResult.realValue)],
        fail: [makePercentFromNumber(failResult.value), makePercentFromNumber(failResult.realValue)],
    };
};
