import React, { useEffect } from 'react';
import type { WizardStepData } from '@veeam-vspc/components';
import {
    Form,
    FormLayout,
} from '@veeam-vspc/components';

import type { CompanyUserModel } from '../../../../../CompanyUser/interfaces/company-user-model';
import { stepValidate } from '../../helpers/stepValidate';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useExternalFormApi } from '@veeam-vspc/components/src/form/hooks/useExternalFormApi';

interface LoginInfoFormProps extends WizardStepData<CompanyUserModel> {
    children: React.ReactElement[];
}

export const LoginInfoForm = ({ data, validationState, onDataChange, isEdit, stepData, children }: LoginInfoFormProps) => {
    const langs = useLangs();
    const formApi = useExternalFormApi<CompanyUserModel>();

    useEffect(() => {
        if (stepData.isLoginExist) {
            formApi.setExternalErrors({
                login: RCOP.Lang.THIS_LOGIN_IS_ALREADY_USED,
            });

            delete stepData.isLoginExist;
        }
    });

    return (
        <Form
            value={data}
            validate={data => stepValidate(data, isEdit)}
            validationState={validationState}
            onChange={onDataChange}
            key='loginInfo'
            externalFormApi={formApi}
        >
            <StepLayout
                title={langs.LOGIN_INFO}
                description={langs.SPECIFY_LOGIN_AND_PASSWORD}
            >
                <FormLayout inlineLabel>
                    {children}
                </FormLayout>
            </StepLayout>
        </Form>
    );
};
