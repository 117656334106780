/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedComputerManagedByBackupServerBackup { 
    /**
     * UID assigned to a backup chain.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly backupAgentUid?: string;
    /**
     * UID assigned to a backup job that created the restore point.
     */
    readonly jobUid?: string;
    /**
     * Name of a job that protects the computer.
     */
    readonly jobName?: string;
    /**
     * Job type.
     */
    readonly jobKind?: ProtectedComputerManagedByBackupServerBackup.JobKindEnum;
    /**
     * Total size of protected computer disks, in bytes.
     */
    readonly provisionedSourceSize?: number;
    /**
     * Used space on protected computer disks, in bytes.
     */
    readonly usedSourceSize?: number;
    /**
     * UID assigned to a target repository.
     */
    readonly repositoryUid?: string;
    /**
     * Total size of all restore points, in bytes.
     */
    readonly totalRestorePointSize?: number;
    /**
     * Size of the latest restore point, in bytes.
     */
    readonly latestRestorePointSize?: number;
    /**
     * Number of restore points.
     */
    readonly restorePoints?: number;
    /**
     * Date of the latest restore point creation.
     */
    readonly latestRestorePointDate?: Date;
    /**
     * Type of a target repository.
     */
    readonly targetType?: ProtectedComputerManagedByBackupServerBackup.TargetTypeEnum;
}
export namespace ProtectedComputerManagedByBackupServerBackup {
    export type JobKindEnum = 'Unknown' | 'Backup' | 'Copy';
    export const JobKindEnum = {
        Unknown: 'Unknown' as JobKindEnum,
        Backup: 'Backup' as JobKindEnum,
        Copy: 'Copy' as JobKindEnum
    };
    export type TargetTypeEnum = 'Unknown' | 'Local' | 'Cloud';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        Local: 'Local' as TargetTypeEnum,
        Cloud: 'Cloud' as TargetTypeEnum
    };
}