import React from 'react';
import {
    STACK_ALIGN,
    STACK_GAP,
    StackView,
    Text,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    useGridApi,
    ComboboxKit,
} from '@veeam-vspc/components';

import { UserType } from 'features/RolesAndUsers/wizards/AdminUser/enums/user-type';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import type { ActiveDirectoryAccount } from '../../../../../interfaces/active-directory-account';
import type { AccountFilterModel } from '../../../../../interfaces/account-filter-model';

export const ToolbarFirst = () => {
    const langs = useLangs();
    const gridApi = useGridApi<ActiveDirectoryAccount, any, AccountFilterModel>();

    return (
        <Toolbar
            items={[
                {
                    type: TOOLBAR_ITEM_TYPE.customControl,
                    render: () => (
                        <StackView gap={STACK_GAP.m} align={STACK_ALIGN.center}>
                            <Text>Type:</Text>
                            <ComboboxKit
                                value={gridApi.filters.userType}
                                onChange={userType => gridApi.applyFilters({
                                    userType,
                                })}
                                data={[
                                    { value: UserType.User, text: langs.USER },
                                    { value: UserType.Group, text: langs.GROUP },
                                ]}
                                valueGetter={item => item.value}
                                textGetter={item => item.text}
                            />
                        </StackView>
                    ),
                },
            ]}
        />
    );
};
