import styled from 'styled-components';
import { constants, colors, insertDefaultFontSettings } from '@veeam-vspc/components';

import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT } from 'features/HeatMap/components/HeatMap/HeatMap.styled';

export const DATE_TITLE_HEIGHT = '22px';
export const NARROW_DATE_TITLE_HEIGHT = '13px';

export const DateTitle = styled.div`
  height: ${DATE_TITLE_HEIGHT};
  padding-left: ${constants.SPACING_XS};
  font-size: ${constants.FONT_SIZE_M};
  display: flex;
  align-items: center;
  font-weight: ${constants.BASE_FONT_SEMI_BOLD_WEIGHT};
  color: ${colors.G600};

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
    height: ${NARROW_DATE_TITLE_HEIGHT};
  }
`;

export const DayWrapper = styled.div.attrs<{ 'data-tid'?: string; }>({ 'data-tid': 'day' })`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  height: 100%;
  border-color: ${colors.G300};
  border-style: solid;
  border-width: ${constants.BASE_BORDER_WIDTH} ${constants.BASE_BORDER_WIDTH} 0 0;
  box-sizing: content-box !important;
  background-color: ${colors.G000};
  position: relative;

  ${insertDefaultFontSettings}
`;
