/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupRepository { 
    /**
     * UID assigned to a backup repository.
     */
    readonly instanceUid?: string;
    /**
     * Name of a backup repository.
     */
    readonly name?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * UID assigned to a scale-out backup repository which includes the backup repository as an extent.
     */
    readonly parentRepositoryUid?: string;
    /**
     * VM backup files option in the repository settings is enabled. Displays if the per-VM backup job option is enabled or not in the backup repository settings.
     */
    readonly perVMBackupFiles?: boolean;
    /**
     * Total disk space of backup repository, in bytes.
     */
    readonly capacity?: number;
    /**
     * Indicates whether information about total disk space is available.
     */
    readonly isCapacityAvailable?: boolean;
    /**
     * Free disk space of backup repository, in bytes.
     */
    readonly freeSpace?: number;
    /**
     * Indicates whether a backup repository has free space.
     */
    readonly isFreeSpaceAvailable?: boolean;
    /**
     * Amount of used space on a backup repository, in bytes.
     */
    readonly usedSpace?: number;
    /**
     * Indicates whether information about used space is available.
     */
    readonly isUsedSpaceAvailable?: boolean;
    /**
     * Type of a backup repository.
     */
    readonly type?: BackupRepository.TypeEnum;
    /**
     * UID assigned to a backup repository if it is used as a cloud repository.
     */
    readonly cloudRepositoryUid?: string;
    /**
     * Path to the folder where backup files are stored.
     */
    readonly path?: string;
    /**
     * Name of a computer that performs a role of a backup repository.
     */
    readonly hostName?: string;
    /**
     * UID assigned to a computer that performs a role of a backup repository.
     */
    readonly hostUid?: string;
    /**
     * Indicates whether a backup repository service is outdated.
     */
    readonly isOutOfDate?: boolean;
    /**
     * Status of a backup repository.
     */
    readonly status?: BackupRepository.StatusEnum;
    /**
     * Indicates whether a backup repository is used as a cloud repository.
     */
    readonly isCloud?: boolean;
}
export namespace BackupRepository {
    export type TypeEnum = 'Unknown' | 'Windows' | 'Linux' | 'Share' | 'Cloud' | 'ScaleOut' | 'MicrosoftAzureBlobStorage' | 'AmazonS3' | 'AmazonS3Compatible' | 'AmazonS3External' | 'AzureExternal' | 'DellEmcDataDomain' | 'HpeStoreOnce' | 'QuantumDxi' | 'IbmCloudObjectStorage' | 'NFS' | 'ExaGrid';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        Windows: 'Windows' as TypeEnum,
        Linux: 'Linux' as TypeEnum,
        Share: 'Share' as TypeEnum,
        Cloud: 'Cloud' as TypeEnum,
        ScaleOut: 'ScaleOut' as TypeEnum,
        MicrosoftAzureBlobStorage: 'MicrosoftAzureBlobStorage' as TypeEnum,
        AmazonS3: 'AmazonS3' as TypeEnum,
        AmazonS3Compatible: 'AmazonS3Compatible' as TypeEnum,
        AmazonS3External: 'AmazonS3External' as TypeEnum,
        AzureExternal: 'AzureExternal' as TypeEnum,
        DellEmcDataDomain: 'DellEmcDataDomain' as TypeEnum,
        HpeStoreOnce: 'HpeStoreOnce' as TypeEnum,
        QuantumDxi: 'QuantumDxi' as TypeEnum,
        IbmCloudObjectStorage: 'IbmCloudObjectStorage' as TypeEnum,
        NFS: 'NFS' as TypeEnum,
        ExaGrid: 'ExaGrid' as TypeEnum
    };
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Warning' | 'Error';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum
    };
}