import type { PortalFeatureResponse } from '../../../services/portal/interfaces';
import type { FeatureData } from '../interfaces';
import type { FeatureNames } from '../enums';

export class PortalFeatures {

    protected features: { [key in FeatureNames]?: FeatureData } = {};

    constructor(featuresArr: PortalFeatureResponse[]) {
        featuresArr.forEach(({ id: featureId, ...restData }) => {
            this.features[featureId] = { featureId, ...restData };
        });
    }

    isFeatureEnabled(featureId: FeatureNames): boolean {
        return this.features[featureId] && !this.features[featureId].unsupported;
    }

}
