/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerFileTapeJobObjectSource { 
    /**
     * Path to a location where protected files and folders reside.
     */
    readonly path?: string;
    /**
     * Type of a protected unit.
     */
    readonly type?: BackupServerFileTapeJobObjectSource.TypeEnum;
}
export namespace BackupServerFileTapeJobObjectSource {
    export type TypeEnum = 'Unknown' | 'File' | 'Directory';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        File: 'File' as TypeEnum,
        Directory: 'Directory' as TypeEnum
    };
}