/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsMsSqlAccountSettings } from './windowsMsSqlAccountSettings';
import { WindowsPolicyPeriodicallyLogBackupSettings } from './windowsPolicyPeriodicallyLogBackupSettings';

export interface WindowsSqlServerTransactionLogHandlingSettings { 
    /**
     * Credentials of a user account that Veeam Agent for Microsoft Windows must use to connect to the Microsoft SQL Server.
     */
    credentials?: WindowsMsSqlAccountSettings;
    /**
     * Transaction log processing mode.
     */
    logsProcessingMode?: WindowsSqlServerTransactionLogHandlingSettings.LogsProcessingModeEnum;
    /**
     * Settings for periodic transaction log backup.
     */
    periodicallyBackupSetting?: WindowsPolicyPeriodicallyLogBackupSettings;
}
export namespace WindowsSqlServerTransactionLogHandlingSettings {
    export type LogsProcessingModeEnum = 'TruncateLogs' | 'DoNotTruncateLogs' | 'BackupLogsPeriodically';
    export const LogsProcessingModeEnum = {
        TruncateLogs: 'TruncateLogs' as LogsProcessingModeEnum,
        DoNotTruncateLogs: 'DoNotTruncateLogs' as LogsProcessingModeEnum,
        BackupLogsPeriodically: 'BackupLogsPeriodically' as LogsProcessingModeEnum
    };
}