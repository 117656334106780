import type { BaseRequestResponse, BaseSuccessRequestResponse, TransportService } from '@veeam-vspc/core';
import { addParamsToQuery } from '@veeam-vspc/core';
import { themes } from '@veeam-vspc/components';

import type { IdentityProviderSettings, InlineResponse20028 } from 'swagger/models';

import { PortalData } from '../../../entries';
import type {
    LoginDataResponse,
    LoginResponse,
    PortalDataRequestOptions,
    PortalFeatureResponse,
    PortalServiceParams,
} from '../interfaces';
import type { PortalDataParams } from '../../../entries/portal-data/interfaces';

export class PortalService {

    protected static instance: PortalService;

    protected readonly portalDataUrl: string;
    protected readonly enabledFeaturesUrl: string | void;
    protected readonly identityProvidersUrl: string | void;

    protected portalData: PortalData | null = null;

    constructor(
        protected readonly transportService: TransportService,
        { portalDataUrl, enabledFeaturesUrl, identityProvidersUrl }: PortalServiceParams
    ) {
        if (!PortalService.instance) {
            PortalService.instance = this;

            this.portalDataUrl = portalDataUrl;
            this.enabledFeaturesUrl = enabledFeaturesUrl;
            this.identityProvidersUrl = identityProvidersUrl;
        }

        return PortalService.instance;
    }

    getData(requestOptions: PortalDataRequestOptions = {}): Promise<PortalData> {
        return this.getAllPortalData(requestOptions)
            .then(([loginData, identityProviders, featuresList]) => this.savePortalData({
                ...loginData as LoginDataResponse,
                identityProviders,
                featuresList,
            }));
    }

    getPortalData(): PortalData | null {
        return this.portalData;
    }

    getProductTheme(): themes.Theme {
        const { theme } = this.portalData;
        const vspcThemesMap = {
            blue: themes.blue,
            green: themes.green,
            yellow: themes.yellow,
            turquoise: themes.teal,
            red: themes.red,
        };

        return vspcThemesMap[theme];
    }

    protected getAllPortalData(
        { noAuth = false, brandingUrl }: PortalDataRequestOptions
    ): Promise<[LoginResponse, IdentityProviderSettings[] | null, PortalFeatureResponse[] | null]> {
        return Promise.all([
            this.transportService.request<void, LoginResponse>(this.portalDataUrl, null, null, { noAuth }),
            this.getIdentityProviders(noAuth),
            this.getEnabledFeatures(brandingUrl),
        ]);
    }

    protected getIdentityProviders(noAuth: boolean): Promise<IdentityProviderSettings[] | null> {
        return (noAuth && this.identityProvidersUrl)
            ? this.transportService.get<void, InlineResponse20028>(this.identityProvidersUrl, null, null, { noAuth })
                .then(resp => resp.data)
            : Promise.resolve(null);
    }

    protected getEnabledFeatures(brandingUrl?: string): Promise<PortalFeatureResponse[] | null> {
        const url = this.enabledFeaturesUrl
            ? addParamsToQuery(this.enabledFeaturesUrl, brandingUrl ? { brandingUrl } : {})
            : null;

        return url
            ? this.transportService.request<void, BaseRequestResponse<PortalFeatureResponse[]>>(url, null, null, { noAuth: true })
                .then((resp: BaseSuccessRequestResponse<PortalFeatureResponse[]>) => resp.data)
            : Promise.resolve(null);
    }

    protected savePortalData(loginData: PortalDataParams): PortalData {
        this.setPortalData(new PortalData(loginData));

        return this.getPortalData();
    }

    protected setPortalData(portalData: PortalData): void {
        this.portalData = portalData;
    }

}
