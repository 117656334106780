/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsActiveDirectoryBasedDiscoveryRule { 
    /**
     * UID assigned to an Active Directory discovery rule.
     */
    readonly instanceUid?: string;
    /**
     * LDAP query that returns a list of computers to scan.
     */
    customQuery?: string;
    /**
     * Active Directory discovery method.
     */
    adMethod: WindowsActiveDirectoryBasedDiscoveryRule.AdMethodEnum;
    /**
     * Number of days for which offline computers are skipped from discovery.
     */
    skipOfflineComputersDays?: number;
}
export namespace WindowsActiveDirectoryBasedDiscoveryRule {
    export type AdMethodEnum = 'Unknown' | 'Search' | 'Query' | 'Custom';
    export const AdMethodEnum = {
        Unknown: 'Unknown' as AdMethodEnum,
        Search: 'Search' as AdMethodEnum,
        Query: 'Query' as AdMethodEnum,
        Custom: 'Custom' as AdMethodEnum
    };
}