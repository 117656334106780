import React from 'react';
import { AdminSSORule } from 'features/RolesAndUsers/wizards/AdminSSORule';
import { AdminUser } from 'features/RolesAndUsers/wizards/AdminUser';
import { CompanySSORule } from 'features/RolesAndUsers/wizards/CompanySSORule';
import { CompanyUser } from 'features/RolesAndUsers/wizards/CompanyUser';
import { SPSSORule } from 'features/RolesAndUsers/wizards/SPSSORule';
import { SPUser } from 'features/RolesAndUsers/wizards/SPUser';
import { SSORuleProps } from 'features/RolesAndUsers/wizards/Base/interfaces/sso-rule-props';
import { AdminUserProps } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/admin-user-props';
import { CompanyUserProps } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/company-user-props';
import { SPUserProps } from 'features/RolesAndUsers/wizards/SPUser/interfaces/sp-user-props';
import { RolesAndUsersWizardType } from '../../enums/roles-and-users-wizard-type';


export type WizardManagerProps  = SSORuleProps | AdminUserProps | CompanyUserProps | SPUserProps;

export const WizardManager = (props: WizardManagerProps) => {
    switch (props.wizard) {
        case RolesAndUsersWizardType.AdminSSORule:
            return <AdminSSORule {...props as SSORuleProps} />;
        case RolesAndUsersWizardType.AdminUser:
            return <AdminUser {...props as AdminUserProps} />;
        case RolesAndUsersWizardType.CompanySSORule:
            return <CompanySSORule {...props as SSORuleProps} />;
        case RolesAndUsersWizardType.CompanyUser:
            return <CompanyUser {...props as CompanyUserProps} />;
        case RolesAndUsersWizardType.SPSSORule:
            return <SPSSORule {...props as SSORuleProps} />;
        case RolesAndUsersWizardType.SPUser:
            return <SPUser {...props as SPUserProps} />;
    }
};
