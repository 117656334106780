/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxBaseCredentials } from './linuxBaseCredentials';
import { LinuxOracleArchivedLogsTruncationConfig } from './linuxOracleArchivedLogsTruncationConfig';

export interface LinuxOracleApplicationAwareProcessingSettings { 
    /**
     * Processing type.
     */
    processingType?: LinuxOracleApplicationAwareProcessingSettings.ProcessingTypeEnum;
    /**
     * Credentials of a user account that Veeam Agent for Linux must use to connect to the Oracle database.
     */
    credentials?: LinuxBaseCredentials;
    /**
     * Archived log processing settings.
     */
    truncationConfig?: LinuxOracleArchivedLogsTruncationConfig;
    /**
     * Indicates whether the Oracle account credentials must be used.
     */
    useOracleCredentials?: boolean;
}
export namespace LinuxOracleApplicationAwareProcessingSettings {
    export type ProcessingTypeEnum = 'DisableProcess' | 'TryProcess' | 'RequireSuccess';
    export const ProcessingTypeEnum = {
        DisableProcess: 'DisableProcess' as ProcessingTypeEnum,
        TryProcess: 'TryProcess' as ProcessingTypeEnum,
        RequireSuccess: 'RequireSuccess' as ProcessingTypeEnum
    };
}