import React from 'react';

import { PageLayout } from 'views/layouts/PageLayout';

import { Login } from '../Login/Login';

export const LoginPage = () => (
    <PageLayout>
        <Login />
    </PageLayout>
);
