/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinuxIndexingSettings { 
    /**
     * Indexing mode.
     */
    indexingType?: LinuxIndexingSettings.IndexingTypeEnum;
    /**
     * Array of paths to the indexed folders. > Required for the `SpecifiedFolders` indexing mode.' 
     */
    includedFolders?: Array<string>;
    /**
     * Array of paths to folders that are excluded from the indexing scope. > Required for the `ExceptSpecifiedFolders` indexing mode. 
     */
    excludedFolders?: Array<string>;
}
export namespace LinuxIndexingSettings {
    export type IndexingTypeEnum = 'None' | 'EveryFolders' | 'SpecifiedFolders' | 'ExceptSpecifiedFolders';
    export const IndexingTypeEnum = {
        None: 'None' as IndexingTypeEnum,
        EveryFolders: 'EveryFolders' as IndexingTypeEnum,
        SpecifiedFolders: 'SpecifiedFolders' as IndexingTypeEnum,
        ExceptSpecifiedFolders: 'ExceptSpecifiedFolders' as IndexingTypeEnum
    };
}