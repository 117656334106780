/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DiscoveryRuleNotificationSettings { 
    /**
     * Indicates whether notifications about discovery results are enabled.
     */
    isEnabled?: boolean;
    /**
     * Notification frequency.
     */
    scheduleType: DiscoveryRuleNotificationSettings.ScheduleTypeEnum;
    /**
     * Time at which notifications must are sent.
     */
    scheduleTime?: string;
    /**
     * Day at which notifications must are sent.
     */
    scheduleDay?: DiscoveryRuleNotificationSettings.ScheduleDayEnum;
    /**
     * Email address at which notifications must be sent.
     */
    to?: string;
    /**
     * Subject of a notification message.
     */
    subject?: string;
    /**
     * Indicates whether a notification must be sent on the first 
     */
    notifyOnTheFirstRun?: boolean;
}
export namespace DiscoveryRuleNotificationSettings {
    export type ScheduleTypeEnum = 'Days' | 'Weeks';
    export const ScheduleTypeEnum = {
        Days: 'Days' as ScheduleTypeEnum,
        Weeks: 'Weeks' as ScheduleTypeEnum
    };
    export type ScheduleDayEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const ScheduleDayEnum = {
        Sunday: 'Sunday' as ScheduleDayEnum,
        Monday: 'Monday' as ScheduleDayEnum,
        Tuesday: 'Tuesday' as ScheduleDayEnum,
        Wednesday: 'Wednesday' as ScheduleDayEnum,
        Thursday: 'Thursday' as ScheduleDayEnum,
        Friday: 'Friday' as ScheduleDayEnum,
        Saturday: 'Saturday' as ScheduleDayEnum
    };
}