/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ConsoleLicenseSummaryCounter { 
    /**
     * License type.
     */
    readonly type?: ConsoleLicenseSummaryCounter.TypeEnum;
    /**
     * License unit type.
     */
    readonly unitType?: ConsoleLicenseSummaryCounter.UnitTypeEnum;
    /**
     * Number of units that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    readonly usedUnits?: number;
    /**
     * Number of units that were activated within the current calendar month.
     */
    readonly newUnits?: number;
    /**
     * Number of objects that were activated within the current calendar month.
     */
    readonly newCount?: number;
    /**
     * Number of objects that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    readonly usedCount?: number;
}
export namespace ConsoleLicenseSummaryCounter {
    export type TypeEnum = 'Unknown' | 'VAC_Server_Agent' | 'VAC_Workstation_Agent';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        VACServerAgent: 'VAC_Server_Agent' as TypeEnum,
        VACWorkstationAgent: 'VAC_Workstation_Agent' as TypeEnum
    };
    export type UnitTypeEnum = 'Unknown' | 'Instances' | 'Points';
    export const UnitTypeEnum = {
        Unknown: 'Unknown' as UnitTypeEnum,
        Instances: 'Instances' as UnitTypeEnum,
        Points: 'Points' as UnitTypeEnum
    };
}