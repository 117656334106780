import React from 'react';
import { DayTitleWrapper, WeekTitleWrapper } from './WeekTitle.styled';

export const WeekTitle = () => {
    return (
        <WeekTitleWrapper>
            <DayTitleWrapper>Sunday</DayTitleWrapper>
            <DayTitleWrapper>Monday</DayTitleWrapper>
            <DayTitleWrapper>Tuesday</DayTitleWrapper>
            <DayTitleWrapper>Wednesday</DayTitleWrapper>
            <DayTitleWrapper>Thursday</DayTitleWrapper>
            <DayTitleWrapper>Friday</DayTitleWrapper>
            <DayTitleWrapper>Saturday</DayTitleWrapper>
        </WeekTitleWrapper>
    );
};
