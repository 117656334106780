/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EnterpriseManager { 
    /**
     * UID assigned to a Veeam Backup Enterprise Manager.
     */
    readonly instanceUid?: string;
    /**
     * Hostname of a Veeam Backup Enterprise Manager server.
     */
    readonly name?: string;
    /**
     * UID assigned to a management agent installed on a Veeam Backup Enterprise Manager server.
     */
    readonly managementAgentUid?: string;
    /**
     * UID assigned to a Veeam Backup Enterprise Manager installation.
     */
    readonly installationUid?: string;
    /**
     * Version of a Veeam Backup Enterprise Manager.
     */
    readonly version?: string;
    /**
     * Status of a Veeam Backup Enterprise Manager.
     */
    readonly status?: EnterpriseManager.StatusEnum;
}
export namespace EnterpriseManager {
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Inaccessible' | 'OutofDate';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Inaccessible: 'Inaccessible' as StatusEnum,
        OutofDate: 'OutofDate' as StatusEnum
    };
}