import React from 'react';
import { WizardStep, WizardSummary, WizardStepData, CheckboxKit } from '@veeam-vspc/components';

import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { StepLayout } from 'components/layouts/StepLayout';
import { SPUserModel } from '../../interfaces/sp-user-model';
import { ManagedCompaniesMode } from '../../enums/managed-companies-mode';
import { UserMfaStatuses } from '../../../Base/components/MultiFactorAuthentication/user-mfa-statuses';
import { ROLES } from '../../../../../../const';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const Summary = ({ data, onDataChange }: WizardStepData<SPUserModel>) => {
    const langs = useLangs();

    return (
        <StepLayout
            title={langs.SUMMARY}
            description={langs.REVIEW_USER_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${langs.USER_INFO}:`,
                        fields: [
                            {
                                label: `${langs.FIRST_NAME}:`,
                                value: data.firstName
                            },
                            {
                                label: `${langs.LAST_NAME}:`,
                                value: data.lastName
                            },
                            {
                                label: `${langs.EMAIL_ADDRESS}:`,
                                value: data.email
                            },
                            {
                                label: `${langs.USERNAME}:`,
                                value: data.login,
                            },
                        ]
                    },
                    {
                        title: `${langs.ROLE}:`,
                        fields: [
                            {
                                label: `${langs.ROLE}:`,
                                value: data.userRole,
                                transform: (value: PortalUserRoles) => ROLES[value],
                            },
                        ]
                    },
                    (data.userRole === PortalUserRoles.ServiceProviderUser || data.userRole === PortalUserRoles.ServiceProviderOperator) && {
                        title: `${langs.COMPANIES}:`,
                        fields: [
                            {
                                label: `${langs.MANAGED_COMPANIES}:`,
                                value: data.selectAllCompanies === ManagedCompaniesMode.CustomSelection
                                    ? data.companies.map(company => company.name).join(', ')
                                    : langs.ALL,
                            },
                        ]
                    },
                    {
                        title: `${langs.MULTI_FACTOR_AUTHENTICATION}:`,
                        fields: [
                            {
                                label: `${langs.MFA_STATUS}:`,
                                value: data.mfaPolicyStatus,
                                transform: (value: UserMfaStatuses) => value === UserMfaStatuses.Disabled ? langs.NOT_ENFORCED : langs.ENFORCED,
                            },
                        ]
                    },
                    {
                        renderBody: data.userRole === PortalUserRoles.ServiceProviderInvoiceAuditor &&
                            <CheckboxKit
                                checked={data.sendInvoice}
                                onChange={(isChecked) => {
                                    data.sendInvoice = isChecked;

                                    onDataChange(data);
                                }}
                            >
                                {langs.ENABLE_AUTOMATIC_DELIVERY_OF_INVOICES}
                            </CheckboxKit>
                    }
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<SPUserModel> => ({
    title,
    render: data => <Summary {...data} />
});
