/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedVirtualMachineBackup { 
    /**
     * UID assigned to a backup chain.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a virtual machine.
     */
    readonly virtualMachineUid?: string;
    /**
     * UID assigned to a backup job.
     */
    readonly jobUid?: string;
    /**
     * Backup job type.
     */
    readonly backupType?: ProtectedVirtualMachineBackup.BackupTypeEnum;
    /**
     * UID assigned to a repository on which the restore point resides.
     */
    readonly repositoryUid?: string;
    /**
     * Total size of all restore points, in bytes.
     */
    readonly totalRestorePointSize?: number;
    /**
     * Size of the latest restore point, in bytes.
     */
    readonly latestRestorePointSize?: number;
    /**
     * Number of restore points.
     */
    readonly restorePoints?: number;
    /**
     * Date of the latest restore point creation.
     */
    readonly latestRestorePointDate?: Date;
    /**
     * Type of a target repository.
     */
    readonly targetType?: ProtectedVirtualMachineBackup.TargetTypeEnum;
}
export namespace ProtectedVirtualMachineBackup {
    export type BackupTypeEnum = 'Unknown' | 'Backup' | 'Replication' | 'BackupCopy' | 'BackupToTape';
    export const BackupTypeEnum = {
        Unknown: 'Unknown' as BackupTypeEnum,
        Backup: 'Backup' as BackupTypeEnum,
        Replication: 'Replication' as BackupTypeEnum,
        BackupCopy: 'BackupCopy' as BackupTypeEnum,
        BackupToTape: 'BackupToTape' as BackupTypeEnum
    };
    export type TargetTypeEnum = 'Unknown' | 'Local' | 'Cloud';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        Local: 'Local' as TargetTypeEnum,
        Cloud: 'Cloud' as TargetTypeEnum
    };
}