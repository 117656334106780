import styled from 'styled-components';
import { insertDefaultFontSettings } from '@veeam-vspc/components';

export const HeatMapWrapper = styled.div`
  height: 100%;

  ${insertDefaultFontSettings}
`;

export const NARROW_HEAT_MAP_HEIGHT_BREAKPOINT = '800px';
export const MAX_INNER_HEIGHT_HEAT_MAP_CONTAINER = '1000px';
export const MAX_HEAT_MAP_DAYS = 42;
export const MAX_HEAT_MAP_WEEKS = MAX_HEAT_MAP_DAYS / 7;
