/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DiscoveryRuleDailyScheduleSettings { 
    /**
     * Time of the day when discovery must run.
     */
    time?: string;
    /**
     * Week days on which discovery must be performed. > Required for the `SpecificDay` schedule type. 
     */
    specificDays?: Array<DiscoveryRuleDailyScheduleSettings.SpecificDaysEnum>;
}
export namespace DiscoveryRuleDailyScheduleSettings {
    export type SpecificDaysEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const SpecificDaysEnum = {
        Sunday: 'Sunday' as SpecificDaysEnum,
        Monday: 'Monday' as SpecificDaysEnum,
        Tuesday: 'Tuesday' as SpecificDaysEnum,
        Wednesday: 'Wednesday' as SpecificDaysEnum,
        Thursday: 'Thursday' as SpecificDaysEnum,
        Friday: 'Friday' as SpecificDaysEnum,
        Saturday: 'Saturday' as SpecificDaysEnum
    };
}