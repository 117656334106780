/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DiscoveryRuleFilter { 
    /**
     * Array of applied exclusion masks. For custom discovery rules this property is ignored.
     */
    exclusionMask?: Array<string>;
    /**
     * Indicates whether discovery is performed among accessible computers only.
     */
    ignoreInaccessibleMachine?: boolean;
    /**
     * Type of operating system.
     */
    osTypes?: Array<DiscoveryRuleFilter.OsTypesEnum>;
    /**
     * Applications that must run on discovered computers.
     */
    applications?: Array<DiscoveryRuleFilter.ApplicationsEnum>;
    /**
     * Name of an application required for the `OtherApp` application type. > Available only for Linux computers. 
     */
    customApplication?: string;
    /**
     * Platforms on which discovered computers must run.
     */
    platforms?: Array<DiscoveryRuleFilter.PlatformsEnum>;
}
export namespace DiscoveryRuleFilter {
    export type OsTypesEnum = 'Unknown' | 'WindowsServer' | 'WindowsWorkstation' | 'CentOS' | 'Debian' | 'OracleLinux' | 'Fedora' | 'Ubuntu' | 'OpenSUSE' | 'SLES' | 'RedHat';
    export const OsTypesEnum = {
        Unknown: 'Unknown' as OsTypesEnum,
        WindowsServer: 'WindowsServer' as OsTypesEnum,
        WindowsWorkstation: 'WindowsWorkstation' as OsTypesEnum,
        CentOS: 'CentOS' as OsTypesEnum,
        Debian: 'Debian' as OsTypesEnum,
        OracleLinux: 'OracleLinux' as OsTypesEnum,
        Fedora: 'Fedora' as OsTypesEnum,
        Ubuntu: 'Ubuntu' as OsTypesEnum,
        OpenSUSE: 'OpenSUSE' as OsTypesEnum,
        SLES: 'SLES' as OsTypesEnum,
        RedHat: 'RedHat' as OsTypesEnum
    };
    export type ApplicationsEnum = 'Unknown' | 'OtherApp' | 'MicrosoftExchangeServer' | 'MicrosoftSqlServer' | 'MicrosoftActiveDirectory' | 'MicrosoftSharePoint' | 'Oracle' | 'MySQL' | 'PostgreSQL' | 'MongoDB' | 'ApacheServer';
    export const ApplicationsEnum = {
        Unknown: 'Unknown' as ApplicationsEnum,
        OtherApp: 'OtherApp' as ApplicationsEnum,
        MicrosoftExchangeServer: 'MicrosoftExchangeServer' as ApplicationsEnum,
        MicrosoftSqlServer: 'MicrosoftSqlServer' as ApplicationsEnum,
        MicrosoftActiveDirectory: 'MicrosoftActiveDirectory' as ApplicationsEnum,
        MicrosoftSharePoint: 'MicrosoftSharePoint' as ApplicationsEnum,
        Oracle: 'Oracle' as ApplicationsEnum,
        MySQL: 'MySQL' as ApplicationsEnum,
        PostgreSQL: 'PostgreSQL' as ApplicationsEnum,
        MongoDB: 'MongoDB' as ApplicationsEnum,
        ApacheServer: 'ApacheServer' as ApplicationsEnum
    };
    export type PlatformsEnum = 'Unknown' | 'Other' | 'MicrosoftHyperVandVmWareVSpere' | 'Physical' | 'MicrosoftAzure' | 'AmazonWebServices';
    export const PlatformsEnum = {
        Unknown: 'Unknown' as PlatformsEnum,
        Other: 'Other' as PlatformsEnum,
        MicrosoftHyperVandVmWareVSpere: 'MicrosoftHyperVandVmWareVSpere' as PlatformsEnum,
        Physical: 'Physical' as PlatformsEnum,
        MicrosoftAzure: 'MicrosoftAzure' as PlatformsEnum,
        AmazonWebServices: 'AmazonWebServices' as PlatformsEnum
    };
}