/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IntegrationFeature { 
    /**
     * Feature type.
     */
    type: IntegrationFeature.TypeEnum;
    /**
     * Feature status.
     */
    status: IntegrationFeature.StatusEnum;
}
export namespace IntegrationFeature {
    export type TypeEnum = 'Unknown' | 'Companies' | 'Ticketing' | 'Billing' | 'Configuration';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        Companies: 'Companies' as TypeEnum,
        Ticketing: 'Ticketing' as TypeEnum,
        Billing: 'Billing' as TypeEnum,
        Configuration: 'Configuration' as TypeEnum
    };
    export type StatusEnum = 'Enabled' | 'Disabled';
    export const StatusEnum = {
        Enabled: 'Enabled' as StatusEnum,
        Disabled: 'Disabled' as StatusEnum
    };
}