/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PolicySettings { 
    /**
     * Organization MFA policy status.
     */
    mfaPolicyStatus?: PolicySettings.MfaPolicyStatusEnum;
    /**
     * Enforce MFA policy for child organizations.
     */
    enforceMfaPolicy?: boolean;
}
export namespace PolicySettings {
    export type MfaPolicyStatusEnum = 'Unknown' | 'Disabled' | 'Enabled' | 'EnabledByInheritance';
    export const MfaPolicyStatusEnum = {
        Unknown: 'Unknown' as MfaPolicyStatusEnum,
        Disabled: 'Disabled' as MfaPolicyStatusEnum,
        Enabled: 'Enabled' as MfaPolicyStatusEnum,
        EnabledByInheritance: 'EnabledByInheritance' as MfaPolicyStatusEnum
    };
}