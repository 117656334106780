/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AlarmKnowledge } from './alarmKnowledge';

export interface Alarm { 
    /**
     * UID assigned to an alarm template.
     */
    readonly instanceUid?: string;
    /**
     * Name of an alarm template.
     */
    name: string;
    /**
     * Category of an alarm template.
     */
    readonly category?: Alarm.CategoryEnum;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid?: string;
    /**
     * ID assigned to an alarm template in Veeam Service Provider Console internal alarm database.
     */
    readonly internalId?: number;
    /**
     * Knowledge base for an alarm template.
     */
    readonly knowledge?: AlarmKnowledge;
    /**
     * Indicates whether an alarm template is predefined.
     */
    readonly isPredifined?: boolean;
    /**
     *  Indicates whether an alarm template is enabled.
     */
    isEnabled: boolean;
}
export namespace Alarm {
    export type CategoryEnum = 'Unknown' | 'ManagementAgent' | 'Location' | 'Company' | 'User' | 'Internal' | 'BackupAgentForWindows' | 'VmFailoverPlan' | 'BackupServer' | 'EnterpriseManager' | 'BackupProxy' | 'BackupRepository' | 'BackupVmJob' | 'BackupServerAgentJob' | 'BackupWanAccelerator' | 'BackupCloudGateway' | 'BackupLicense' | 'BackupTenantRepository' | 'SureBackupJob' | 'BackupAgentJobForWindows' | 'DiscoveryRule' | 'Integrator' | 'Site' | 'Reseller' | 'ResellerCloudRepository';
    export const CategoryEnum = {
        Unknown: 'Unknown' as CategoryEnum,
        ManagementAgent: 'ManagementAgent' as CategoryEnum,
        Location: 'Location' as CategoryEnum,
        Company: 'Company' as CategoryEnum,
        User: 'User' as CategoryEnum,
        Internal: 'Internal' as CategoryEnum,
        BackupAgentForWindows: 'BackupAgentForWindows' as CategoryEnum,
        VmFailoverPlan: 'VmFailoverPlan' as CategoryEnum,
        BackupServer: 'BackupServer' as CategoryEnum,
        EnterpriseManager: 'EnterpriseManager' as CategoryEnum,
        BackupProxy: 'BackupProxy' as CategoryEnum,
        BackupRepository: 'BackupRepository' as CategoryEnum,
        BackupVmJob: 'BackupVmJob' as CategoryEnum,
        BackupServerAgentJob: 'BackupServerAgentJob' as CategoryEnum,
        BackupWanAccelerator: 'BackupWanAccelerator' as CategoryEnum,
        BackupCloudGateway: 'BackupCloudGateway' as CategoryEnum,
        BackupLicense: 'BackupLicense' as CategoryEnum,
        BackupTenantRepository: 'BackupTenantRepository' as CategoryEnum,
        SureBackupJob: 'SureBackupJob' as CategoryEnum,
        BackupAgentJobForWindows: 'BackupAgentJobForWindows' as CategoryEnum,
        DiscoveryRule: 'DiscoveryRule' as CategoryEnum,
        Integrator: 'Integrator' as CategoryEnum,
        Site: 'Site' as CategoryEnum,
        Reseller: 'Reseller' as CategoryEnum,
        ResellerCloudRepository: 'ResellerCloudRepository' as CategoryEnum
    };
}