import React from 'react';
import { StepLayout } from 'components/layouts/StepLayout';
import {
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Textarea,
    TextInput,
    WizardStep,
    WizardStepData,
    immediatelyValidateFlag
} from '@veeam-vspc/components';
import { RuleInfoModel } from './rule-info-model';
import { useLangs } from 'views/layouts/LangLayout/hooks';

const stepValidate = (data: RuleInfoModel) => {
    const validator = new FormValidator(data);

    validator.validate('model.name').string().required().maxLength(50).check(value => {
        if (data.rules.map(rule => rule.name).includes(value)) {
            return `${RCOP.Lang.THE_RULE_WITH_THE_SAME_NAME_ALREADY_EXISTS}${immediatelyValidateFlag}`;
        }
    });
    validator.validate('model.description').string().maxLength(500);

    return validator.result();
};

export const RuleInfo = ({ data, onDataChange, validationState }: WizardStepData<RuleInfoModel>) => {
    const langs = useLangs();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.RULE_INFO}
                description={langs.SPECIFY_RULE}
            >
                <StackView gap={STACK_GAP.m} direction={STACK_DIRECTION.column}>
                    <TextInput
                        name='model.name'
                        label={langs.NAME}
                    />
                    <Textarea
                        name='model.description'
                        label={langs.DESCRIPTION}
                        rows={16}
                    />
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRuleInfoStep = (title: string): WizardStep<RuleInfoModel> => ({
    title,
    validate: ({ data }) => {
        return isValid(stepValidate, data);
    },
    render: (data) => <RuleInfo {...data} />,
    id: 'ruleInfo',
});
