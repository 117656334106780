import React, { useEffect } from 'react';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/usePageWizardStore';
import {
    Link,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    WizardStep,
    WizardStepData
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { LocationsPanel } from './components/LocationsPanel';
import { CompanyUserModel } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/company-user-model';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { useLangs } from 'views/layouts/LangLayout/hooks';

const Locations = (wizardStepData: WizardStepData<CompanyUserModel>) => {
    const langs = useLangs();
    const { data, validationState } = wizardStepData;
    const wizardContext = usePageWizardStore<CompanyUserModel>();
    const locationsCount = wizardStepData.data.locations.length;

    const openPanel = () => {
        wizardContext.openPanel(hide => <LocationsPanel {...wizardStepData} hidePanel={hide}/>);
    };

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.locations.length === 0) {
                openPanel();
            }
        }
    });

    return (
        <StepLayout
            title={langs.LOCATIONS}
            description={langs.SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_USER}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                <Link onClick={openPanel}>
                    {`${locationsCount} ${RCOP.utils.String.plural(locationsCount, langs.LOCATION.toLowerCase())} ${langs.SELECTED.toLowerCase()}...`}
                </Link>
            </StackView>
        </StepLayout>
    );
};

export const getLocationsStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    isHidden: ({ data }) => {
        const roles = [
            PortalUserRoles.LocationUser,
            PortalUserRoles.LocationAdministrator,
            PortalUserRoles.Subtenant,
        ];

        return !roles.includes(data.userRole);
    },
    validate({ data }) {
        return data.locations.length !== 0;
    },
    render: data => <Locations {...data} />,
});


