export const getClientDocUrl = (): string => {
    if (RCOP.utils.Portal.isAdminPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(643);
    }

    if (RCOP.utils.Portal.isResellerPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(645);
    }

    if (RCOP.utils.Portal.isClientPortal()) {
        return RCOP.utils.HelpCenter.getHelpResource(644);
    }

    throw new Error('You are in an unknown portal');
};
