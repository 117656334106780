/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerHost { 
    /**
     * UID assigned to a server.
     */
    readonly instanceUid?: string;
    /**
     * Host name of a server.
     */
    readonly name?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * Type of a server.
     */
    readonly type?: BackupServerHost.TypeEnum;
}
export namespace BackupServerHost {
    export type TypeEnum = 'Unknown' | 'ESX' | 'ESXi' | 'VirtualCenter' | 'LinuxHost' | 'LocalHost' | 'WindowsHost' | 'Share' | 'HyperVServer' | 'HyperVCluster' | 'Scvmm' | 'BackupServer' | 'SmbServer' | 'SmbCluster' | 'VcdSystem' | 'ExternalInfrastructureServer' | 'NasFiler';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        ESX: 'ESX' as TypeEnum,
        ESXi: 'ESXi' as TypeEnum,
        VirtualCenter: 'VirtualCenter' as TypeEnum,
        LinuxHost: 'LinuxHost' as TypeEnum,
        LocalHost: 'LocalHost' as TypeEnum,
        WindowsHost: 'WindowsHost' as TypeEnum,
        Share: 'Share' as TypeEnum,
        HyperVServer: 'HyperVServer' as TypeEnum,
        HyperVCluster: 'HyperVCluster' as TypeEnum,
        Scvmm: 'Scvmm' as TypeEnum,
        BackupServer: 'BackupServer' as TypeEnum,
        SmbServer: 'SmbServer' as TypeEnum,
        SmbCluster: 'SmbCluster' as TypeEnum,
        VcdSystem: 'VcdSystem' as TypeEnum,
        ExternalInfrastructureServer: 'ExternalInfrastructureServer' as TypeEnum,
        NasFiler: 'NasFiler' as TypeEnum
    };
}