/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OwnerCredentials } from './ownerCredentials';

export interface CompanySiteResource { 
    /**
     * UID assigned to a site.
     */
    siteUid: string;
    /**
     * Tenant type in Veeam Cloud Connect.
     */
    cloudTenantType?: CompanySiteResource.CloudTenantTypeEnum;
    /**
     * UID assigned to a company.
     */
    readonly companyUid?: string;
    /**
     * UID assigned to a company tenant.
     */
    readonly cloudTenantUid?: string;
    /**
     * UID assigned to a VCD organization.
     */
    vCloudOrganizationUid?: string;
    /**
     * The last time when a tenant was active.
     */
    lastActive?: Date;
    /**
     * Indicates whether a company account must be disabled automatically.
     */
    leaseExpirationEnabled?: boolean;
    /**
     * Date and time when a company account must be disabled.
     */
    leaseExpirationDate?: Date;
    /**
     * Company owner credentials or username of a tenant account configured for a vCD Organization.
     */
    ownerCredentials: OwnerCredentials;
    /**
     * Company description.
     */
    description?: string;
    /**
     * Indicates whether incoming network traffic that will be accepted from a company is limited.
     */
    throttlingEnabled?: boolean;
    /**
     * Maximum incoming network traffic bandwidth that will be accepted from a company.
     */
    throttlingValue?: number;
    /**
     * Measurement units for incoming network traffic accepted from a company.
     */
    throttlingUnit?: CompanySiteResource.ThrottlingUnitEnum;
    /**
     * Maximum number of concurrent tasks available to a company.
     */
    maxConcurrentTask?: number;
    /**
     * Indicates whether deleted backup file protection is enabled.
     */
    backupProtectionEnabled?: boolean;
    /**
     * Number of days during which deleted backup files must be kept in the recycle bin on the Veeam Cloud Connect server.
     */
    backupProtectionPeriodDays?: number;
    /**
     * Type of cloud gateway selection.
     */
    gatewaySelectionType?: CompanySiteResource.GatewaySelectionTypeEnum;
    /**
     * Collection of UIDs assigned to gateway pools that are allocated to a company. If the collection is empty, company will automatically use a standalone gateway.
     */
    gatewayPoolsUids?: Array<string>;
    /**
     * Indicates whether a company is allowed to fail over to a cloud gateway that is not added to a selected cloud gateway pool.
     */
    isGatewayFailoverEnabled?: boolean;
}
export namespace CompanySiteResource {
    export type CloudTenantTypeEnum = 'Unknown' | 'General' | 'VCD';
    export const CloudTenantTypeEnum = {
        Unknown: 'Unknown' as CloudTenantTypeEnum,
        General: 'General' as CloudTenantTypeEnum,
        VCD: 'VCD' as CloudTenantTypeEnum
    };
    export type ThrottlingUnitEnum = 'MbitPerSec' | 'KbytePerSec' | 'MbytePerSec';
    export const ThrottlingUnitEnum = {
        MbitPerSec: 'MbitPerSec' as ThrottlingUnitEnum,
        KbytePerSec: 'KbytePerSec' as ThrottlingUnitEnum,
        MbytePerSec: 'MbytePerSec' as ThrottlingUnitEnum
    };
    export type GatewaySelectionTypeEnum = 'Unknown' | 'StandaloneGateways' | 'GatewayPool';
    export const GatewaySelectionTypeEnum = {
        Unknown: 'Unknown' as GatewaySelectionTypeEnum,
        StandaloneGateways: 'StandaloneGateways' as GatewaySelectionTypeEnum,
        GatewayPool: 'GatewayPool' as GatewaySelectionTypeEnum
    };
}