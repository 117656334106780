import React from 'react';
import { observer } from 'mobx-react-lite';
import { TokenManagerErrorResponse, TokenManagerResponse } from '@veeam-vspc/core';
import { LoginUserIcon, LoginSsoForm, AlternativeButton, LoginSsoFormState } from '@veeam-vspc/components';

import { useAppServices } from 'views/layouts/AppLayout/hooks';
import { useLangs } from 'views/layouts/LangLayout/hooks';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor/BaseFactor';

export const LoginSso = observer(() => {
    const { tokenManagerService } = useAppServices();
    const langs = useLangs();
    const loginStore = useLoginStore();
    const goToLoginStartButton = (
        <AlternativeButton
            iconBefore={<LoginUserIcon />}
            disabled={loginStore.isLoading}
            onClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
        >
            {langs.USE_LOCAL_USERS}
        </AlternativeButton>
    );

    return (
        <BaseFactor alternativeButton={goToLoginStartButton}>
            <LoginSsoForm
                title={langs.PLEASE_LOG_IN}
                error={loginStore.error}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={({ login }: LoginSsoFormState) => {
                    loginStore.isLoading = true;

                    tokenManagerService.authorizationCode(login)
                        .then((resp: TokenManagerResponse) => loginStore.onFinishFactorHandler(resp))
                        .catch(({ errorDescription }: TokenManagerErrorResponse) => {
                            loginStore.isLoading = false;
                            loginStore.error = errorDescription || langs.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT;
                        });
                }}
                texts={{
                    placeholderLogin: langs.EMAIL,
                    actionLogIn: langs.LOG_IN,
                    errorRequired: langs.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: langs.THE_MAXIMUM_LENGTH_IS,
                }}
            />
        </BaseFactor>
    );
});
