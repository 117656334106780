/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CloudGateway { 
    /**
     * UID assigned to a cloud gateway.
     */
    readonly instanceUid?: string;
    /**
     * Name of a cloud gateway.
     */
    readonly name?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * UID assigned to a cloud gateway pool that includes the cloud gateway.
     */
    gatewayPoolUid?: string;
    /**
     * Internal port that is listening to external connections.
     */
    readonly port?: number;
    /**
     * Port for external connections.
     */
    readonly externalPort?: number;
    /**
     * IP address of a network interface card on a cloud gateway.
     */
    readonly externalIp?: string;
    /**
     * Indicates whether a cloud gateway service is outdated.
     */
    readonly isOutOfDate?: boolean;
    /**
     * UID assigned to a server that performs a role of a cloud gateway.
     */
    readonly hostUid?: string;
    /**
     * Indicates whether a cloud gateway is disabled.
     */
    readonly isDisabled?: boolean;
    /**
     * Status of a cloud gateway.
     */
    readonly status?: CloudGateway.StatusEnum;
}
export namespace CloudGateway {
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Warning' | 'Error';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum
    };
}