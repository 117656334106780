import React from 'react';
import type {
    ExternalFormApi,
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import {
    isValid,
    TextInputKit,
    CONTROL_SIZE,
    FieldLayout,
    PasswordInput,
    Textarea,
    TextInput,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/usePageWizardStore';

import { core } from 'core/core-module';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import type { CompanyUserModel } from '../../../CompanyUser/interfaces/company-user-model';
import { stepValidate } from './helpers/stepValidate';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { LoginInfoForm } from './components/LoginInfoForm';
import { SelectUserLink } from './components/SelectUserLink';
import { SelectUser } from './components/SelectUser';

export const LoginInfo: React.FC<WizardStepData<CompanyUserModel>> = (wizardStepData) => {
    const { validationState } = wizardStepData;
    const langs = useLangs();
    const { data, isEdit } = wizardStepData;
    const isCompanyOwner = data.userRole === PortalUserRoles.CompanyOwner;
    const isSubtenant = data.userRole === PortalUserRoles.Subtenant;
    const wizardContext = usePageWizardStore<CompanyUserModel>();
    const openPanel = () => wizardContext.openPanel(hide => <SelectUser {...wizardStepData} hidePanel={hide}/>);

    if (data.isVcdCompany && isCompanyOwner) {
        return (
            <LoginInfoForm {...wizardStepData}>
                <FieldLayout
                    label={`${langs.LOGIN}:`}
                    disabled={true}
                >
                    <TextInput
                        name='login'
                    />
                </FieldLayout>

                <FieldLayout
                    label={`${langs.NEW_PASSWORD}:`}
                    disabled={true}
                >
                    <PasswordInput
                        name='password'
                        autocomplete='new-password'
                    />
                </FieldLayout>

                <FieldLayout
                    label={`${langs.CONFIRM_PASSWORD}:`}
                    disabled={true}
                >
                    <PasswordInput
                        name='confirmpassword'
                        autocomplete='new-password'
                    />
                </FieldLayout>
            </LoginInfoForm>
        );
    }

    if (data.isVcdCompany && isSubtenant) {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (!isEdit && !data.vcdUserId) {
                openPanel();
            }
        }

        return (
            <LoginInfoForm {...wizardStepData}>
                <FieldLayout
                    label={`${langs.LOGIN}:`}
                    disabled={true}
                    suffix={!isEdit && <SelectUserLink openPanel={openPanel}/>}
                >
                    <TextInputKit
                        value={isEdit ? data.login : data.vcdUserName}
                        size={CONTROL_SIZE.l}
                    />
                </FieldLayout>
                <Textarea
                    name='description'
                    label={langs.DESCRIPTION}
                    rows={8}
                    size={CONTROL_SIZE.l}
                />
            </LoginInfoForm>
        );
    }

    return (
        <LoginInfoForm {...wizardStepData}>
            <FieldLayout label={`${langs.LOGIN}:`} disabled={isEdit}>
                <TextInput
                    name='login'
                />
            </FieldLayout>

            {data.selfEdit &&
            <FieldLayout label={`${langs.CURRENT_PASSWORD}:`}>
                <PasswordInput
                    name='oldPassword'
                    autocomplete='new-password'
                />
            </FieldLayout>
            }

            <FieldLayout label={isEdit ? `${langs.NEW_PASSWORD}:` : `${langs.PASSWORD}:`}>
                <PasswordInput
                    name='password'
                    autocomplete='new-password'
                />
            </FieldLayout>

            <FieldLayout label={`${langs.CONFIRM_PASSWORD}:`}>
                <PasswordInput
                    name='confirmpassword'
                    autocomplete='new-password'
                />
            </FieldLayout>

            {isSubtenant &&
            <Textarea
                name='description'
                label={langs.DESCRIPTION}
                rows={8}
                size={CONTROL_SIZE.m}
            />
            }
        </LoginInfoForm>
    );
};

export const getLoginInfoStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    validate: ({ data, isEdit, stepData }) => new Promise(async(resolve) => {
        let valid = isValid(data => stepValidate(data, isEdit), data);

        if (valid) {
            const item = {
                additionalId: data.companyID || null,
                id: isEdit ? data.iD : null,
                type: 2,
                name: data.login,
            };

            const { exist } = await core.transportService.request<{}, { success: true; exist: boolean; }>('Common/CheckItemExist',{ item });

            if (exist) {
                stepData.isLoginExist = true;

                valid = false;
            }
        }

        resolve(valid);
    }),
    render: data => <LoginInfo {...data} />,
});


