import React from 'react';
import { PortalSpinner } from '@veeam-vspc/components';

import { core } from 'core/core-module';

import { AppViewTypes } from '../../enums';
import { PageLayout } from '../../../PageLayout';
import { BeforeAppDialogs } from '../BeforeAppDialogs/BeforeAppDialogs';
import { ThemeLayout } from '../../../ThemeLayout';
import { RouterLayout } from '../../../RouterLayout';
import { LoginPage } from '../../../../pages/LoginPage';
import type { PageProps } from '../../../RouterLayout/interfaces';
import { useAppStore } from '../../hooks';

export interface AppContentProps {
    viewType: AppViewTypes;
    pageName?: string;
    pageProps?: PageProps;
}

export const AppContent = ({ viewType, pageName, pageProps }: AppContentProps) => {
    const appStore = useAppStore();

    switch (viewType) {
        case (AppViewTypes.Dialogs):
            return (
                <PageLayout>
                    <BeforeAppDialogs/>
                </PageLayout>
            );
        case (AppViewTypes.Loading):
            return (
                <ThemeLayout>
                    <PortalSpinner whiteBg={true} />
                </ThemeLayout>
            );
        case (AppViewTypes.App):
            // Open app
            // @TODO when full react app
            // return {/* @TODO React Router */};

            RCOP.getApplication().closeLoginAndRunApp(appStore.portalData);
            return null;
        case (AppViewTypes.AppPage):
            // Open definite page
            // Only for period of moving from Ext.js to React
            return <RouterLayout pageName={pageName} pageProps={pageProps} />;
        case (AppViewTypes.Login):
            // Open login
            return <LoginPage />;
        default:
            // Open error
            setTimeout(() => { // for async rendering error dialog
                core.tokenManagerService.showHttpError();
            }, 10);
            return null;
    }
};
