import { computed, observable } from 'mobx';
import { makeTwoDigit, Months } from '@veeam-vspc/core';

import { DayJobsHeatMap } from '../interfaces/day-jobs-heat-map';
import { Company } from '../interfaces/company';
import { calcPercents } from '../components/HeatMap/calcPercents';
import { DayType } from '../enums/day-type';

export class HeatMapDay {
    id: string;
    date: Date;
    outOfPeriod: boolean;
    @observable successJobsCount: number | undefined;
    @observable warningJobsCount: number | undefined;
    @observable failJobsCount: number | undefined;
    @observable companies: Company[];

    constructor(dayData: DayJobsHeatMap) {
        this.id = dayData.date;
        this.date = new Date(dayData.date);
        this.successJobsCount = dayData.successJobsCount;
        this.warningJobsCount = dayData.warningJobsCount;
        this.failJobsCount = dayData.failJobsCount;
        this.outOfPeriod = dayData.outOfPeriod;
        this.companies = dayData.companies;
    }

    @computed
    get dayType() {
        if (this.successJobsCount === 0 && this.warningJobsCount === 0 && this.failJobsCount === 0) {
            return DayType.DayWithoutJobs;
        } else if (this.outOfPeriod) {
            return DayType.OutOfPeriod;
        } else if (!this.outOfPeriod && (this.successJobsCount === undefined && this.warningJobsCount === undefined && this.failJobsCount === undefined)) {
            return DayType.NoData;
        } else {
            return DayType.DayWithJobs;
        }
    }

    @computed
    get jobsPercents() {
        return calcPercents([this.successJobsCount || 0, this.warningJobsCount || 0, this.failJobsCount || 0]);
    }

    @computed
    get dateForBackend() {
        return `${makeTwoDigit(this.date.getDate())}.${makeTwoDigit(this.date.getMonth() + 1)}.${this.date.getFullYear()}`;
    }

    @computed
    get displayDateForCell() {
        return this.date.getDate() === 1 ? `${this.date.getDate()} ${Months[this.date.getMonth()]}` : this.date.getDate().toString();
    }

    @computed
    get displayDateForTooltip() {
        return `${this.date.getDate()} ${Months[this.date.getMonth()]}, ${this.date.getFullYear()}`;
    }

    @computed
    get allJobsCount() {
        return this.warningJobsCount + this.successJobsCount + this.failJobsCount;
    }

    @computed
    get affectedCompanies() {
        return this.companies.filter(company => company.failJobsCount > 0 || company.warningJobsCount > 0);
    }
}
