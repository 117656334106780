import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TokenManagerErrorResponse } from '@veeam-vspc/core';
import { VerifyCodeForm, VerifyCodeFormState } from '@veeam-vspc/components';

import { useAppServices } from 'views/layouts/AppLayout/hooks';
import { useLangs } from 'views/layouts/LangLayout/hooks';

import { useLoginStore } from '../../hooks';
import { VerifyCodeFactor } from '../../interfaces';
import { BaseFactor } from '../BaseFactor/BaseFactor';

export interface VerifyCodeProps {
    totalAttempts?: number;
}

export const VerifyCode = observer(({ totalAttempts = 2 }: VerifyCodeProps) => {
    const { tokenManagerService } = useAppServices();
    const langs = useLangs();
    const loginStore = useLoginStore();
    const [disabled, setDisabled] = useState(false);
    const factor = loginStore.currentFactor as VerifyCodeFactor;
    const { mfaToken, login } = factor;

    return (
        <BaseFactor>
            <VerifyCodeForm
                title={langs.VERIFICATION}
                onReturnButtonClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
                error={loginStore.error}
                loading={loginStore.isLoading}
                disabled={disabled}
                onChange={() => loginStore.resetMessages()}
                logIn={({ code }: VerifyCodeFormState) => {
                    loginStore.isLoading = true;

                    tokenManagerService.mfa(mfaToken, code)
                        .then(resp => loginStore.onFinishFactorHandler({ ...resp, login }))
                        .catch(({ errorDescription }: TokenManagerErrorResponse) => {
                            loginStore.isLoading = false;

                            if (factor.attempts >= totalAttempts) {
                                setDisabled(true);
                                loginStore.error = langs.FAILED_TO_CONFIRM_THE_VERIFICATION;
                                return;
                            }

                            factor.attempts += 1;
                            loginStore.error = errorDescription || langs.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT;
                        });
                }}
                texts={{
                    placeholderMFACode: langs.CODE,
                    actionLogIn: langs.LOG_IN,
                    errorRequired: langs.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: langs.THE_MAXIMUM_LENGTH_IS,
                    warningMFA: langs.YOUR_ACCOUNT_USES_TWO_FACTOR_AUTHENTICATION,
                    returnButtonText: login,
                    matchErrorMessage: langs.VERIFICATION_CODE_FORMAT_INVALID,
                }}
            />
        </BaseFactor>
    );
});
