/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerLicense { 
    /**
     * UID assigned to a backup server.
     */
    readonly backupServerUid?: string;
    /**
     * Indicates whether license updates automatically.
     */
    autoUpdateEnabled: boolean;
    /**
     * License edition.
     */
    readonly edition?: string;
    /**
     * Monitoring status.
     */
    readonly monitoring?: boolean;
    /**
     * Product packages.
     */
    readonly packages?: Array<BackupServerLicense.PackagesEnum>;
    /**
     * Name of an organization to which a licensed server belongs.
     */
    readonly company?: string;
    /**
     * Email address of an organization to which a licensed server belongs.
     */
    readonly email?: string;
    /**
     * [Legacy] Name of a contact person in an organization to which the license is issued.
     */
    readonly contactPerson?: string;
    /**
     * License expiration date and time.
     */
    readonly expirationDate?: Date;
    /**
     * Support expiration date and time.
     */
    readonly supportExpirationDate?: Date;
    /**
     * License IDs.
     */
    readonly licenseIds?: Array<string>;
    /**
     * License IDs required to contact Veeam Support.
     */
    readonly supportIds?: Array<string>;
    /**
     * Type of licensed units.
     */
    readonly sectionTypes?: Array<BackupServerLicense.SectionTypesEnum>;
    /**
     * Current status of the license.
     */
    readonly status?: BackupServerLicense.StatusEnum;
    /**
     * Indicates whether Veeam Cloud Connect is included in a license.
     */
    readonly cloudConnect?: BackupServerLicense.CloudConnectEnum;
    /**
     * Number of licensed sockets.
     */
    readonly sockets?: number;
    /**
     * Number of used sockets.
     */
    readonly usedSockets?: number;
    /**
     * Available protected capacity for NAS backup.
     */
    readonly capacity?: number;
    /**
     * Consumed capacity for NAS backup.
     */
    readonly usedCapacity?: number;
    /**
     * Number of available license units.
     */
    readonly units?: number;
    /**
     * Number of used license units.
     */
    readonly usedUnits?: number;
    /**
     * Type of license units.
     */
    readonly unitType?: BackupServerLicense.UnitTypeEnum;
    /**
     * Type of the license.
     */
    readonly type?: BackupServerLicense.TypeEnum;
}
export namespace BackupServerLicense {
    export type PackagesEnum = 'Unknown' | 'None' | 'Starter' | 'Essentials' | 'Backup' | 'ONE' | 'Suite' | 'Orchestrator';
    export const PackagesEnum = {
        Unknown: 'Unknown' as PackagesEnum,
        None: 'None' as PackagesEnum,
        Starter: 'Starter' as PackagesEnum,
        Essentials: 'Essentials' as PackagesEnum,
        Backup: 'Backup' as PackagesEnum,
        ONE: 'ONE' as PackagesEnum,
        Suite: 'Suite' as PackagesEnum,
        Orchestrator: 'Orchestrator' as PackagesEnum
    };
    export type SectionTypesEnum = 'Unknown' | 'Socket' | 'Instance' | 'All';
    export const SectionTypesEnum = {
        Unknown: 'Unknown' as SectionTypesEnum,
        Socket: 'Socket' as SectionTypesEnum,
        Instance: 'Instance' as SectionTypesEnum,
        All: 'All' as SectionTypesEnum
    };
    export type StatusEnum = 'Unknown' | 'Valid' | 'Warning' | 'Error' | 'Expired';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Valid: 'Valid' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum,
        Expired: 'Expired' as StatusEnum
    };
    export type CloudConnectEnum = 'Unknown' | 'No' | 'Yes' | 'Enterprise';
    export const CloudConnectEnum = {
        Unknown: 'Unknown' as CloudConnectEnum,
        No: 'No' as CloudConnectEnum,
        Yes: 'Yes' as CloudConnectEnum,
        Enterprise: 'Enterprise' as CloudConnectEnum
    };
    export type UnitTypeEnum = 'Unknown' | 'Instances' | 'Points';
    export const UnitTypeEnum = {
        Unknown: 'Unknown' as UnitTypeEnum,
        Instances: 'Instances' as UnitTypeEnum,
        Points: 'Points' as UnitTypeEnum
    };
    export type TypeEnum = 'Unknown' | 'NotInstalled' | 'Community' | 'Rental' | 'Subscription' | 'Evaluation' | 'NFR' | 'Perpetual';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        NotInstalled: 'NotInstalled' as TypeEnum,
        Community: 'Community' as TypeEnum,
        Rental: 'Rental' as TypeEnum,
        Subscription: 'Subscription' as TypeEnum,
        Evaluation: 'Evaluation' as TypeEnum,
        NFR: 'NFR' as TypeEnum,
        Perpetual: 'Perpetual' as TypeEnum
    };
}