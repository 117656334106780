import React from 'react';
import type {
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';
import { UserRoles } from 'core/enums';
import { IdentityProviderRoleMappingRule } from 'swagger/identityProviderRoleMappingRule';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { getResellerDocUrl } from '../../../Base/helpers/getResellerDocUrl';
import { NoteBar } from 'components/layouts/NoteBar';


export const Role: React.FC<WizardStepData<SSORuleModel>> = ({ data, validationState, onDataChange }) => {
    const langs = useLangs();

    return (
        <Form
            value={data}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.ROLE}
                description={langs.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='model.role'
                        data={[
                            { value: UserRoles.ResellerAdministrator, text: langs.SERVICE_PROVIDER_ADMINISTRATOR },
                            { value: UserRoles.ResellerOperator, text: langs.SERVICE_PROVIDER_OPERATOR },
                            { value: UserRoles.ResellerUser, text: langs.SERVICE_PROVIDER_USER },
                            { value: UserRoles.ResellerInvoiceAuditor, text: langs.SERVICE_PROVIDER_INVOICE_AUDITOR },
                        ]}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        size={CONTROL_SIZE.m}
                        label={langs.ROLE}
                        inlineLabel
                    />
                    <NoteBar>
                        {data.model.role === IdentityProviderRoleMappingRule.RoleEnum.ResellerAdministrator &&
                            <p>{langs.SERVICE_PROVIDER_ADMINISTRATOR_HAS_ACCESS}</p>
                        }
                        {data.model.role === IdentityProviderRoleMappingRule.RoleEnum.ResellerOperator &&
                            <p>{langs.SERVICE_PROVIDER_OPERATOR_CAN_PERFORM}</p>
                        }
                        {data.model.role === IdentityProviderRoleMappingRule.RoleEnum.ResellerUser &&
                            <p>{langs.SERVICE_PROVIDER_USER_HAS_ACCESS}</p>
                        }
                        {data.model.role === IdentityProviderRoleMappingRule.RoleEnum.ResellerInvoiceAuditor &&
                            <p>{langs.SERVICE_PROVIDER_INVOICE_AUDITOR_HAS_ACCESS}</p>
                        }
                        <p>
                            {langs.CLICK} <a
                                href={getResellerDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {langs.HERE}
                            </a> {langs.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Role {...data} />,
});
