/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * [TBD] OAuth2 identity provider settings for mail server.
 */
export interface SmtpSettingsOAuth2CredentialClientSettings { 
    /**
     * [TBD] Type of OAuth2 service provider.
     */
    kind: SmtpSettingsOAuth2CredentialClientSettings.KindEnum;
    /**
     * [TBD] Client id.
     */
    clientId: string;
    /**
     * [TBD] Client secret.
     */
    clientSecret: string;
    /**
     * [TBD] Tenant Id for Azure OAuth2 service provider.
     */
    tenantId?: string;
}
export namespace SmtpSettingsOAuth2CredentialClientSettings {
    export type KindEnum = 'Google' | 'Azure';
    export const KindEnum = {
        Google: 'Google' as KindEnum,
        Azure: 'Azure' as KindEnum
    };
}