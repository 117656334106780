/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscoveryRuleCredentials } from './discoveryRuleCredentials';
import { WindowsDiscoveryRuleDeploymentSettings } from './windowsDiscoveryRuleDeploymentSettings';

export interface WindowsDiscoveryRule { 
    /**
     * UID assigned to a discovery rule.
     */
    readonly instanceUid?: string;
    /**
     * Discovery method.
     */
    readonly method?: WindowsDiscoveryRule.MethodEnum;
    /**
     * Indicates whether Veeam Service Provider Console must use master agent credentials to connect discovered computers.
     */
    useMasterManagementAgentCredentials?: boolean;
    /**
     * Credentials of an account with Local Administrator permissions on discovered computers.
     */
    accessAccount: DiscoveryRuleCredentials;
    /**
     * Settings configured for Veeam backup agent deployment.
     */
    deploymentSettings?: WindowsDiscoveryRuleDeploymentSettings;
}
export namespace WindowsDiscoveryRule {
    export type MethodEnum = 'Unknown' | 'NetworkBased' | 'AD' | 'Manual';
    export const MethodEnum = {
        Unknown: 'Unknown' as MethodEnum,
        NetworkBased: 'NetworkBased' as MethodEnum,
        AD: 'AD' as MethodEnum,
        Manual: 'Manual' as MethodEnum
    };
}