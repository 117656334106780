import React, { useEffect } from 'react';
import {
    LinkButton,
    Text,
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/usePageWizardStore';

import { ManagedCompaniesPanel } from './components/ManagedCompaniesPanel';
import { StepLayout } from 'components/layouts/StepLayout';
import { UserRoles } from 'core/enums';
import { SSORuleModel } from 'features/RolesAndUsers/wizards/Base/interfaces/sso-rule-model';
import { ManagedCompaniesMode } from '../../enums/managed-companies-mode';
import { RadioGroup } from 'components/controls/RadioGroup';
import { useLangs } from 'views/layouts/LangLayout/hooks';

const Companies = (wizardStepData: WizardStepData<SSORuleModel>) => {
    const langs = useLangs();
    const wizardContext = usePageWizardStore<SSORuleModel>();
    const { data, onDataChange, validationState } = wizardStepData;
    const companiesCount = data.model.managedCompaniesUids.length;

    const showPanel = () => {
        wizardContext.openPanel(hide => <ManagedCompaniesPanel {...wizardStepData} hidePanel={hide}/>);
    };

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.model.managedCompaniesUids.length === 0) {
                showPanel();
            }
        }
    });

    return (
        <StepLayout
            title={langs.COMPANIES}
            description={langs.SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER}
        >
            <RadioGroup
                value={data.selectAllCompanies}
                onChange={(value: ManagedCompaniesMode) => {
                    data.selectAllCompanies = value;

                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: langs.ALL_COMPANIES_INCLUDING_NEWLY_ADDED,
                        value: ManagedCompaniesMode.AllCompanies
                    },
                    {
                        render: () => {
                            return (
                                <>
                                    <Text>
                                        {langs.SELECTED_COMPANIES_ONLY} {companiesCount > 0 ? `(${companiesCount})` : ''}
                                    </Text>
                                    <LinkButton
                                        onClick={showPanel}
                                        disabled={data.selectAllCompanies === ManagedCompaniesMode.AllCompanies}
                                    >
                                        ({langs.CONFIGURE})
                                    </LinkButton>
                                </>
                            );
                        },
                        value: ManagedCompaniesMode.CustomSelection,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getCompaniesStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: wizardStepData => <Companies {...wizardStepData} />,
    isHidden: ({ data }) => {
        return data.model.role === UserRoles.ResellerAdministrator || data.model.role === UserRoles.ResellerInvoiceAuditor;
    },
    validate({ data }) {
        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.model.managedCompaniesUids.length === 0) {
            return false;
        }

        return true;
    },
    id: 'companies'
});


