import React from 'react';
import type { WizardStep, WizardStepData } from '@veeam-vspc/components';
import { WizardSummary } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import type { AdminUserModel } from '../../interfaces/admin-user-model';
import { PrincipalRoleType } from '../../enums/principal-role-type';
import { ManagedCompaniesMode } from '../../enums/managed-companies-mode';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const Summary = ({ data }: WizardStepData<AdminUserModel>) => {
    const langs = useLangs();

    return (
        <StepLayout
            title={langs.SUMMARY}
            description={langs.REVIEW_USER_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${langs.ACCOUNT}:`,
                        fields: [
                            {
                                label: `${langs.ACCOUNT}:`,
                                value: data.model.name,
                            },
                            {
                                label: `${langs.ACCOUNT_TYPE}:`,
                                value: data.model.type === 0 ? langs.USER : langs.GROUP,
                            },
                        ],
                    },
                    {
                        title: `${langs.ROLE}:`,
                        fields: [
                            {
                                label: `${langs.ROLE}:`,
                                value: data.model.roleType,
                                transform: (value: PrincipalRoleType) => {
                                    const roleMap = {
                                        [PrincipalRoleType.ReadonlyUser]: langs.PORTAL_READONLY_OPERATOR,
                                        [PrincipalRoleType.PortalOperator]: langs.PORTAL_OPERATOR,
                                        [PrincipalRoleType.PortalAdministrator]: langs.PORTAL_ADMINISTRATOR,
                                    };

                                    return roleMap[value];
                                },
                            },
                        ],
                    },
                    {
                        title: `${langs.COMPANIES}:`,
                        fields: [
                            {
                                label: `${langs.COMPANIES}:`,
                                value: data.selectAllCompanies === ManagedCompaniesMode.AllCompanies
                                || data.model.roleType === PrincipalRoleType.PortalAdministrator
                                    ? langs.ALL
                                    : data.selectedCompanies.map(company => company.name).join(', '),
                            },
                        ],
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    render: data => <Summary {...data} />,
});
