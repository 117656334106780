import React from 'react';
import { observer } from 'mobx-react-lite';
import { BaseFailedRequestResponse, BaseRequestResponse, BaseSuccessRequestResponse, BaseSuccessResponseData } from '@veeam-vspc/core';
import { ChangePasswordForm, ChangePasswordFormState } from '@veeam-vspc/components';

import { useAppServices } from 'views/layouts/AppLayout/hooks';
import { useLangs } from 'views/layouts/LangLayout/hooks';

import { useLoginStore } from '../../hooks';
import { ChangePasswordData, ChangePasswordFactor } from '../../interfaces';
import { BaseFactor } from '../BaseFactor/BaseFactor';

export const ChangePassword = observer(() => {
    const { transportService } = useAppServices();
    const langs = useLangs();
    const loginStore = useLoginStore();
    const { code } = loginStore.currentFactor as ChangePasswordFactor;

    return (
        <BaseFactor width={'491px'}>
            <ChangePasswordForm
                title={langs.PLEASE_ENTER_A_NEW_PASSWORD}
                error={loginStore.error}
                loading={loginStore.isLoading}
                logIn={({ newPassword }: ChangePasswordFormState) => {
                    loginStore.isLoading = true;

                    transportService.request<ChangePasswordData, BaseRequestResponse>(
                        '/User/CompleteResetPasswordRequest',
                        { newPassword, code },
                        null,
                        { noAuth: true, notShowDefaultError: true }
                    )
                        .then(() => loginStore.onFinishFactorHandler({
                            goToLoginStart: true,
                            info: langs.THE_NEW_PASSWORD_HAS_BEEN_SET,
                        }))
                        .catch((resp: BaseRequestResponse) => {
                            const failedResp = resp as BaseFailedRequestResponse;
                            const successResp = resp as BaseSuccessRequestResponse;

                            loginStore.isLoading = false;
                            loginStore.error = failedResp.error || (successResp.data as BaseSuccessResponseData)?.message;
                        });
                }}
                onChange={() => loginStore.resetMessages()}
                texts={{
                    labelNewPassword: langs.NEW_PASSWORD,
                    labelRepeatPassword: langs.CONFIRM_PASSWORD,
                    actionLogIn: langs.SAVE,
                    errorRequired: langs.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: langs.THE_MAXIMUM_LENGTH_IS,
                    errorMatchingPasswords: langs.PASSWORDS_DO_NOT_MATCH,
                }}
            />
        </BaseFactor>
    );
});
