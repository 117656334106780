import { useEffect, useState } from 'react';
import { TransportMethods, BlobFileData } from '@veeam-vspc/core';

import { useAppServices } from 'views/layouts/AppLayout/hooks';

import { UseImageLoaderData } from '../interfaces';

const initialRandom = Date.now();
const initialState = {
    image: '',
    random: null,
};

/**
 * @description Using for uploading images via get request, not just get static images
 */
export function useImageLoader(url: string, noAuth = true, customRandom = initialRandom): UseImageLoaderData {
    const { transportService } = useAppServices();
    const [imageLoaderState, setImageLoaderState] = useState(initialState);
    const { image, random } = imageLoaderState;
    const forceReload = (newRandom = Date.now()) => setImageLoaderState({ image, random: newRandom });

    useEffect(() => {
        if (random !== customRandom) {
            transportService.downloadFileAjax(url, null, { method: TransportMethods.Get }, { noAuth, noAutoDownload: true })
                .then((resp: BlobFileData) => {
                    const reader = new FileReader();

                    reader.readAsDataURL(resp.blob);
                    reader.onloadend = () => setImageLoaderState({ image: reader.result as string, random: customRandom });
                })
                .catch(err => err && console.error(err));
        }
    }, [transportService, url, random, customRandom, noAuth]);

    return { image, forceReload };
}
