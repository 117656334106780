export enum PortalUserRoles {
    Unknown = 0,
    PortalAdministrator = 1,
    LocationAdministrator = 2,
    LocationUser = 3,
    CompanyOwner = 4,
    CompanyInvoiceAuditor = 5,
    Subtenant = 6,
    PortalOperator = 7,
    ServiceProviderGlobalAdministrator = 8,
    ServiceProviderOperator = 9,
    ServiceProviderUser = 10,
    ServiceProviderInvoiceAuditor = 11,
    ServiceProviderAdministrator = 12,
    SiteAdministrator = 13,
    CompanyAdministrator = 14,
    VacReadonlyOperator = 15,
    LocalAdministrator = 20,
}
