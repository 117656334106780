import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import {
    Form,
    FormValidator,
    isValid,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    TextInput,
    WizardStep,
    WizardStepData
} from '@veeam-vspc/components';
import { RadioGroup } from 'components/controls/RadioGroup';
import { SSORuleModel } from 'features/RolesAndUsers/wizards/Base/interfaces/sso-rule-model';
import { LocationMode } from './location-mode';
import { UserRoles } from 'core/enums';
import { useExternalFormApi } from '@veeam-vspc/components/src/form/hooks/useExternalFormApi';
import { useLangs } from 'views/layouts/LangLayout/hooks';

const stepValidate = (data: SSORuleModel) => {
    const validator = new FormValidator(data);

    if (data.locationMode === LocationMode.useLocationClaim) {
        validator.validate('model.locationsMappingSourceClaimType').string()
            .required();
    }

    return validator.result();
};

const Locations = (wizardStepData: WizardStepData<SSORuleModel>) => {
    const langs = useLangs();
    const { data, onDataChange, validationState } = wizardStepData;
    const formApi = useExternalFormApi();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
            externalFormApi={formApi}
            key='managedLocations'
        >
            <StepLayout
                title={langs.LOCATIONS}
                description={langs.SPECIFY_LOCATIONS_THAT_WILL_BE_ACCESSIBLE}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <RadioGroup
                        value={data.locationMode}
                        onChange={(value: LocationMode) => {
                            formApi.setValue('locationMode', value);
                        }}
                        buttons={[
                            {
                                label: langs.USE_DEFAULT_LOCATION,
                                value: LocationMode.useDefaultLocation
                            },
                            {
                                label: langs.RECEIVE_LOCATIONS_LIST,
                                value: LocationMode.useLocationClaim,
                            },
                        ]}
                    />
                    <TextInput
                        name='model.locationsMappingSourceClaimType'
                        label={langs.SPECIFY_CLAIM}
                        disabled={data.locationMode === LocationMode.useDefaultLocation}
                        inlineLabel
                    />
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getLocationsStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: wizardStepData => <Locations {...wizardStepData} />,
    validate({ data }) {
        return isValid(stepValidate, data);
    },
    isHidden: ({ data }) => {
        if (data.model.role === UserRoles.CompanyLocationUser || data.model.role === UserRoles.CompanyLocationAdministrator) {
            return false;
        }

        return true;
    },
});
