/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerLicenseCounter { 
    /**
     * License type.
     */
    type: BackupServerLicenseCounter.TypeEnum;
    /**
     * License unit type.
     */
    readonly unitType: BackupServerLicenseCounter.UnitTypeEnum;
    /**
     * Number of units that were activated within the current calendar month.
     */
    newUnits: number;
    /**
     * Number of units that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    usedUnits: number;
    /**
     * Number of objects that were activated within the current calendar month.
     */
    newCount: number;
    /**
     * Number of objects that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    usedCount: number;
}
export namespace BackupServerLicenseCounter {
    export type TypeEnum = 'Unknown' | 'VBR_vSphere_VM' | 'VBR_HyperV_VM' | 'VBR_Nutanix_VM' | 'VBR_NAS_Backup' | 'VBR_Cloud_VM' | 'VBR_Application_Plugins' | 'VBR_Server_Agent' | 'VBR_Workstation_Agent';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        VBRVSphereVM: 'VBR_vSphere_VM' as TypeEnum,
        VBRHyperVVM: 'VBR_HyperV_VM' as TypeEnum,
        VBRNutanixVM: 'VBR_Nutanix_VM' as TypeEnum,
        VBRNASBackup: 'VBR_NAS_Backup' as TypeEnum,
        VBRCloudVM: 'VBR_Cloud_VM' as TypeEnum,
        VBRApplicationPlugins: 'VBR_Application_Plugins' as TypeEnum,
        VBRServerAgent: 'VBR_Server_Agent' as TypeEnum,
        VBRWorkstationAgent: 'VBR_Workstation_Agent' as TypeEnum
    };
    export type UnitTypeEnum = 'Unknown' | 'Instances' | 'Points';
    export const UnitTypeEnum = {
        Unknown: 'Unknown' as UnitTypeEnum,
        Instances: 'Instances' as UnitTypeEnum,
        Points: 'Points' as UnitTypeEnum
    };
}