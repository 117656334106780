/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupAgent { 
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly instanceUid?: string;
    /**
     * Name of a platform on which Veeam backup agent is deployed.
     */
    readonly agentPlatform?: BackupAgent.AgentPlatformEnum;
    /**
     * UID assigned to a management agent that is deployed along with Veeam backup agent.
     */
    readonly managementAgentUid?: string;
    /**
     * UID assigned to a site on which an organization that owns Veeam backup agents is registered.
     */
    siteUid?: string;
    /**
     * UID assigned to an organization to which Veeam backup agents belong.
     */
    readonly organizationUid?: string;
    /**
     * Name of a managed computer on which Veeam backup agent is deployed.
     */
    readonly name?: string;
    /**
     * Backup job operation mode.
     */
    readonly operationMode?: BackupAgent.OperationModeEnum;
    /**
     * Indicates the UI access mode for the Veeam backup agent.
     */
    guiMode: BackupAgent.GuiModeEnum;
    /**
     * Computer platform on which Veeam backup agent is deployed.
     */
    readonly platform?: BackupAgent.PlatformEnum;
    /**
     * Version of Veeam backup agent deployed on a managed computer.
     */
    readonly version?: string;
    /**
     * Date and time when Veeam backup agent was activated.
     */
    readonly activationTime?: Date;
    /**
     * Management mode of Veeam backup agent. > You can change management mode to `ManagedByConsole` or `UnManaged` using the PATCH endpoint. 
     */
    readonly managementMode?: BackupAgent.ManagementModeEnum;
    /**
     * Type of Veeam backup agent installation procedure.
     */
    readonly installationType?: BackupAgent.InstallationTypeEnum;
    /**
     * Number of all jobs.
     */
    readonly totalJobsCount?: number;
    /**
     * Number of running jobs.
     */
    readonly runningJobsCount?: number;
    /**
     * Number of successful jobs.
     */
    readonly successJobsCount?: number;
}
export namespace BackupAgent {
    export type AgentPlatformEnum = 'Unknown' | 'Windows' | 'Linux' | 'Mac';
    export const AgentPlatformEnum = {
        Unknown: 'Unknown' as AgentPlatformEnum,
        Windows: 'Windows' as AgentPlatformEnum,
        Linux: 'Linux' as AgentPlatformEnum,
        Mac: 'Mac' as AgentPlatformEnum
    };
    export type OperationModeEnum = 'Unknown' | 'UnLicensed' | 'Server' | 'Workstation';
    export const OperationModeEnum = {
        Unknown: 'Unknown' as OperationModeEnum,
        UnLicensed: 'UnLicensed' as OperationModeEnum,
        Server: 'Server' as OperationModeEnum,
        Workstation: 'Workstation' as OperationModeEnum
    };
    export type GuiModeEnum = 'Unknown' | 'ReadOnly' | 'Full';
    export const GuiModeEnum = {
        Unknown: 'Unknown' as GuiModeEnum,
        ReadOnly: 'ReadOnly' as GuiModeEnum,
        Full: 'Full' as GuiModeEnum
    };
    export type PlatformEnum = 'Unknown' | 'Physical' | 'Cloud' | 'Virtual';
    export const PlatformEnum = {
        Unknown: 'Unknown' as PlatformEnum,
        Physical: 'Physical' as PlatformEnum,
        Cloud: 'Cloud' as PlatformEnum,
        Virtual: 'Virtual' as PlatformEnum
    };
    export type ManagementModeEnum = 'Unknown' | 'UnManaged' | 'ManagedByVBR' | 'ManagedByConsole';
    export const ManagementModeEnum = {
        Unknown: 'Unknown' as ManagementModeEnum,
        UnManaged: 'UnManaged' as ManagementModeEnum,
        ManagedByVBR: 'ManagedByVBR' as ManagementModeEnum,
        ManagedByConsole: 'ManagedByConsole' as ManagementModeEnum
    };
    export type InstallationTypeEnum = 'Unknown' | 'Full' | 'Restricted' | 'Broken' | 'Installing' | 'Uninstalling';
    export const InstallationTypeEnum = {
        Unknown: 'Unknown' as InstallationTypeEnum,
        Full: 'Full' as InstallationTypeEnum,
        Restricted: 'Restricted' as InstallationTypeEnum,
        Broken: 'Broken' as InstallationTypeEnum,
        Installing: 'Installing' as InstallationTypeEnum,
        Uninstalling: 'Uninstalling' as InstallationTypeEnum
    };
}