/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsServerJobRetentionSettings { 
    /**
     * Retention policy type. > The `Days` type is available only for Veeam Agent for Microsoft Windows version 5.0 or later. For earlier versions the `RestorePoints` type is used.' 
     */
    retentionMode?: WindowsServerJobRetentionSettings.RetentionModeEnum;
    /**
     * Retention policy counter value.
     */
    retentionCount?: number;
}
export namespace WindowsServerJobRetentionSettings {
    export type RetentionModeEnum = 'Unknown' | 'Days' | 'RestorePoints';
    export const RetentionModeEnum = {
        Unknown: 'Unknown' as RetentionModeEnum,
        Days: 'Days' as RetentionModeEnum,
        RestorePoints: 'RestorePoints' as RetentionModeEnum
    };
}