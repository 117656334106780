/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Information about a computer on which a management agent is deployed.
 */
export interface ComputerInfo { 
    /**
     * UID assigned to a computer.
     */
    readonly uniqueUid?: string;
    /**
     * UUID in Win32_ComputerSystem WMI class.
     */
    readonly biosUuid?: string;
    /**
     * Name of a computer.
     */
    readonly hostName?: string;
    /**
     * FQDN of a computer.
     */
    readonly fqdn?: string;
    /**
     * Operating system installed on a computer.
     */
    readonly guestOs?: string;
    /**
     * Type of a computer operating system.
     */
    readonly guestOsType?: ComputerInfo.GuestOsTypeEnum;
    /**
     * Version of a computer operating system.
     */
    readonly guestOsVersion?: string;
    /**
     * SKU of a computer operating system.
     */
    readonly guestOsSku?: number;
    /**
     * Type of a computer platform.
     */
    readonly platformType?: ComputerInfo.PlatformTypeEnum;
    /**
     * Computer IP addresses.
     */
    readonly ipAddresses?: Array<string>;
    /**
     * Computer MAC addresses.
     */
    readonly macAddresses?: Array<string>;
    /**
     * Array of applications installed on a computer.
     */
    readonly applications?: Array<ComputerInfo.ApplicationsEnum>;
}
export namespace ComputerInfo {
    export type GuestOsTypeEnum = 'Unknown' | 'Workstation' | 'DomainController' | 'Server' | 'Linux' | 'Mac';
    export const GuestOsTypeEnum = {
        Unknown: 'Unknown' as GuestOsTypeEnum,
        Workstation: 'Workstation' as GuestOsTypeEnum,
        DomainController: 'DomainController' as GuestOsTypeEnum,
        Server: 'Server' as GuestOsTypeEnum,
        Linux: 'Linux' as GuestOsTypeEnum,
        Mac: 'Mac' as GuestOsTypeEnum
    };
    export type PlatformTypeEnum = 'Unknown' | 'Windows' | 'HyperV' | 'Linux' | 'Mac' | 'vSphere' | 'Azure' | 'Amazon' | 'Other';
    export const PlatformTypeEnum = {
        Unknown: 'Unknown' as PlatformTypeEnum,
        Windows: 'Windows' as PlatformTypeEnum,
        HyperV: 'HyperV' as PlatformTypeEnum,
        Linux: 'Linux' as PlatformTypeEnum,
        Mac: 'Mac' as PlatformTypeEnum,
        VSphere: 'vSphere' as PlatformTypeEnum,
        Azure: 'Azure' as PlatformTypeEnum,
        Amazon: 'Amazon' as PlatformTypeEnum,
        Other: 'Other' as PlatformTypeEnum
    };
    export type ApplicationsEnum = 'Unknown' | 'OtherApp' | 'MicrosoftExchangeServer' | 'MicrosoftSqlServer' | 'MicrosoftActiveDirectory' | 'MicrosoftSharePoint' | 'Oracle' | 'MySQL' | 'PostgreSQL' | 'MongoDB' | 'ApacheServer';
    export const ApplicationsEnum = {
        Unknown: 'Unknown' as ApplicationsEnum,
        OtherApp: 'OtherApp' as ApplicationsEnum,
        MicrosoftExchangeServer: 'MicrosoftExchangeServer' as ApplicationsEnum,
        MicrosoftSqlServer: 'MicrosoftSqlServer' as ApplicationsEnum,
        MicrosoftActiveDirectory: 'MicrosoftActiveDirectory' as ApplicationsEnum,
        MicrosoftSharePoint: 'MicrosoftSharePoint' as ApplicationsEnum,
        Oracle: 'Oracle' as ApplicationsEnum,
        MySQL: 'MySQL' as ApplicationsEnum,
        PostgreSQL: 'PostgreSQL' as ApplicationsEnum,
        MongoDB: 'MongoDB' as ApplicationsEnum,
        ApacheServer: 'ApacheServer' as ApplicationsEnum
    };
}