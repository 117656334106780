import React from 'react';
import { observer } from 'mobx-react-lite';
import type { BackendErrors, TokenManagerErrorResponse, TokenManagerResponse } from '@veeam-vspc/core';
import  { HttpResponseCodes } from '@veeam-vspc/core';
import type { LoginStartFormState } from '@veeam-vspc/components';
import { LoginKeyIcon, AlternativeButton, LoginStartForm, immediatelyValidateFlag } from '@veeam-vspc/components';

import { useAppServices } from 'views/layouts/AppLayout/hooks';
import { useLangs } from 'views/layouts/LangLayout/hooks';

import type { LoginStartFactor } from '../../interfaces';
import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor/BaseFactor';

export const LoginStart = observer(() => {
    const { tokenManagerService } = useAppServices();
    const langs = useLangs();
    const loginStore = useLoginStore();
    const { identityProviders } = loginStore.currentFactor as LoginStartFactor;
    const goToSsoButton = () => (
        <AlternativeButton
            iconBefore={<LoginKeyIcon />}
            disabled={loginStore.isLoading}
            // onClick={() => loginStore.onFinishFactorHandler({ goToSso: true })} // Uncomment for full SSO
            onClick={() => loginStore.onSsoClick()}
        >
            {langs.LOG_IN_WITH_SSO}
        </AlternativeButton>
    );

    return (
        <BaseFactor alternativeButton={(identityProviders && identityProviders.length > 0) && goToSsoButton()}>
            <LoginStartForm
                title={langs.PLEASE_LOG_IN}
                error={loginStore.error}
                warning={loginStore.warning}
                info={loginStore.info}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={({ login, password }: LoginStartFormState) => {
                    loginStore.isLoading = true;

                    tokenManagerService.login(login, password)
                        .then((resp: TokenManagerResponse) => loginStore.onFinishFactorHandler({ ...resp, login }))
                        .catch((err: TokenManagerErrorResponse | BackendErrors) => {
                            const { errors } = err as BackendErrors;
                            const throttlingError = errors?.[0]?.code === HttpResponseCodes.TooManyRequests
                                ? langs.CANNOT_COMPLETE_THE_LOGIN_OPERATION
                                : null;
                            const { error, errorDescription } = err as TokenManagerErrorResponse;
                            const isInvalidRequest = error === 'invalid_request';

                            loginStore.isLoading = false;
                            loginStore.error = isInvalidRequest
                                ? langs.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT
                                : errorDescription || throttlingError || langs.FAILED_TO_AUTHENTICATE_A_USER_ACCOUNT;
                        });
                }}
                texts={{
                    placeholderLogin: langs.USERNAME_TEXTFIELD_PLACEHOLDER,
                    placeholderPassword: langs.ENTER_PASSWORD,
                    actionLogIn: langs.LOG_IN,
                    actionForgotPassword: langs.FORGOT_PASSWORD,
                    errorRequired: langs.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: langs.THE_MAXIMUM_LENGTH_IS,
                    warningCapsLockOn: langs.CAPS_LOCK_IS_ON,
                }}
                onForgotPasswordClick={() => loginStore.onFinishFactorHandler({ goToResetPassword: true })}
                customLoginValidation={(value: string) => {
                    if (value.indexOf('@') !== -1) {
                        return `${langs.SPECIFIED_USERNAME_FORMAT_IS_NOT_SUPPORTED}${immediatelyValidateFlag}`;
                    }
                }}
            />
        </BaseFactor>
    );
});
