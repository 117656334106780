import React from 'react';
import type {
    FormErrors,
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';
import {
    CheckboxKit,
    Combobox,
    CONTROL_SIZE,
    FieldLayout,
    Form,
    FormLayout,
    FormValidator,
    isValid,
    NumberInput,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
    Text,
    TextInput,
} from '@veeam-vspc/components';
import { capitalize, formatStr } from '@veeam-vspc/core';

import { StepLayout } from 'components/layouts/StepLayout';
import type { CompanyUserModel } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/company-user-model';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { Unit } from '../../enums/unit';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { core } from 'core/core-module';

export const stepValidate = (data: CompanyUserModel): FormErrors<CompanyUserModel> => {
    const validator = new FormValidator(data);

    validator.validate('repositoryFriendlyName').string()
        .required();

    validator.validate('tenantRepositoryId').string()
        .required();

    if (!data.isUnlimited) {
        validator.validate('repositoryQuota').number()
            .min(1);
    }

    return validator.result();
};

export const Quota: React.FC<WizardStepData<CompanyUserModel>> = ({ data, onDataChange, validationState }) => {
    const langs = useLangs();
    const repoCapacity: number = data.repositories.find(repository => repository.id === data.tenantRepositoryId)?.repositoryQuota / 1024;

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.QUOTA}
                description={langs.SET_CLOUD_REPOSITORY_QUOTA_FOR_THE_USER}
            >
                <FormLayout inlineLabel>
                    <FieldLayout label={`${langs.CLOUD_REPOSITORY_NAME}:`}>
                        <TextInput name='repositoryFriendlyName' />
                    </FieldLayout>
                    <FieldLayout label={`${capitalize(langs.BACKUP_REPOSITORY, true)}:`}>
                        <Combobox
                            name='tenantRepositoryId'
                            data={data.repositories}
                            valueGetter={item => item.id}
                            textGetter={item => item.repositoryFriendlyName}
                            size={CONTROL_SIZE.m}
                        />
                    </FieldLayout>
                    {data.tenantRepositoryId && (
                        <FieldLayout label={Ext.String.format(langs.REPOSITORY_CAPACITY_SOME_GB, repoCapacity)}>
                            <Text/>
                        </FieldLayout>
                    )}
                    <FieldLayout label={`${langs.USER_QUOTA}:`}>
                        <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.m}>
                            <NumberInput
                                name='repositoryQuota'
                                disabled={data.isUnlimited}
                                minValue={1}

                            />
                            <Combobox
                                name='repositoryQuotaUnit'
                                data={[
                                    { value: Unit.GB, text: langs.GB },
                                    { value: Unit.TB, text: langs.TB },
                                ]}
                                valueGetter={item => item.value}
                                textGetter={item => item.text}
                                size={CONTROL_SIZE.xxs}
                                disabled={data.isUnlimited}
                            />
                            <CheckboxKit
                                checked={data.isUnlimited}
                                onChange={(isChecked) => {
                                    data.isUnlimited = isChecked;

                                    onDataChange(data);
                                }}
                            >
                                {langs.UNLIMITED}
                            </CheckboxKit>
                        </StackView>
                    </FieldLayout>
                </FormLayout>
            </StepLayout>
        </Form>
    );
};

export const getQuotaStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    isHidden: ({ data }) => data.userRole !== PortalUserRoles.Subtenant,
    validate: ({ data }) => {
        const valid = isValid(stepValidate, data);

        if (valid) {
            const unit = data.repositoryQuotaUnit === Unit.GB ? 1024 : 1024 * 1024;
            const repository = data.repositories.find(repository => repository.id === data.tenantRepositoryId);
            const repositoryQuota = repository.repositoryQuota;
            const repositoryQuotaInGb = Math.round(repositoryQuota / 1024);
            const selectedQuota = !data.isUnlimited && data.repositoryQuota * unit;
            const text = formatStr(
                RCOP.Lang.QUOTA_FOR_THIS,
                repositoryQuotaInGb,
            );

            if (selectedQuota > repositoryQuota) core.notificationService.info(RCOP.Lang.QUOTA, text);
        }

        return valid;
    },
    render: data => <Quota {...data} />,
});
