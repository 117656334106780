/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WorkloadLicenseUsage } from './workloadLicenseUsage';

export interface ProductLicenseUsage { 
    /**
     * License ID.
     */
    readonly licenseId?: string;
    /**
     * Product type.
     */
    readonly productType?: ProductLicenseUsage.ProductTypeEnum;
    /**
     * License edition.
     */
    readonly licenseEdition?: string;
    /**
     * Number of license points used by an organization.
     */
    readonly usedPoints?: number;
    /**
     * License usage for each workload type.
     */
    readonly workloadUsage?: Array<WorkloadLicenseUsage>;
}
export namespace ProductLicenseUsage {
    export type ProductTypeEnum = 'Unknown' | 'EnterpriseManager' | 'BackupAndReplication' | 'CloudConnect' | 'VSPC';
    export const ProductTypeEnum = {
        Unknown: 'Unknown' as ProductTypeEnum,
        EnterpriseManager: 'EnterpriseManager' as ProductTypeEnum,
        BackupAndReplication: 'BackupAndReplication' as ProductTypeEnum,
        CloudConnect: 'CloudConnect' as ProductTypeEnum,
        VSPC: 'VSPC' as ProductTypeEnum
    };
}