/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsBackupStorage { 
    /**
     * Compression level for the backup.
     */
    compressionLevel?: WindowsBackupStorage.CompressionLevelEnum;
    /**
     * Type of a backup target.
     */
    storageOptimization?: WindowsBackupStorage.StorageOptimizationEnum;
    /**
     * Indicates whether encryption is enabled. > Encryption cannot be enabled for backup files stored on the Veeam backup repository. 
     */
    encryptionEnabled?: boolean;
    /**
     * Password used for encryption.  > Required if encryption is enabled. 
     */
    password?: string;
    /**
     * Hint for the password. > Must not consist of the password itself. 
     */
    passwordHint?: string;
}
export namespace WindowsBackupStorage {
    export type CompressionLevelEnum = 'Unknown' | 'NoCompression' | 'Dedupe' | 'Optimal' | 'High' | 'Extreme';
    export const CompressionLevelEnum = {
        Unknown: 'Unknown' as CompressionLevelEnum,
        NoCompression: 'NoCompression' as CompressionLevelEnum,
        Dedupe: 'Dedupe' as CompressionLevelEnum,
        Optimal: 'Optimal' as CompressionLevelEnum,
        High: 'High' as CompressionLevelEnum,
        Extreme: 'Extreme' as CompressionLevelEnum
    };
    export type StorageOptimizationEnum = 'Unknown' | 'Local16TbPlusBackup' | 'Local' | 'Lan' | 'Wan';
    export const StorageOptimizationEnum = {
        Unknown: 'Unknown' as StorageOptimizationEnum,
        Local16TbPlusBackup: 'Local16TbPlusBackup' as StorageOptimizationEnum,
        Local: 'Local' as StorageOptimizationEnum,
        Lan: 'Lan' as StorageOptimizationEnum,
        Wan: 'Wan' as StorageOptimizationEnum
    };
}