/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsDailyScheduleSettings } from './windowsDailyScheduleSettings';

export interface WindowsWorkstationJobPeriodicalScheduleSettings { 
    /**
     * Scheduling settings required for a daily running job.
     */
    dailyScheduleSettings?: WindowsDailyScheduleSettings;
    /**
     * Action that Veeam Agent for Microsoft Windows must perform in case your computer is powered off at the time when the scheduled backup job must start.
     */
    shutdownAction?: WindowsWorkstationJobPeriodicalScheduleSettings.ShutdownActionEnum;
    /**
     * Finalizing action that must be performed after the backup job completes successfully.
     */
    finalizingAction?: WindowsWorkstationJobPeriodicalScheduleSettings.FinalizingActionEnum;
}
export namespace WindowsWorkstationJobPeriodicalScheduleSettings {
    export type ShutdownActionEnum = 'SkipBackup' | 'BackupOncePoweredOn';
    export const ShutdownActionEnum = {
        SkipBackup: 'SkipBackup' as ShutdownActionEnum,
        BackupOncePoweredOn: 'BackupOncePoweredOn' as ShutdownActionEnum
    };
    export type FinalizingActionEnum = 'KeepRunning' | 'Sleep' | 'Shutdown' | 'Hibernate';
    export const FinalizingActionEnum = {
        KeepRunning: 'KeepRunning' as FinalizingActionEnum,
        Sleep: 'Sleep' as FinalizingActionEnum,
        Shutdown: 'Shutdown' as FinalizingActionEnum,
        Hibernate: 'Hibernate' as FinalizingActionEnum
    };
}