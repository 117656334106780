/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrgContainer { 
    /**
     * UID assigned to an organization.
     */
    readonly instanceUid?: string;
    /**
     * Name of an organization.
     */
    name: string;
    /**
     * [TBD] Type of container
     */
    readonly type?: OrgContainer.TypeEnum;
    /**
     * [TBD] Child Organization Uids
     */
    childrenOrganizations?: Array<string>;
    /**
     * [TBD] Child Container Uids
     */
    childrenContainers?: Array<string>;
}
export namespace OrgContainer {
    export type TypeEnum = 'Unknown' | 'DefaultCompanies' | 'DefaultResellers' | 'Custom';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        DefaultCompanies: 'DefaultCompanies' as TypeEnum,
        DefaultResellers: 'DefaultResellers' as TypeEnum,
        Custom: 'Custom' as TypeEnum
    };
}