import React from 'react';

import type { AppStorage } from '@veeam-vspc/core';

import type { PortalData } from 'core/entries';

import { AppContext } from '../../stores';
import type { AppContextData, AppServices } from '../../interfaces';
import { useAppInit } from '../../hooks';
import type { PageProps } from '../../../RouterLayout/interfaces';
import { AppContent } from '../AppContent/AppContent';

interface AppLayoutProps extends AppServices {
    appStorage: AppStorage;
    pageName?: string;
    portalData?: PortalData;
    pageProps?: PageProps;
}

export const AppLayout = ({ appStorage, pageName, portalData, pageProps = {}, ...restProps }: AppLayoutProps) => {
    const { viewType, appStore, appActions } = useAppInit(restProps, { pageName, portalData });
    const appContextData: AppContextData = {
        data: appStore,
        services: restProps,
        actions: appActions,
        appStorage, // @TODO Remove when finish switching to full React App
    };

    return (
        <AppContext.Provider value={appContextData}>
            <AppContent viewType={viewType} pageName={pageName} pageProps={pageProps} />
        </AppContext.Provider>
    );
};
