import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import {
    Combobox,
    Form,
    FormValidator,
    isValid,
    WizardStep,
    WizardStepData
} from '@veeam-vspc/components';
import { IdentityProviderModel } from './identity-provider-model';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const stepValidate = (data: IdentityProviderModel) => {
    const validator = new FormValidator(data);

    validator.validate('identityProviderName').string().required();

    return validator.result();
};

export const IdentityProvider = ({ data, validationState, onDataChange, isEdit }: WizardStepData<IdentityProviderModel>) => {
    const langs = useLangs();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.IDENTITY_PROVIDER}
                description={langs.SELECT_THE_IDENTITY_PROVIDER_TO_USE}
            >
                <Combobox
                    name='identityProviderName'
                    data={data.providers}
                    valueGetter={(item) => item.value}
                    textGetter={item => item.text}
                    disabled={isEdit}
                    label={langs.PROVIDER}
                    inlineLabel
                />
            </StepLayout>
        </Form>
    );
};

export const getIdentityProviderStep = (title: string): WizardStep<IdentityProviderModel> => ({
    title,
    validate: ({ data }) => {
        return isValid(stepValidate, data);
    },
    isHidden: ({ data, isEdit }) => {
        return !isEdit && data.providers.length === 1;
    },
    render: (data) => <IdentityProvider {...data}/>,
    id: 'identityProvider',
});
