import * as React from 'react';
import { Text } from '@veeam-vspc/components';
import { NoteBarStyled } from './NoteBarStyled';

export const NoteBar: React.FC = props => (
    <NoteBarStyled>
        <Text>
            {props.children}
        </Text>
    </NoteBarStyled>
);
