/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BackupJobOperationMode } from './backupJobOperationMode';
import { BackupPolicyAccessMode } from './backupPolicyAccessMode';

export interface BackupPolicy { 
    /**
     * UID assigned to a backup policy.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to an organization to whose agents a backup policy is assigned.
     */
    readonly organizationUid?: string;
    /**
     * Backup policy name. Pattern is '^[^$()%]+$' for Windows policy and '^[^~\"#%&*:<>?/\\\\{|}.'`]+$' for Linux and Mac policies.
     */
    name: string;
    /**
     * Backup policy description.
     */
    description?: string;
    /**
     * System ID assigned to a backup policy configuration.
     */
    readonly configId?: string;
    operationMode: BackupJobOperationMode;
    /**
     * Backup job operation mode. > Property is deprecated. We recommend to use the `operationMode` property. 
     */
    readonly mode?: BackupPolicy.ModeEnum;
    /**
     * Backup policy type.
     */
    readonly type?: BackupPolicy.TypeEnum;
    accessMode: BackupPolicyAccessMode;
    /**
     * Type of guest OS on a managed computer.
     */
    readonly systemType?: BackupPolicy.SystemTypeEnum;
    /**
     * Name of a company, reseller or service provider that created a backup policy.
     */
    readonly createdBy?: string;
    /**
     * Date and time when settings of a backup policy were last modified.
     */
    readonly modifiedDate?: Date;
    /**
     * Number of companies to whose Veeam backup agents a policy is assigned.
     */
    readonly companiesCount?: number;
}
export namespace BackupPolicy {
    export type ModeEnum = 'Unknown' | 'Workstation' | 'Server';
    export const ModeEnum = {
        Unknown: 'Unknown' as ModeEnum,
        Workstation: 'Workstation' as ModeEnum,
        Server: 'Server' as ModeEnum
    };
    export type TypeEnum = 'Unknown' | 'Predefined' | 'Provider' | 'Reseller' | 'Client';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        Predefined: 'Predefined' as TypeEnum,
        Provider: 'Provider' as TypeEnum,
        Reseller: 'Reseller' as TypeEnum,
        Client: 'Client' as TypeEnum
    };
    export type SystemTypeEnum = 'Unknown' | 'Windows' | 'Linux' | 'Mac';
    export const SystemTypeEnum = {
        Unknown: 'Unknown' as SystemTypeEnum,
        Windows: 'Windows' as SystemTypeEnum,
        Linux: 'Linux' as SystemTypeEnum,
        Mac: 'Mac' as SystemTypeEnum
    };
}