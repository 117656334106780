import React from 'react';
import type { WizardStepData } from '@veeam-vspc/components';
import { Grid, Search, Toolbar, TOOLBAR_ITEM_TYPE } from '@veeam-vspc/components';

import type { CompanyUserModel } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/company-user-model';
import { TenantType } from 'features/RolesAndUsers/wizards/CompanyUser/enums/tenant-type';
import type { BaseResponse } from 'interfaces/base-response';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppServices } from 'views/layouts/AppLayout/hooks';

export interface CompanyRow {
    id: number;
    name: string;
    siteName: string;
    tenantType: TenantType;
}

export interface CompanyFilterModel {
    name: string;
}

export const CompanyGrid = ({ data, onDataChange }: WizardStepData<CompanyUserModel>) => {
    const langs = useLangs();
    const { transportService } = useAppServices();

    return (
        <Grid
            columns={[
                { field: 'name', title: langs.COMPANY },
                { field: 'siteName', title: langs.SITE },
            ]}
            initialState={{
                selected: data.companyID ? [{ id: data.companyID } as CompanyRow] : undefined,
            }}
            data={requestParams => transportService.request<typeof requestParams, BaseResponse<CompanyRow>>('React/GetCompanies', requestParams)}
            toggleable={false}
            selection={{
                field: 'id',
                multiple: false,
                checkbox: false,
            }}
            onSelectionChange={(selected) => {
                data.companyID = selected[0]?.id;
                data.isVcdCompany = selected[0]?.tenantType === TenantType.vCloudDirector;

                onDataChange(data);
            }}
            paginationLimit={Number.MAX_SAFE_INTEGER}
            toolbars={[
                () => (
                    <Toolbar
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () => (
                                    <Search
                                        name='name'
                                        placeholder={langs.COMPANY_NAME}
                                        hasSearchButton={false}
                                    />
                                ),
                            },
                        ]}
                    />
                ),
            ]}
        />
    );
};
