import { PortalUserRoles } from 'core/entries/portal-user/enums';

export const MONTH_NAMES: string[] = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

export const ROLES = {
    [PortalUserRoles.CompanyOwner]: 'Company Owner',
    [PortalUserRoles.ServiceProviderGlobalAdministrator]: 'Service Provider Global Administrator',
    [PortalUserRoles.CompanyAdministrator]: 'Company Administrator',
    [PortalUserRoles.LocationAdministrator]: 'Location Administrator',
    [PortalUserRoles.LocationUser]: 'Location User',
    [PortalUserRoles.CompanyInvoiceAuditor]: 'Company Invoice Auditor',
    [PortalUserRoles.Subtenant]: 'Subtenant',
    [PortalUserRoles.ServiceProviderGlobalAdministrator]: 'Service Provider Global Administrator',
    [PortalUserRoles.ServiceProviderAdministrator]: 'Service Provider Administrator',
    [PortalUserRoles.ServiceProviderOperator]: 'Service Provider Operator',
    [PortalUserRoles.ServiceProviderUser]: 'Service Provider User',
    [PortalUserRoles.ServiceProviderInvoiceAuditor]: 'Service Provider Invoice Auditor',
};
