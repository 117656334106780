/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Company { 
    /**
     * UID assigned to a company.
     */
    readonly instanceUid?: string;
    /**
     * Name of a company.
     */
    readonly name?: string;
    /**
     * Status of a service provider. You can set the `Active` or `Disabled` value using the PATCH method.
     */
    status?: Company.StatusEnum;
    /**
     * UID assigned to a reseller that manages the company.
     */
    resellerUid?: string;
    /**
     * UID assigned to a company subscription plan.
     */
    subscriptionPlanUid?: string;
    /**
     * Array of the Veeam Service Provider Console components that a company can access.
     */
    readonly permissions?: Array<Company.PermissionsEnum>;
    /**
     * Indicates whether a company must receive notifications about alarms that were triggered for this company.
     */
    isAlarmDetectEnabled?: boolean;
}
export namespace Company {
    export type StatusEnum = 'Unknown' | 'Active' | 'Disabled' | 'Expired' | 'Creating' | 'Deleting' | 'Deleted' | 'SiteResourceCreationFailed' | 'SiteResourceCreating' | 'SiteResourceUpdating' | 'SiteResourceUpdateFailed';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Active: 'Active' as StatusEnum,
        Disabled: 'Disabled' as StatusEnum,
        Expired: 'Expired' as StatusEnum,
        Creating: 'Creating' as StatusEnum,
        Deleting: 'Deleting' as StatusEnum,
        Deleted: 'Deleted' as StatusEnum,
        SiteResourceCreationFailed: 'SiteResourceCreationFailed' as StatusEnum,
        SiteResourceCreating: 'SiteResourceCreating' as StatusEnum,
        SiteResourceUpdating: 'SiteResourceUpdating' as StatusEnum,
        SiteResourceUpdateFailed: 'SiteResourceUpdateFailed' as StatusEnum
    };
    export type PermissionsEnum = 'Unknown' | 'REST';
    export const PermissionsEnum = {
        Unknown: 'Unknown' as PermissionsEnum,
        REST: 'REST' as PermissionsEnum
    };
}