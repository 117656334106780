import React, { useRef, useState } from 'react';
import type { GridStore } from '@veeam-vspc/components';
import { ACTION_VIEW, Grid } from '@veeam-vspc/components';
import type { RequestParams } from '@veeam-vspc/components/src/Grid/interfaces/request-params';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import type { Location } from '../../../../interfaces/location';
import type { BaseResponse } from 'interfaces/base-response';
import type { LocationRequest } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/location-request';
import type { LocationsPanelProps } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/locations-panel-props';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppServices } from 'views/layouts/AppLayout/hooks';

export const LocationsPanel = ({ hidePanel, data, onDataChange }: LocationsPanelProps) => {
    const langs = useLangs();
    const { transportService } = useAppServices();
    const api = useRef<GridStore<Location, any, {}>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.locations?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={langs.LOCATIONS}
            description={langs.SELECT_LOCATIONS_ACCESSIBLE_FOR_THIS_USER}
            actions={[
                {
                    text: langs.APPLY,
                    onClick: () => {
                        data.locations = api.current.selected;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: langs.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <Grid
                initialState={{
                    selected: data.locations,
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: langs.LOCATION },
                    { field: 'admins', title: langs.ADMINS, cell: ({ cellData = langs.DASH_SYMBOL }) => <>{cellData}</> },
                    { field: 'users', title: langs.USERS, cell: ({ cellData = langs.DASH_SYMBOL }) => <>{cellData}</> },
                ]}
                data={(requestParams: RequestParams<{}>) => transportService
                    .request<LocationRequest, BaseResponse<Location>>('React/GetLocationData', {
                        ...requestParams,
                        companyId: data.companyID,
                    })}
                toggleable={false}
                selection={{
                    field: 'iD',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={Number.MAX_SAFE_INTEGER}
                api={api}
            />
        </SidePanelForm>
    );
};
