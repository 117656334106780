import React from 'react';

import { ThemeLayout } from '../../../ThemeLayout';
import { LangLayout } from '../../../LangLayout';
import { NotificationLayout } from '../../../NotificationLayout';
import { AppStorageLayout } from '../../../AppStorageLayout';
import { useAppContext } from '../../../AppLayout/hooks';

export interface PageLayoutProps {
    children: React.ReactElement;
}

export const PageLayout = ({ children }: PageLayoutProps) => {
    const { appStorage } = useAppContext();

    return (
        <ThemeLayout>
            <LangLayout>
                <NotificationLayout>
                    <AppStorageLayout appStorage={appStorage}>
                        {children}
                    </AppStorageLayout>
                </NotificationLayout>
            </LangLayout>
        </ThemeLayout>
    );
};
