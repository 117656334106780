/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerBackupCopyJob { 
    /**
     * UID assigned to a job.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a target repository.
     */
    readonly targetRepositoryUid?: string;
    /**
     * UID assigned to a target WAN accelerator.
     */
    readonly targetWanAcceleratorUid?: string;
    /**
     * UID assigned to a source WAN accelerator.
     */
    readonly sourceWanAcceleratorUid?: string;
    /**
     * Type of a retention policy of a backup copy job.
     */
    readonly retentionPolicyType?: BackupServerBackupCopyJob.RetentionPolicyTypeEnum;
    /**
     * Indicates whether a warning is enabled in case backup copy job fails to complete within the specified RPO interval.
     */
    readonly isRpoOptionsEnabled?: boolean;
    /**
     * Desired RPO interval value.
     */
    readonly rpoOptionsValue?: number;
    /**
     * Measurement units of a desired RPO interval value.
     */
    readonly rpoOptionsUnit?: BackupServerBackupCopyJob.RpoOptionsUnitEnum;
}
export namespace BackupServerBackupCopyJob {
    export type RetentionPolicyTypeEnum = 'Unknown' | 'None' | 'Simple' | 'GFS';
    export const RetentionPolicyTypeEnum = {
        Unknown: 'Unknown' as RetentionPolicyTypeEnum,
        None: 'None' as RetentionPolicyTypeEnum,
        Simple: 'Simple' as RetentionPolicyTypeEnum,
        GFS: 'GFS' as RetentionPolicyTypeEnum
    };
    export type RpoOptionsUnitEnum = 'Minutes' | 'Hours' | 'Days' | 'Months' | 'Years';
    export const RpoOptionsUnitEnum = {
        Minutes: 'Minutes' as RpoOptionsUnitEnum,
        Hours: 'Hours' as RpoOptionsUnitEnum,
        Days: 'Days' as RpoOptionsUnitEnum,
        Months: 'Months' as RpoOptionsUnitEnum,
        Years: 'Years' as RpoOptionsUnitEnum
    };
}