/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxBackupServerSettings } from './linuxBackupServerSettings';
import { LinuxSharedFolderTarget } from './linuxSharedFolderTarget';

export interface LinuxBackupTarget { 
    /**
     * Target location for the created backup.
     */
    targetType: LinuxBackupTarget.TargetTypeEnum;
    /**
     * Path to the folder where backup files must be stored. > Required for the `LocalFolder` target location. 
     */
    localPath?: string;
    /**
     * Shared folder settings. > Required for the `SharedFolder` target location. 
     */
    sharedFolder?: LinuxSharedFolderTarget;
    /**
     * Veeam Backup & Replication repository settings. > Required for the `BackupRepository` target location.' 
     */
    backupRepository?: LinuxBackupServerSettings;
    /**
     * Defines whether the deleted backup files must be removed after a specific time period.
     */
    enableDeletedFilesRetention?: boolean;
    /**
     * Number of days for which the deleted backup files are stored.
     */
    removeDeletedItemsDataAfter?: number;
}
export namespace LinuxBackupTarget {
    export type TargetTypeEnum = 'Unknown' | 'LocalFolder' | 'SharedFolder' | 'BackupRepository' | 'CloudRepository';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        LocalFolder: 'LocalFolder' as TargetTypeEnum,
        SharedFolder: 'SharedFolder' as TargetTypeEnum,
        BackupRepository: 'BackupRepository' as TargetTypeEnum,
        CloudRepository: 'CloudRepository' as TargetTypeEnum
    };
}