/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsComputerLevelBackupSource } from './windowsComputerLevelBackupSource';
import { WindowsFileLevelBackupSource } from './windowsFileLevelBackupSource';
import { WindowsVolumeLevelBackupSource } from './windowsVolumeLevelBackupSource';

export interface WindowsBackupSource { 
    /**
     * Backup mode.
     */
    backupMode: WindowsBackupSource.BackupModeEnum;
    /**
     * Settings for entire computer backup.
     */
    computerLevelOptions?: WindowsComputerLevelBackupSource;
    /**
     * Settings required for the `Volume` backup mode.
     */
    volumeLevelOptions?: WindowsVolumeLevelBackupSource;
    /**
     * Settings required for the `FilesFolders` backup mode.
     */
    fileLevelOptions?: WindowsFileLevelBackupSource;
}
export namespace WindowsBackupSource {
    export type BackupModeEnum = 'Unknown' | 'EntireComputer' | 'Volume' | 'FilesFolders';
    export const BackupModeEnum = {
        Unknown: 'Unknown' as BackupModeEnum,
        EntireComputer: 'EntireComputer' as BackupModeEnum,
        Volume: 'Volume' as BackupModeEnum,
        FilesFolders: 'FilesFolders' as BackupModeEnum
    };
}