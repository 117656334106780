/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * [TBD] Claim match rule.
 */
export interface IdentityProviderClaimMatchRule { 
    /**
     * [TBD]
     */
    claimType: string;
    /**
     * [TBD]
     */
    operator: IdentityProviderClaimMatchRule.OperatorEnum;
    /**
     * [TBD]
     */
    value: string;
    /**
     * [TBD] Not work with Regex.
     */
    matchCase: boolean;
}
export namespace IdentityProviderClaimMatchRule {
    export type OperatorEnum = 'Unknown' | 'Equals' | 'NotEquals' | 'Contains' | 'NotContains' | 'MatchRegex';
    export const OperatorEnum = {
        Unknown: 'Unknown' as OperatorEnum,
        Equals: 'Equals' as OperatorEnum,
        NotEquals: 'NotEquals' as OperatorEnum,
        Contains: 'Contains' as OperatorEnum,
        NotContains: 'NotContains' as OperatorEnum,
        MatchRegex: 'MatchRegex' as OperatorEnum
    };
}