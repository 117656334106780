/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerAgentJobObject { 
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly agentUid?: string;
    /**
     * UID assigned to a job.
     */
    readonly jobUid?: string;
    /**
     * Computer name of a Veeam backup agent.
     */
    readonly computer?: string;
    /**
     * Status of a job.
     */
    readonly backupStatus?: BackupServerAgentJobObject.BackupStatusEnum;
    /**
     * Date and time when the latest job session started.
     */
    readonly lastRun?: Date;
    /**
     * Date and time when the latest job session ended.
     */
    readonly lastEndTime?: Date;
    /**
     * Duration of the latest job session, in seconds.
     */
    readonly lastDuration?: number;
    /**
     * Number of restore points available in the backup chain.
     */
    readonly restorePointsCount?: number;
    /**
     * Type of a protected computer operating system.
     */
    readonly osType?: BackupServerAgentJobObject.OsTypeEnum;
    /**
     * Message that is displayed in case a backup job fails. > Every line break is represented by the `\\r\\n` control characters. 
     */
    readonly failureMessage?: string;
}
export namespace BackupServerAgentJobObject {
    export type BackupStatusEnum = 'Unknown' | 'None' | 'Success' | 'Warning' | 'Failed' | 'Running';
    export const BackupStatusEnum = {
        Unknown: 'Unknown' as BackupStatusEnum,
        None: 'None' as BackupStatusEnum,
        Success: 'Success' as BackupStatusEnum,
        Warning: 'Warning' as BackupStatusEnum,
        Failed: 'Failed' as BackupStatusEnum,
        Running: 'Running' as BackupStatusEnum
    };
    export type OsTypeEnum = 'Unknown' | 'Windows' | 'Linux' | 'Mac';
    export const OsTypeEnum = {
        Unknown: 'Unknown' as OsTypeEnum,
        Windows: 'Windows' as OsTypeEnum,
        Linux: 'Linux' as OsTypeEnum,
        Mac: 'Mac' as OsTypeEnum
    };
}