import React from 'react';

import { useAppActions, useAppStore } from 'views/layouts/AppLayout/hooks';

import { LoginContext, LoginStore } from '../../stores';
import { LoginBackground } from '../LoginBackground/LoginBackground';
import { LoginFactorsContainer } from '../LoginFactorsContainer/LoginFactorsContainer';

export const Login = () => {
    const { identityProviders, message: error } = useAppStore();
    const { loadApp, loadLogin } = useAppActions();

    return (
        <LoginContext.Provider value={new LoginStore({ loadApp, loadLogin, identityProviders, error })}>
            <LoginBackground>
                <LoginFactorsContainer/>
            </LoginBackground>
        </LoginContext.Provider>
    );
};
