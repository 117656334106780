/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxBaseCredentials } from './linuxBaseCredentials';

export interface LinuxMySqlApplicationAwareProcessingSettings { 
    /**
     * Transaction log processing mode.
     */
    processingType?: LinuxMySqlApplicationAwareProcessingSettings.ProcessingTypeEnum;
    /**
     * Credentials of a user account that Veeam Agent for Linux must use to connect to the MySQL database.
     */
    credentials?: LinuxBaseCredentials;
    /**
     * Type of credentials format.
     */
    authType?: LinuxMySqlApplicationAwareProcessingSettings.AuthTypeEnum;
    /**
     * Path to the password file.
     */
    passwordFilePath?: string;
}
export namespace LinuxMySqlApplicationAwareProcessingSettings {
    export type ProcessingTypeEnum = 'DisableProcess' | 'TryProcess' | 'RequireSuccess';
    export const ProcessingTypeEnum = {
        DisableProcess: 'DisableProcess' as ProcessingTypeEnum,
        TryProcess: 'TryProcess' as ProcessingTypeEnum,
        RequireSuccess: 'RequireSuccess' as ProcessingTypeEnum
    };
    export type AuthTypeEnum = 'MySQLPassword' | 'MySQLPasswordFile';
    export const AuthTypeEnum = {
        MySQLPassword: 'MySQLPassword' as AuthTypeEnum,
        MySQLPasswordFile: 'MySQLPasswordFile' as AuthTypeEnum
    };
}