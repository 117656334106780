/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConsoleLicenseSummaryCounter } from './consoleLicenseSummaryCounter';

export interface ConsoleLicenseUsage { 
    /**
     * License unit type.
     */
    readonly unitType?: ConsoleLicenseUsage.UnitTypeEnum;
    /**
     * Number of units that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    readonly usedUnits?: number;
    /**
     * Number of objects that have licenses assigned and are fully managed by Veeam Service Provider Console.
     */
    readonly usedCount?: number;
    /**
     * Number of units that were activated within the current calendar month.
     */
    readonly newUnits?: number;
    /**
     * Number of objects that were activated within the current calendar month.
     */
    readonly newCount?: number;
    /**
     * Total number of available license units.
     */
    readonly licensedUnits?: number;
    /**
     * Counters for each type of licensed objects.
     */
    readonly counters?: Array<ConsoleLicenseSummaryCounter>;
}
export namespace ConsoleLicenseUsage {
    export type UnitTypeEnum = 'Unknown' | 'Instances' | 'Points';
    export const UnitTypeEnum = {
        Unknown: 'Unknown' as UnitTypeEnum,
        Instances: 'Instances' as UnitTypeEnum,
        Points: 'Points' as UnitTypeEnum
    };
}