/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxDiscoveryCredentials } from './linuxDiscoveryCredentials';
import { LinuxDiscoveryRuleDeploymentSettings } from './linuxDiscoveryRuleDeploymentSettings';

export interface LinuxDiscoveryRule { 
    /**
     * UID assigned to a discovery rule.
     */
    readonly instanceUid?: string;
    /**
     * Discovery method.
     */
    readonly method?: LinuxDiscoveryRule.MethodEnum;
    /**
     * Credentials to connect discovered computers.
     */
    credentials: Array<LinuxDiscoveryCredentials>;
    /**
     * Settings configured for Veeam backup agent deployment.
     */
    deploymentSettings?: LinuxDiscoveryRuleDeploymentSettings;
}
export namespace LinuxDiscoveryRule {
    export type MethodEnum = 'Unknown' | 'NetworkBased' | 'Manual';
    export const MethodEnum = {
        Unknown: 'Unknown' as MethodEnum,
        NetworkBased: 'NetworkBased' as MethodEnum,
        Manual: 'Manual' as MethodEnum
    };
}