/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ConsoleLicense { 
    /**
     * License ID.
     */
    readonly licenseId?: string;
    /**
     * License edition.
     */
    readonly edition?: string;
    /**
     * Product packages.
     */
    readonly _package?: ConsoleLicense.PackageEnum;
    /**
     * Name of an organization to which a license is issued.
     */
    readonly licenseeCompany?: string;
    /**
     * Email address of a contact person in an organization.
     */
    readonly licenseeEmail?: string;
    /**
     * Email address of a license administrator in a company.
     */
    readonly licenseeAdministratorEmail?: string;
    /**
     * [Legacy] Name of a contact person in an organization to which the license is issued.
     */
    readonly contactPerson?: string;
    /**
     * License expiration date and time.
     */
    readonly expirationDate?: Date;
    /**
     * Support expiration date and time.
     */
    readonly supportExpirationDate?: Date;
    /**
     * Support ID required for contacting Veeam Support.
     */
    readonly supportId?: string;
    /**
     * Current status of the license.
     */
    readonly status?: ConsoleLicense.StatusEnum;
    /**
     * Description of a license status.
     */
    readonly statusMessage?: string;
    /**
     * Indicates whether a license includes Veeam Cloud Connect.
     */
    readonly cloudConnect?: ConsoleLicense.CloudConnectEnum;
    /**
     * Total number of available instances.
     */
    readonly instances?: number;
    /**
     * Type of a license.
     */
    readonly type?: ConsoleLicense.TypeEnum;
    /**
     * Date and time when license was last updated.
     */
    readonly lastUpdateDate?: Date;
    /**
     * Message to the last license update.
     */
    readonly lastUpdateMessage?: string;
    /**
     * Status of the last license update.
     */
    readonly lastUpdateStatus?: ConsoleLicense.LastUpdateStatusEnum;
}
export namespace ConsoleLicense {
    export type PackageEnum = 'Unknown' | 'None' | 'Starter' | 'Essentials' | 'Backup' | 'ONE' | 'Suite' | 'Orchestrator';
    export const PackageEnum = {
        Unknown: 'Unknown' as PackageEnum,
        None: 'None' as PackageEnum,
        Starter: 'Starter' as PackageEnum,
        Essentials: 'Essentials' as PackageEnum,
        Backup: 'Backup' as PackageEnum,
        ONE: 'ONE' as PackageEnum,
        Suite: 'Suite' as PackageEnum,
        Orchestrator: 'Orchestrator' as PackageEnum
    };
    export type StatusEnum = 'Unknown' | 'Valid' | 'Warning' | 'Error' | 'Expired';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Valid: 'Valid' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum,
        Expired: 'Expired' as StatusEnum
    };
    export type CloudConnectEnum = 'Unknown' | 'No' | 'Yes' | 'Enterprise';
    export const CloudConnectEnum = {
        Unknown: 'Unknown' as CloudConnectEnum,
        No: 'No' as CloudConnectEnum,
        Yes: 'Yes' as CloudConnectEnum,
        Enterprise: 'Enterprise' as CloudConnectEnum
    };
    export type TypeEnum = 'Unknown' | 'NotInstalled' | 'Community' | 'Rental' | 'Subscription' | 'Evaluation' | 'NFR' | 'Perpetual';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        NotInstalled: 'NotInstalled' as TypeEnum,
        Community: 'Community' as TypeEnum,
        Rental: 'Rental' as TypeEnum,
        Subscription: 'Subscription' as TypeEnum,
        Evaluation: 'Evaluation' as TypeEnum,
        NFR: 'NFR' as TypeEnum,
        Perpetual: 'Perpetual' as TypeEnum
    };
    export type LastUpdateStatusEnum = 'Unknown' | 'Success' | 'Warning' | 'Error';
    export const LastUpdateStatusEnum = {
        Unknown: 'Unknown' as LastUpdateStatusEnum,
        Success: 'Success' as LastUpdateStatusEnum,
        Warning: 'Warning' as LastUpdateStatusEnum,
        Error: 'Error' as LastUpdateStatusEnum
    };
}