import React from 'react';
import type { WizardStep } from '@veeam-vspc/components';

import { PageWizard } from 'components/wizards/PageWizard';
import { getAccountStep } from './components/Account';
import { getDomainStep } from './components/Domain';
import { getRoleStep } from './components/Role';
import { getSummaryStep } from './components/Summary';
import type { AdminUserProps } from './interfaces/admin-user-props';
import { UserType } from './enums/user-type';
import type { AdminUserModel } from './interfaces/admin-user-model';
import type { PrincipalObject } from './interfaces/principal-object';
import { PrincipalAccessType } from './enums/principal-access-type';
import { PrincipalObjectType } from './enums/principal-object-type';
import { getCompaniesStep } from './components/Companies';
import type { ActiveDirectoryAccount } from './interfaces/active-directory-account';
import { PrincipalRoleType } from './enums/principal-role-type';
import { ManagedCompaniesMode } from './enums/managed-companies-mode';
import { saveUser } from 'features/RolesAndUsers/wizards/Base/helpers/saveUser';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const AdminUser = (props: AdminUserProps) => {
    const langs = useLangs();
    const defaultModel: ActiveDirectoryAccount = {
        roleType: PrincipalRoleType.PortalOperator,
        type: UserType.User,
    };
    const title = props.isEdit ? langs.EDIT_WINDOWS_USER : langs.NEW_WINDOWS_USER;

    return (
        <PageWizard
            title={title}
            deactivate={()=>{}}
            isEdit={props.isEdit}
            data={{
                model: props.isEdit ? props.model : defaultModel,
                userName: props.userName,
                password: props.password,
                selectedCompanies: props.selectedCompanies || [],
                selectAllCompanies: props.selectAllCompanies ? ManagedCompaniesMode.AllCompanies : ManagedCompaniesMode.CustomSelection,
            } as AdminUserModel}
            steps={[
                getDomainStep(langs.DOMAIN),
                getAccountStep(langs.ACCOUNT),
                getRoleStep(langs.ROLE),
                getCompaniesStep(langs.COMPANIES),
                getSummaryStep(langs.SUMMARY),
            ] as WizardStep<AdminUserModel>[]}
            onFinish={({ data }) => {
                const principalObject: PrincipalObject[] = [];

                if (data.selectAllCompanies === ManagedCompaniesMode.AllCompanies) {
                    principalObject.push({
                        accessType: PrincipalAccessType.FullAccess,
                        id: -1,
                        type: PrincipalObjectType.All,
                    });
                } else {
                    data.selectedCompanies.forEach(company => principalObject.push({
                        accessType: PrincipalAccessType.FullAccess,
                        id: company.iD,
                        type: PrincipalObjectType.Company,
                    }));
                }

                const requestParams: { principal: ActiveDirectoryAccount; } = {
                    principal: {
                        ...data.model,
                        enabled: props.isEdit ? data.model.enabled : true,
                        principalObject,
                    },
                };

                return saveUser({ props, requestParams, areAdminRoles: true });
            }}
        />
    );
};
