import React, { useEffect } from 'react';
import { LinkButton, Text, WizardStep, WizardStepData } from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/usePageWizardStore';

import { ManagedCompaniesPanel } from './components/ManagedCompaniesPanel';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { StepLayout } from 'components/layouts/StepLayout';
import { SPUserModel } from '../../interfaces/sp-user-model';
import { RadioGroup } from 'components/controls/RadioGroup';
import { ManagedCompaniesMode } from '../../enums/managed-companies-mode';
import { useLangs } from 'views/layouts/LangLayout/hooks';

const Companies = (wizardStepData: WizardStepData<SPUserModel>) => {
    const langs = useLangs();
    const wizardContext = usePageWizardStore<SPUserModel>();
    const { data, onDataChange, validationState } = wizardStepData;

    const showPanel = () => {
        wizardContext.openPanel(hide => <ManagedCompaniesPanel {...wizardStepData} hidePanel={hide}/>);
    };

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.companies.length === 0) {
                showPanel();
            }
        }
    });

    return (
        <StepLayout
            title={langs.COMPANIES}
            description={langs.SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER}
        >
            <RadioGroup
                value={data.selectAllCompanies}
                onChange={(value: ManagedCompaniesMode) => {
                    data.selectAllCompanies = value;

                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: langs.ALL_MANAGED_COMPANIES,
                        value: ManagedCompaniesMode.AllCompanies
                    },
                    {
                        render: () => {
                            return (
                                <>
                                    <Text>{langs.SELECTED_COMPANIES_ONLY} {data.companies.length > 0 ? `(${data.companies.length})` : ''}</Text>
                                    <LinkButton
                                        onClick={showPanel}
                                        disabled={data.selectAllCompanies === ManagedCompaniesMode.AllCompanies}
                                    >
                                        ({langs.CONFIGURE})
                                    </LinkButton>
                                </>
                            );
                        },
                        value: ManagedCompaniesMode.CustomSelection,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getCompaniesStep = (title: string): WizardStep<SPUserModel> => ({
    title,
    render: data => <Companies {...data} />,
    isHidden: ({ data }) => {
        return data.userRole === PortalUserRoles.ServiceProviderAdministrator ||
            data.userRole === PortalUserRoles.ServiceProviderInvoiceAuditor ||
            data.userRole === PortalUserRoles.ServiceProviderGlobalAdministrator;
    },
    validate({ data }) {
        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.companies.length === 0) {
            return false;
        }

        return true;
    },
    id: 'companies'
});
