import styled from 'styled-components';
import { PercentsStatus } from '../../../../interfaces/percents-status';
import { colors, constants } from '@veeam-vspc/components';
import { DayWrapper } from '../DayBase';
import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT } from 'features/HeatMap/components/HeatMap/HeatMap.styled';
import { NARROW_DATE_TITLE_HEIGHT, DATE_TITLE_HEIGHT } from 'features/HeatMap/components/Day/components/DayBase/DayBase.styled';

export const DayWithJobsWrapper = styled(DayWrapper)`
  &:hover {
    cursor: pointer;
  }
`;

export const StatusesWrapper = styled.div`
  height: calc(100% - ${DATE_TITLE_HEIGHT});
  position: relative;

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
      height: calc(100% - ${NARROW_DATE_TITLE_HEIGHT});
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 1;
  }

  &:hover::before {
      content: ''
  }
`;

const FONT_SIZE_XS = '11px';
const FONT_LINE_HEIGHT_XS = FONT_SIZE_XS;
const MIN_THRESHOLD_TO_SEE_PERCENTS = 20;

const Status = styled.div<PercentsStatus>`
  height: ${props => `${props.percents[0]}`};
  display: ${props => props.percents[0] ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  color: ${colors.G900};

  &::before {
    content: '${props => props.percents[1]}';
    font-size: ${FONT_SIZE_XS};
    line-height: ${FONT_LINE_HEIGHT_XS};
  }

    @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
      &::before {
        // if a real height of the status < 20% - hide visible real percents on narrow screen
        content: '${props => parseInt(props.percents[0]) > MIN_THRESHOLD_TO_SEE_PERCENTS && props.percents[1]}';
      }
    }
`;

export const FailStatus = styled(Status)`
  background-color: ${colors.R800};
`;

export const WarningStatus = styled(Status)`
  background-color: #f2c973;
`;

export const SuccessStatus = styled(Status)`
  background-color: ${colors.Grn400};
`;
