/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ResellerQuota } from './resellerQuota';

export interface Reseller { 
    /**
     * UID assigned to a reseller.
     */
    readonly instanceUid?: string;
    /**
     * ProPartner Portal ID assigned to a reseller.
     */
    proPartnerId?: string;
    /**
     * Name of a reseller.
     */
    readonly name?: string;
    /**
     * Reseller status.
     */
    status?: Reseller.StatusEnum;
    /**
     * Array of the Veeam Service Provider Console components that a reseller can access.
     */
    readonly permissions?: Array<Reseller.PermissionsEnum>;
    /**
     * Reseller quota.
     */
    quota: ResellerQuota;
}
export namespace Reseller {
    export type StatusEnum = 'Unknown' | 'Active' | 'Disabled';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Active: 'Active' as StatusEnum,
        Disabled: 'Disabled' as StatusEnum
    };
    export type PermissionsEnum = 'Unknown' | 'REST';
    export const PermissionsEnum = {
        Unknown: 'Unknown' as PermissionsEnum,
        REST: 'REST' as PermissionsEnum
    };
}