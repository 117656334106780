/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserProfile { 
    /**
     * User first name.
     */
    firstName?: string;
    /**
     * User last name.
     */
    lastName?: string;
    /**
     * User title.
     */
    title?: UserProfile.TitleEnum;
    /**
     * User email address.
     */
    email?: string;
    /**
     * Address of a user or user organization.
     */
    address?: string;
    /**
     * Telephone number of a user or user organization.
     */
    phone?: string;
}
export namespace UserProfile {
    export type TitleEnum = 'Unknown' | 'Mr' | 'Miss' | 'Mrs' | 'Ms';
    export const TitleEnum = {
        Unknown: 'Unknown' as TitleEnum,
        Mr: 'Mr' as TitleEnum,
        Miss: 'Miss' as TitleEnum,
        Mrs: 'Mrs' as TitleEnum,
        Ms: 'Ms' as TitleEnum
    };
}