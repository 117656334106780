/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Invoice { 
    /**
     * [TBD]
     */
    readonly name?: string;
    /**
     * [TBD]
     */
    readonly companyId?: number;
    /**
     * [TBD]
     */
    readonly statementUid?: string;
    /**
     * [TBD]
     */
    readonly amount?: number;
    /**
     * [TBD] Plan Uuid
     */
    readonly subscriptionPlanUid?: string;
    /**
     * [TBD]Date and time when the invoice was generated
     */
    readonly creationDate?: Date;
    /**
     * [TBD]Date and time when the invoice was paid
     */
    readonly paidDate?: Date;
    /**
     * [TBD] Date from
     */
    readonly fromDate?: Date;
    /**
     * [TBD] Date to
     */
    readonly toDate?: Date;
    /**
     * [TBD]
     */
    readonly status?: Invoice.StatusEnum;
}
export namespace Invoice {
    export type StatusEnum = 'Unknown' | 'Unpaid' | 'Paid' | 'Overdue' | 'NotInvoice';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Unpaid: 'Unpaid' as StatusEnum,
        Paid: 'Paid' as StatusEnum,
        Overdue: 'Overdue' as StatusEnum,
        NotInvoice: 'NotInvoice' as StatusEnum
    };
}