/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserLogin { 
    /**
     * System ID assigned to a user identity.
     */
    readonly id: number;
    /**
     * UID assigned to a user.
     */
    readonly userUid: string;
    /**
     * Username.
     */
    readonly userName?: string;
    /**
     * UID assigned to a user company.
     */
    readonly companyId?: string;
    /**
     * Name of a user company.
     */
    readonly companyName?: string;
    /**
     * Name of an identity provider that manages user identity.
     */
    readonly identityProviderName: string;
    /**
     * Description of a user identity.
     */
    description: string;
    /**
     * Provided identity.
     */
    readonly identifierInProvider: string;
    /**
     * Services that are available to the user identity.
     */
    readonly scopes?: Array<UserLogin.ScopesEnum>;
    /**
     * User identity status. > You can change status to `enabled` or `disabled` using the PATCH method. 
     */
    status: UserLogin.StatusEnum;
    /**
     * Parameters of a user identity.
     */
    readonly parameters?: string;
    /**
     * Date and time of identity creation.
     */
    readonly creationDate?: Date;
}
export namespace UserLogin {
    export type ScopesEnum = 'unknown' | 'integration' | 'rest' | 'ui';
    export const ScopesEnum = {
        Unknown: 'unknown' as ScopesEnum,
        Integration: 'integration' as ScopesEnum,
        Rest: 'rest' as ScopesEnum,
        Ui: 'ui' as ScopesEnum
    };
    export type StatusEnum = 'Unknown' | 'enabled' | 'disabled' | 'disabledDueToUser' | 'disabledDueToCompany' | 'disabledDueToSystem' | 'disabledDueSystemComponentsRestrictions';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Enabled: 'enabled' as StatusEnum,
        Disabled: 'disabled' as StatusEnum,
        DisabledDueToUser: 'disabledDueToUser' as StatusEnum,
        DisabledDueToCompany: 'disabledDueToCompany' as StatusEnum,
        DisabledDueToSystem: 'disabledDueToSystem' as StatusEnum,
        DisabledDueSystemComponentsRestrictions: 'disabledDueSystemComponentsRestrictions' as StatusEnum
    };
}