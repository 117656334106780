import { useEffect, useState } from 'react';

import { LangsService } from 'core/services';
import { LangsServiceBase } from 'core/services/langs/interfaces';

import { UseLangsServiceData } from '../interfaces';

const initialState = {
    hasLangs: false,
    langs: null,
    locale: null,
};

export const useLangsService = (langsService: LangsService<LangsServiceBase>, newLocale: string): UseLangsServiceData => {
    const [langsState, setLangsState] = useState(initialState);
    const { hasLangs, langs, locale } = langsState;

    useEffect(() => {
        if (locale !== newLocale) {
            langsService.loadLangsFor(newLocale)
                .then(() => setLangsState({ hasLangs: true, langs: langsService.getLangs(), locale: newLocale }))
                .catch((err) => {
                    console.error(err);
                    setLangsState({ hasLangs, langs, locale: newLocale });
                });
        }
    }, [locale, newLocale, langsService, hasLangs, langs]);

    return { langs, locale, hasLangs };
};
