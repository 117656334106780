import React, { useEffect } from 'react';
import {
    LinkButton,
    WizardStep,
    WizardStepData,
    Text,
} from '@veeam-vspc/components';
import { usePageWizardStore } from '@veeam-vspc/components/src/PageWizard/hooks/usePageWizardStore';

import { StepLayout } from 'components/layouts/StepLayout';
import { ManagedCompaniesPanel } from './components/ManagedCompaniesPanel';
import { AdminUserModel } from '../../interfaces/admin-user-model';
import { PrincipalRoleType } from '../../enums/principal-role-type';
import { RadioGroup } from 'components/controls/RadioGroup';
import { ManagedCompaniesMode } from '../../enums/managed-companies-mode';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { core } from 'core/core-module';


export const Companies = (wizardStepData: WizardStepData<AdminUserModel>) => {
    const langs = useLangs();
    const { data, onDataChange, validationState } = wizardStepData;
    const selectedCompaniesCount = data.selectedCompanies?.length ?? 0;
    const wizardContext = usePageWizardStore<AdminUserModel>();
    const showPanel = () => wizardContext.openPanel(hide => <ManagedCompaniesPanel {...wizardStepData} hidePanel={hide}/>);

    useEffect(() => {
        if (validationState.isForce()) {
            validationState.markAsHandledForce();

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.selectedCompanies.length === 0) {
                showPanel();
            }

            if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection &&
                data.selectedCompanies?.some(company => company.managedByReseller)
            ) {
                const companyNames = data.selectedCompanies
                    .filter(company => company.managedByReseller)
                    .map(company => company.name)
                    .join(', ');

                core.notificationService.error(langs.ERROR, [Ext.String.format(langs.CANNOT_CREATE_A_NEW_USER, companyNames)]);
            }
        }
    });

    return (
        <StepLayout
            title={langs.COMPANIES}
            description={langs.SELECT_COMPANIES_ACCESSIBLE_FOR_THIS_USER}
        >
            <RadioGroup
                value={data.selectAllCompanies}
                onChange={(value: ManagedCompaniesMode) => {
                    data.selectAllCompanies = value;

                    onDataChange(data);
                }}
                buttons={[
                    {
                        label: langs.ALL_COMPANIES_INCLUDING_NEWLY_ADDED,
                        value: ManagedCompaniesMode.AllCompanies
                    },
                    {
                        render: () => {
                            return (
                                <>
                                    <Text>
                                        {langs.SELECTED_COMPANIES_ONLY} {selectedCompaniesCount > 0 ? `(${selectedCompaniesCount})` : ''}
                                    </Text>
                                    <LinkButton
                                        onClick={showPanel}
                                        disabled={data.selectAllCompanies === ManagedCompaniesMode.AllCompanies}
                                    >
                                        ({langs.CONFIGURE_COMPANIES}...)
                                    </LinkButton>
                                </>
                            );
                        },
                        value: ManagedCompaniesMode.CustomSelection,
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getCompaniesStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    validate({ data }) {
        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.selectedCompanies.length === 0) {
            return false;
        }

        if (data.selectAllCompanies === ManagedCompaniesMode.CustomSelection && data.selectedCompanies?.some(company => company.managedByReseller)) {
            return false;
        }

        return true;
    },
    isHidden: ({ data }) => data.model.roleType === PrincipalRoleType.PortalAdministrator,
    id: 'companies',
    render: data => <Companies {...data} />,
});
