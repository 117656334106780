import React from 'react';
import { DateTitle } from '../DayBase';
import { useStore } from '../../../../store/useStore';
import { StatusesWrapper, FailStatus, WarningStatus, SuccessStatus, DayWithJobsWrapper } from './DayWithJobs.styled';
import { Tooltip } from '../../../Tooltip';
import { openJobsDialog } from '../../../../interfaces/open-jobs-dialog';

interface Props {
    id: string;
    openJobsDialog: openJobsDialog;
}

export const DayWithJobs = ({ id, openJobsDialog }: Props) => {
    const store = useStore();
    const record = store.findById(id);

    return (
        <>
            <DayWithJobsWrapper
                data-for={id}
                data-tip={id}
                onClick={() => openJobsDialog(id)}
            >
                <DateTitle>
                    {record.displayDateForCell}
                </DateTitle>
                <StatusesWrapper>
                    <FailStatus percents={record.jobsPercents.fail} />
                    <WarningStatus percents={record.jobsPercents.warning} />
                    <SuccessStatus percents={record.jobsPercents.success} />
                </StatusesWrapper>
            </DayWithJobsWrapper>
            <Tooltip id={id}/>
        </>
    );
};
