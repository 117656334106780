import { computed, observable } from 'mobx';

import type { IdentityProviderSettings } from 'swagger/models';
import { PortalData, PortalUser, PortalFeatures } from 'core/entries';
import { getProductTheme } from 'core/utils';

export class AppStore {

    @observable theme: string;
    @observable locale: string;

    @observable private portalNameValue: string; // Be aware! It has getter/setter

    version: string;

    appMinWidth = 960;
    appMinHeight = 800;

    message: string | null = null;
    identityProviders: IdentityProviderSettings[] | null;

    @observable portalUser: PortalUser;
    @observable portalFeatures: PortalFeatures;
    @observable portalData: PortalData;

    constructor(portalData: PortalData) {
        const {
            theme,
            locale,
            version,
            portalUser,
            portalName,
            message,
            identityProviders,
            portalFeatures,
        } = portalData;

        this.theme = getProductTheme(theme);
        this.locale = locale;
        this.version = version;
        this.portalName = portalName;

        this.message = message;
        this.identityProviders = identityProviders;

        this.portalUser = portalUser;
        this.portalFeatures = portalFeatures;
        this.portalData = portalData;
    }

    @computed get portalName(): string {
        return this.portalNameValue;
    }

    set portalName(value: string) {
        this.portalNameValue = value;
        this.setDocumentTitle(value);
    }

    private setDocumentTitle(title: string): void {
        document && (document.title = title);
    }

}
