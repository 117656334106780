/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface JsonPatch { 
    /**
     * Performed operation.
     */
    op: JsonPatch.OpEnum;
    /**
     * Value that is added, replaced, tested or removed by the PATCH operation.
     */
    value: string;
    /**
     * JSON Pointer containing path to a location from which data is moved or copied.
     */
    from?: string;
    /**
     * JSON Pointer containing path to a target location where the PATCH operation is performed.
     */
    path: string;
}
export namespace JsonPatch {
    export type OpEnum = 'add' | 'replace' | 'test' | 'remove' | 'move' | 'copy';
    export const OpEnum = {
        Add: 'add' as OpEnum,
        Replace: 'replace' as OpEnum,
        Test: 'test' as OpEnum,
        Remove: 'remove' as OpEnum,
        Move: 'move' as OpEnum,
        Copy: 'copy' as OpEnum
    };
}