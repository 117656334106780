import React from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeProvider, themes } from '@veeam-vspc/components';

import { useAppServices, useAppStore } from '../../../AppLayout/hooks';

export interface Props {
    children: React.ReactElement;
}

export const ThemeLayout = observer((props: Props) => {
    const appStore = useAppStore();
    const { notificationService } = useAppServices();

    notificationService.setTheme(appStore.theme);

    return (
        <ThemeProvider theme={themes[appStore.theme]}>
            {props.children}
        </ThemeProvider>
    );
});
