import React from 'react';
import { WeekTitle } from '../WeekTitle';
import { Days } from '../Days';
import { Legend } from '../Legend';
import { HeatMapWrapper } from './HeatMap.styled';
import { Context } from '../../store/Context';
import { DayJobsHeatMap } from '../../interfaces/day-jobs-heat-map';
import { openJobsDialog } from '../../interfaces/open-jobs-dialog';
import { Period } from '../../enums/period';
import { createStore } from '../../store/createStore';

interface Props {
    days: DayJobsHeatMap[];
    beginDate: Date;
    endTime: Date;
    selectedPeriod: Period;
    openJobsDialog: openJobsDialog;
}

export const HeatMap = (props: Props) => {
    const store = createStore(props);

    return (
        <Context.Provider value={store} >
            <HeatMapWrapper>
                <WeekTitle/>
                <Days openJobsDialog={props.openJobsDialog} />
                <Legend/>
            </HeatMapWrapper>
        </Context.Provider>
    );
};
