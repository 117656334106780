import React from 'react';
import { StepLayout } from 'components/layouts/StepLayout';
import type { WizardStep, WizardStepData } from '@veeam-vspc/components';
import { WizardSummary } from '@veeam-vspc/components';
import { UserRoles } from 'core/enums';
import type { SSORuleModel } from '../../../Base/interfaces/sso-rule-model';
import { LocationMode } from '../Locations/location-mode';
import { useLangs } from 'views/layouts/LangLayout/hooks';


export const Summary = ({ data }: WizardStepData<SSORuleModel>) => {
    const langs = useLangs();
    const roleMap = {
        [UserRoles.CompanyOwner]: langs.COMPANY_OWNER,
        [UserRoles.CompanyAdministrator]: langs.COMPANY_ADMINISTRATOR,
        [UserRoles.CompanyLocationAdministrator]: langs.LOCATION_ADMINISTRATOR,
        [UserRoles.CompanyLocationUser]: langs.LOCATION_USER,
        [UserRoles.CompanyInvoiceAuditor]: langs.COMPANY_INVOICE_AUDITOR,
        [UserRoles.ResellerOwner]: langs.SERVICE_PROVIDER_GLOBAL_ADMINISTRATOR,
    };

    return (
        <StepLayout
            title={langs.SUMMARY}
            description={langs.REVIEW_RULE_DETAILS}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: `${langs.RULE_INFO}:`,
                        fields: [
                            {
                                label: `${langs.NAME}:`,
                                value: data.model.name,
                            },
                        ],
                    },
                    {
                        title: `${langs.ROLE}:`,
                        fields: [
                            {
                                label: `${langs.ROLE}:`,
                                value: roleMap[data.model.role],
                            },
                        ],
                    },
                    {
                        title: `${langs.LOCATIONS}:`,
                        fields: [
                            {
                                label: data.locationMode === LocationMode.useLocationClaim ? `${langs.LOCATIONS_CLAIM}:` : `${langs.LOCATIONS}:`,
                                value: data.locationMode === LocationMode.useLocationClaim
                                    ? data.model.locationsMappingSourceClaimType
                                    : langs.DEFAULT_LOCATION,
                            },
                        ],
                    },
                    {
                        title: `${langs.CONDITIONS}:`,
                        fields: [
                            {
                                label: `${langs.NAME}:`,
                                value: data.model.organizationMappingSourceClaimType,
                            },
                            {
                                label: `${langs.PARAMETERS}:`,
                                value: data.model.additionalMappings?.length ?? 0,
                            },
                        ],
                    },
                ]}
            />
        </StepLayout>
    );
};

export const getSummaryStep = (title: string): WizardStep<SSORuleModel> => ({
    title,
    render: data => <Summary {...data} />,
    id: 'summary',
});
