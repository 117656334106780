import React from 'react';
import { LegendWrapper, FailedLabel, SuccessLabel, WarningLabel } from './Legend.styled';

export const Legend = () => {
    return (
        <LegendWrapper>
            <FailedLabel>Failed</FailedLabel>
            <WarningLabel>Warning</WarningLabel>
            <SuccessLabel>Success</SuccessLabel>
        </LegendWrapper>
    );
};
