/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedComputerManagedByConsole { 
    /**
     * UID assigned to a backup agent installed on a computer.
     */
    readonly backupAgentUid?: string;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid?: string;
    /**
     * Hostname of a protected computer.
     */
    readonly name?: string;
    /**
     * Number of jobs.
     */
    readonly numberOfJobs?: number;
    /**
     * Operation mode.
     */
    readonly operationMode?: ProtectedComputerManagedByConsole.OperationModeEnum;
    /**
     * Date of the latest restore point creation.
     */
    readonly latestRestorePointDate?: Date;
}
export namespace ProtectedComputerManagedByConsole {
    export type OperationModeEnum = 'Unknown' | 'Server' | 'Workstation';
    export const OperationModeEnum = {
        Unknown: 'Unknown' as OperationModeEnum,
        Server: 'Server' as OperationModeEnum,
        Workstation: 'Workstation' as OperationModeEnum
    };
}