/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OAuth2Error { 
    /**
     * Error type.
     */
    readonly error?: OAuth2Error.ErrorEnum;
    /**
     * Error description.
     */
    readonly errorDescription?: string;
    /**
     * Error URI.
     */
    readonly errorUri?: string;
}
export namespace OAuth2Error {
    export type ErrorEnum = 'invalid_request' | 'invalid_client' | 'invalid_grant' | 'unauthorized_client' | 'unsupported_grant_type' | 'invalid_scope';
    export const ErrorEnum = {
        InvalidRequest: 'invalid_request' as ErrorEnum,
        InvalidClient: 'invalid_client' as ErrorEnum,
        InvalidGrant: 'invalid_grant' as ErrorEnum,
        UnauthorizedClient: 'unauthorized_client' as ErrorEnum,
        UnsupportedGrantType: 'unsupported_grant_type' as ErrorEnum,
        InvalidScope: 'invalid_scope' as ErrorEnum
    };
}