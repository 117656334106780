import { LoginUser } from '../../../services/portal/interfaces';
import { PortalUserCreationSources, PortalUserRoles, PortalUserStatuses } from '../enums';
import { MfaPolicyStatuses } from '../../../services/portal/enums';

export class PortalUser {

    readonly iD: number;
    readonly instanceUid: string;
    readonly userRole: PortalUserRoles;
    readonly creationSource: PortalUserCreationSources;

    login: string;
    email: string | null;
    firstName: string;
    lastName: string;
    userStatus: PortalUserStatuses;
    mfaPolicyStatus: MfaPolicyStatuses;
    requireChangePassword: boolean;
    address: string | null;
    isVcdCompany: boolean;
    companyId: number;
    organizationUid: string;
    companyName: string | null;
    companyStatus: number; // TODO add companyStatus enum when add company service
    havePasswordLogin: boolean

    constructor(loginUser: LoginUser) {
        const {
            iD,
            instanceUid,
            userRole,
            creationSource,
            login,
            email,
            firstName,
            lastName,
            userStatus,
            mfaPolicyStatus,
            requireChangePassword,
            address,
            isVcdCompany,
            companyId,
            organizationUid,
            companyName,
            companyStatus,
            companyUid,
            havePasswordLogin,
        } = loginUser;

        this.iD = iD;
        this.instanceUid = instanceUid;
        this.userRole = userRole;
        this.creationSource = creationSource;
        this.login = login;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userStatus = userStatus;
        this.mfaPolicyStatus = mfaPolicyStatus;
        this.requireChangePassword = requireChangePassword;
        this.address = address;
        this.isVcdCompany = isVcdCompany;
        this.companyId = companyId;
        this.organizationUid = organizationUid;
        this.companyName = companyName;
        this.companyStatus = companyStatus;
        this.organizationUid = companyUid;
        this.havePasswordLogin = havePasswordLogin;
    }

}
