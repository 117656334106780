import { TransportService } from '@veeam-vspc/core';

export class LangsService<T extends {}> {

    protected locale: string;
    protected langs: T = {} as T;

    constructor(protected readonly transportService: TransportService) {}

    loadLangsFor(locale: string): Promise<void> {
        // @TODO make upload langs for current locale
        if (this.locale !== locale) {
            return Promise.resolve(RCOP?.Lang)
                .then((langs: T) => this.setLangs(langs, locale));
        }

        return Promise.resolve();
    }

    getLangs(): T {
        return this.langs;
    }

    getLocale(): string {
        return this.locale;
    }

    protected setLangs(langs: T, locale: string): void {
        this.locale = locale;
        Object.keys(langs).forEach((key: string) => this.langs[key] = langs[key]);
    }

}
