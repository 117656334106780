/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProtectedComputerManagedByBackupServerRestorePoint { 
    /**
     * UID assigned to a restore point.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly backupAgentUid?: string;
    /**
     * UID assigned to a backup chain.
     */
    readonly backupUid?: string;
    /**
     * UID assigned to a backup job that created the restore point.
     */
    readonly jobUid?: string;
    /**
     * UID assigned to a target repository
     */
    readonly repositoryUid?: string;
    /**
     * Size of the restore point, in bytes.
     */
    readonly size?: number;
    /**
     * Total size of protected computer disks, in bytes.
     */
    readonly provisionedSourceSize?: number;
    /**
     * Used space on protected computer disks, in bytes.
     */
    readonly usedSourceSize?: number;
    /**
     * Size of the backup increment, in bytes.
     */
    readonly incrementRawDataSize?: number;
    /**
     * Size of protected data, in bytes.
     */
    readonly sourceSize?: number;
    /**
     * Number of protected computer CPU cores.
     */
    readonly cpuCores?: number;
    /**
     * Protected computer memory, in bytes.
     */
    readonly memory?: number;
    /**
     * Type of a target repository.
     */
    readonly targetType?: ProtectedComputerManagedByBackupServerRestorePoint.TargetTypeEnum;
    /**
     * Time and date when backup was created.
     */
    readonly backupCreationTime?: Date;
    /**
     * Time and date when a restore point was created.
     */
    readonly fileCreationTime?: Date;
}
export namespace ProtectedComputerManagedByBackupServerRestorePoint {
    export type TargetTypeEnum = 'Unknown' | 'Local' | 'Cloud';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        Local: 'Local' as TargetTypeEnum,
        Cloud: 'Cloud' as TargetTypeEnum
    };
}