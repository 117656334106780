/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinuxBackupVolumeSourceSettings { 
    /**
     * Volume type.
     */
    volumeType: LinuxBackupVolumeSourceSettings.VolumeTypeEnum;
    /**
     * Path to a block device or mount point.
     */
    path: string;
}
export namespace LinuxBackupVolumeSourceSettings {
    export type VolumeTypeEnum = 'Device' | 'MountPoint' | 'LVM' | 'BTRFS';
    export const VolumeTypeEnum = {
        Device: 'Device' as VolumeTypeEnum,
        MountPoint: 'MountPoint' as VolumeTypeEnum,
        LVM: 'LVM' as VolumeTypeEnum,
        BTRFS: 'BTRFS' as VolumeTypeEnum
    };
}