/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupFailoverPlan { 
    /**
     * UID assigned to a failover plan.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a failover plan by Veeam Backup & Replication.
     */
    readonly originalUid?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server on which a failover plan is configured.
     */
    readonly backupServerUid?: string;
    /**
     * Name of a failover plan.
     */
    readonly name?: string;
    /**
     * Type of a failover plan.
     */
    readonly type?: BackupFailoverPlan.TypeEnum;
    /**
     * Status of a failover plan.
     */
    readonly status?: BackupFailoverPlan.StatusEnum;
    /**
     * UID assigned to a tenant for which a failover plan is configured.
     */
    readonly tenantUid?: string;
    /**
     * Number of objects in a job.
     */
    readonly objectsCount?: number;
    /**
     * Indicates whether a custom script must be executed before a failover plan.
     */
    preFailoverScriptEnabled?: boolean;
    /**
     * Path to a script file that is executed before a failover.
     */
    preFailoverCommand?: string;
    /**
     * Path to a script file that is executed after a failover.
     */
    postFailoverCommand?: string;
    /**
     * Indicates whether a custom script must be executed after a failover plan.
     */
    postFailoverScriptEnabled?: boolean;
}
export namespace BackupFailoverPlan {
    export type TypeEnum = 'Unknown' | 'Local' | 'Cloud' | 'Tenant';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        Local: 'Local' as TypeEnum,
        Cloud: 'Cloud' as TypeEnum,
        Tenant: 'Tenant' as TypeEnum
    };
    export type StatusEnum = 'Unknown' | 'Ready' | 'InProgress' | 'InUndoProgress' | 'Success' | 'Failed' | 'Completed' | 'UndoFailed';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Ready: 'Ready' as StatusEnum,
        InProgress: 'InProgress' as StatusEnum,
        InUndoProgress: 'InUndoProgress' as StatusEnum,
        Success: 'Success' as StatusEnum,
        Failed: 'Failed' as StatusEnum,
        Completed: 'Completed' as StatusEnum,
        UndoFailed: 'UndoFailed' as StatusEnum
    };
}