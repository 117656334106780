/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupAgentSettings { 
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly backupAgentUid?: string;
    /**
     * Indicates whether a Veeam backup agent job schedule is disabled.
     */
    disableScheduledBackups: boolean;
    /**
     * Indicates whether Control Panel notifications.
     */
    disableControlPanelNotification: boolean;
    /**
     * Indicates whether backup over metered connections is disabled.
     */
    disableBackupOverMeteredConnection: boolean;
    /**
     * Indicates whether a scheduled wake up timer is disabled.
     */
    disableScheduleWakeup: boolean;
    /**
     * Indicates whether Veeam backup agent throttles backup activities when system is busy.
     */
    throttleBackupActivity: boolean;
    /**
     * Indicates whether backup over VPN connections is disabled.
     */
    restrictVpnConnections: boolean;
    /**
     * Indicates whether bandwidth consumption for backup jobs is limited.
     */
    limitBandwidthConsumption?: boolean;
    /**
     * Value of maximum speed for transferring backed-up data.
     */
    bandwidthSpeedLimit?: number;
    /**
     * Measurement units of maximum speed for transferring backed-up data.
     */
    bandwidthSpeedLimitUnit?: BackupAgentSettings.BandwidthSpeedLimitUnitEnum;
    /**
     * Indicates whether file-level restore is available to users that do not have administrative privileges.
     */
    flrWithoutAdminPrivilegesAllowed: boolean;
}
export namespace BackupAgentSettings {
    export type BandwidthSpeedLimitUnitEnum = 'MbitPerSec' | 'KbytePerSec' | 'MbytePerSec';
    export const BandwidthSpeedLimitUnitEnum = {
        MbitPerSec: 'MbitPerSec' as BandwidthSpeedLimitUnitEnum,
        KbytePerSec: 'KbytePerSec' as BandwidthSpeedLimitUnitEnum,
        MbytePerSec: 'MbytePerSec' as BandwidthSpeedLimitUnitEnum
    };
}