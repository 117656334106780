/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerJobObjectLastSession { 
    /**
     * Status of the latest job session.
     */
    readonly backupStatus?: BackupServerJobObjectLastSession.BackupStatusEnum;
    /**
     * Size of backup files, in bytes.
     */
    readonly totalBackedSize?: number;
    /**
     * Size of processed data, in bytes.
     */
    readonly sourceSize?: number;
    /**
     * Time taken to complete the latest job session, in seconds.
     */
    readonly duration?: number;
    /**
     * Array of job session messages.
     */
    readonly messages?: Array<string>;
}
export namespace BackupServerJobObjectLastSession {
    export type BackupStatusEnum = 'Unknown' | 'Running' | 'Success' | 'Warning' | 'Failed';
    export const BackupStatusEnum = {
        Unknown: 'Unknown' as BackupStatusEnum,
        Running: 'Running' as BackupStatusEnum,
        Success: 'Success' as BackupStatusEnum,
        Warning: 'Warning' as BackupStatusEnum,
        Failed: 'Failed' as BackupStatusEnum
    };
}