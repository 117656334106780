import React from 'react';
import { PageLayout } from 'views/layouts/PageLayout';
import { WizardManager, WizardManagerProps } from 'views/pages/RolesAndUsersPage/components/WizardManager/WizardManager';


export const RolesAndUsersPage = (props: WizardManagerProps) => {

    return (
        <PageLayout>
            <WizardManager {...props}/>
        </PageLayout>
    );
};
