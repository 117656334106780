/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsBackupAgentJob { 
    /**
     * UID assigned to a Veeam Agent for Microsoft Windows job.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a Veeam Agent for Microsoft Windows.
     */
    readonly backupAgentUid?: string;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid?: string;
    /**
     * Name of a Veeam Agent for Microsoft Windows job.
     */
    readonly name?: string;
    /**
     * UID assigned to a backup job configuration.
     */
    readonly configUid?: string;
    /**
     * UID of a backup policy assigned to a Veeam Agent for Microsoft Windows.
     */
    readonly backupPolicyUid?: string;
    /**
     * Message that is displayed in case a backup policy job fails.
     */
    readonly backupPolicyFailureMessage?: string;
    /**
     * Status of the latest job session. > Can be changed to `Running` or `Stopping` using the PATCH endpoint. 
     */
    status: WindowsBackupAgentJob.StatusEnum;
    /**
     * Operation mode of a Veeam Agent for Microsoft Windows.
     */
    readonly operationMode?: WindowsBackupAgentJob.OperationModeEnum;
    /**
     * Location where backup files for a Veeam Agent for Microsoft Windows reside.
     */
    readonly destination?: string;
    /**
     * Number of restore points.
     */
    readonly restorePoints?: number;
    /**
     * Date and time when the latest job session started.
     */
    readonly lastRun?: Date;
    /**
     * Date and time when the latest job session finished.
     */
    readonly lastEndTime?: Date;
    /**
     * Duration of the latest backup job session, in seconds.
     */
    readonly lastDuration?: number;
    /**
     * Date and time of the next scheduled backup job session.
     */
    readonly nextRun?: Date;
    /**
     * Average duration of a backup job session, in seconds.
     */
    readonly avgDuration?: number;
    /**
     * Type of backup operation mode.
     */
    readonly backupMode?: WindowsBackupAgentJob.BackupModeEnum;
    /**
     * Type of a location where backup files for a Veeam Agent for Microsoft Windows reside.
     */
    readonly targetType?: WindowsBackupAgentJob.TargetTypeEnum;
    /**
     * Indicates whether a job schedule is enabled. > Can be changed using the PATCH endpoint. 
     */
    readonly isEnabled?: boolean;
    /**
     * Type of schedule configured for the job.
     */
    readonly scheduleType?: WindowsBackupAgentJob.ScheduleTypeEnum;
    /**
     * Events that trigger the backup job launch.
     */
    readonly scheduleEvents?: Array<WindowsBackupAgentJob.ScheduleEventsEnum>;
    /**
     * Date and time when settings of the backup job were last modified.
     */
    readonly lastModifiedDate?: Date;
    /**
     * Name of the user who last modified job settings.
     */
    readonly lastModifiedBy?: string;
    /**
     * Message that is displayed in case a backup job fails.
     */
    readonly failureMessage?: string;
    /**
     * Total size of all restore points, in bytes.
     */
    readonly backedUpSize?: number;
    /**
     * Amount of free space available on the target repository. > If the job has never been run, the property value is `null`. 
     */
    readonly freeSpace?: number;
}
export namespace WindowsBackupAgentJob {
    export type StatusEnum = 'Unknown' | 'None' | 'Success' | 'Warning' | 'Failed' | 'Starting' | 'Running' | 'Stopping';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        None: 'None' as StatusEnum,
        Success: 'Success' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Failed: 'Failed' as StatusEnum,
        Starting: 'Starting' as StatusEnum,
        Running: 'Running' as StatusEnum,
        Stopping: 'Stopping' as StatusEnum
    };
    export type OperationModeEnum = 'Unknown' | 'UnLicensed' | 'Server' | 'Workstation';
    export const OperationModeEnum = {
        Unknown: 'Unknown' as OperationModeEnum,
        UnLicensed: 'UnLicensed' as OperationModeEnum,
        Server: 'Server' as OperationModeEnum,
        Workstation: 'Workstation' as OperationModeEnum
    };
    export type BackupModeEnum = 'Unknown' | 'EntireComputer' | 'Volume' | 'File';
    export const BackupModeEnum = {
        Unknown: 'Unknown' as BackupModeEnum,
        EntireComputer: 'EntireComputer' as BackupModeEnum,
        Volume: 'Volume' as BackupModeEnum,
        File: 'File' as BackupModeEnum
    };
    export type TargetTypeEnum = 'Unknown' | 'Local' | 'Cloud';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        Local: 'Local' as TargetTypeEnum,
        Cloud: 'Cloud' as TargetTypeEnum
    };
    export type ScheduleTypeEnum = 'Unknown' | 'NotScheduled' | 'Daily' | 'Monthly' | 'Periodically' | 'Continuously';
    export const ScheduleTypeEnum = {
        Unknown: 'Unknown' as ScheduleTypeEnum,
        NotScheduled: 'NotScheduled' as ScheduleTypeEnum,
        Daily: 'Daily' as ScheduleTypeEnum,
        Monthly: 'Monthly' as ScheduleTypeEnum,
        Periodically: 'Periodically' as ScheduleTypeEnum,
        Continuously: 'Continuously' as ScheduleTypeEnum
    };
    export type ScheduleEventsEnum = 'Unknown' | 'AtLock' | 'AtLogoff' | 'WhenBackupTargetIsConnected';
    export const ScheduleEventsEnum = {
        Unknown: 'Unknown' as ScheduleEventsEnum,
        AtLock: 'AtLock' as ScheduleEventsEnum,
        AtLogoff: 'AtLogoff' as ScheduleEventsEnum,
        WhenBackupTargetIsConnected: 'WhenBackupTargetIsConnected' as ScheduleEventsEnum
    };
}