/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupFailoverPlanRestoreSession { 
    /**
     * UID assigned to a protected VM.
     */
    readonly instanceUid?: string;
    /**
     * Status of a failover session.
     */
    readonly backupStatus?: BackupFailoverPlanRestoreSession.BackupStatusEnum;
    /**
     * UID assigned to a replication restore point.
     */
    readonly restorePointUid?: string;
    /**
     * Date and time of the replication restore point creation.
     */
    readonly restorePointDateTime?: Date;
    /**
     * Failover session start date and time.
     */
    readonly startDateTime?: Date;
    /**
     * Failover session end date and time.
     */
    readonly endDateTime?: Date;
}
export namespace BackupFailoverPlanRestoreSession {
    export type BackupStatusEnum = 'Unknown' | 'Running' | 'Success' | 'Warning' | 'Failed';
    export const BackupStatusEnum = {
        Unknown: 'Unknown' as BackupStatusEnum,
        Running: 'Running' as BackupStatusEnum,
        Success: 'Success' as BackupStatusEnum,
        Warning: 'Warning' as BackupStatusEnum,
        Failed: 'Failed' as BackupStatusEnum
    };
}