/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxCommonCredentials } from './linuxCommonCredentials';

export interface LinuxSharedFolderTarget { 
    /**
     * Type of a network shared folder.
     */
    targetType: LinuxSharedFolderTarget.TargetTypeEnum;
    /**
     * Path to a network shared folder.'
     */
    path: string;
    /**
     * Credentials of a user account that has access permissions on a shared folder.
     */
    credentials?: LinuxCommonCredentials;
}
export namespace LinuxSharedFolderTarget {
    export type TargetTypeEnum = 'NFS' | 'SMB';
    export const TargetTypeEnum = {
        NFS: 'NFS' as TargetTypeEnum,
        SMB: 'SMB' as TargetTypeEnum
    };
}