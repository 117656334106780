import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import type { WizardStep , WizardStepData } from '@veeam-vspc/components';
import { Form, FormLayout, FormValidator, isValid, PasswordInput, TextInput , defaultMaskPlaceholder } from '@veeam-vspc/components';
import type { AdminUserModel } from '../../interfaces/admin-user-model';

import { useLangs } from 'views/layouts/LangLayout/hooks';

export const stepValidate = (data: AdminUserModel) => {
    const validator = new FormValidator(data);

    validator.validate('domain').string()
        .maxLength(253);
    validator.validate('userName').string()
        .maxLength(253);
    validator.validate('password').string()
        .maxLength(253);

    return validator.result();
};

export const Domain = ({ data, validationState, onDataChange }: WizardStepData<AdminUserModel>) => {
    const langs = useLangs();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.DOMAIN}
                description={langs.SPECIFY_DOMAIN_NAME_AND_CONNECTION_ACCOUNT}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name='domain'
                        placeholder={langs.LOCALHOST}
                        label={langs.DOMAIN_NAME}
                    />
                    <TextInput
                        name='userName'
                        label={langs.USERNAME}
                    />
                    <PasswordInput
                        name='password'
                        label={langs.PASSWORD}
                        hasValueMask={defaultMaskPlaceholder}
                    />
                </FormLayout>
            </StepLayout>
        </Form>
    );
};

export const getDomainStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    isHidden: ({ isEdit }) => isEdit,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <Domain {...data} />,
});
