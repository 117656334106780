import React from 'react';
import { observer } from 'mobx-react-lite';

import { useLoginStore } from '../../hooks';
import { LoginFactors } from '../../enums';
import { LoginStart } from '../LoginStart/LoginStart';
import { SelectServer } from '../SelectServer/SelectServer';
import { VerifyCode } from '../VerifyCode/VerifyCode';
import { ChangePassword } from '../ChangePassword/ChangePassword';
import { LoginSso } from '../LoginSso/LoginSso';
import { ResetPassword } from '../ResetPassword/ResetPassword';

export const LoginFactorsContainer = observer(() => {
    const loginStore = useLoginStore();

    switch (loginStore.currentFactor.type) {
        case (LoginFactors.LoginStart):
            return <LoginStart />;
        case (LoginFactors.SelectServer):
            return <SelectServer />;
        case (LoginFactors.VerifyCode):
            return <VerifyCode />;
        case (LoginFactors.ChangePassword):
            return <ChangePassword />;
        case (LoginFactors.ResetPassword):
            return <ResetPassword />;
        case (LoginFactors.Sso):
            return <LoginSso />;
        default: {
            console.error('Wrong login type');
            return <LoginStart />;
        }
    }
});
