import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    BaseFailedRequestResponse,
    BaseRequestResponse,
    BaseSuccessRequestResponse,
    BaseSuccessResponseData,
    emailValidator,
} from '@veeam-vspc/core';
import { ResetPasswordForm, ResetPasswordFormState } from '@veeam-vspc/components';

import { useAppServices } from 'views/layouts/AppLayout/hooks';
import { useLangs } from 'views/layouts/LangLayout/hooks';

import { useLoginStore } from '../../hooks';
import { BaseFactor } from '../BaseFactor/BaseFactor';
import { ResetPasswordData } from '../../interfaces';

export const ResetPassword = observer(() => {
    const { transportService } = useAppServices();
    const langs = useLangs();
    const loginStore = useLoginStore();

    return (
        <BaseFactor width={'442px'}>
            <ResetPasswordForm
                title={langs.RESET_PASSWORD}
                onReturnButtonClick={() => loginStore.onFinishFactorHandler({ goToLoginStart: true })}
                error={loginStore.error}
                loading={loginStore.isLoading}
                onChange={() => loginStore.resetMessages()}
                logIn={(formData: ResetPasswordFormState) => {
                    loginStore.isLoading = true;

                    const data = {
                        ...formData,
                        returnUrl: `${window.location.origin}/`,
                    };

                    transportService.request<ResetPasswordData, BaseRequestResponse>('/User/CreateResetPasswordRequest', data, null, {
                        noAuth: true,
                        notShowDefaultError: true,
                    })
                        .then(() => loginStore.onFinishFactorHandler({
                            goToLoginStart: true,
                            info: langs.A_PASSWORD_RESET_WAS_INITIATED_FOR_YOUR_ACCOUNT,
                        }))
                        .catch((resp: BaseRequestResponse) => {
                            const failedResp = resp as BaseFailedRequestResponse;
                            const successResp = resp as BaseSuccessRequestResponse;

                            loginStore.isLoading = false;
                            loginStore.error = failedResp.error || (successResp.data as BaseSuccessResponseData)?.message;
                        });
                }}
                texts={{
                    labelUsername: langs.USERNAME,
                    labelEmail: langs.EMAIL,
                    actionLogIn: langs.RESET,
                    errorRequired: langs.THE_FIELD_IS_REQUIRED,
                    errorMaxLength: langs.THE_MAXIMUM_LENGTH_IS,
                    warningAboutReset: langs.CLICK_RESET_TO_RECEIVE,
                    returnButtonText: langs.BACK,
                }}
                customEmailValidation={(value: string) => emailValidator(value, {
                    multipleEmailsError: langs.ONLY_ONE_EMAIL_ADDRESS_IS_SUPPORTED,
                    wrongEmailFormatError: langs.THIS_FIELD_SHOULD_BE_AN_EMAIL_ADDRESS,
                })}
            />
        </BaseFactor>
    );
});
