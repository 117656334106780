/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsVolumeLevelBackupSource { 
    /**
     * Filter type.
     */
    mode?: WindowsVolumeLevelBackupSource.ModeEnum;
    /**
     * Indicates whether agent operating system is included in a backup scope. >Available only if the `InclusionMode` filter type is selected. 
     */
    backupOperatingSystem?: boolean;
    /**
     * Array of drive letters of volumes that must be included in the backup scope. > Drive letters must be specified in the following format: `C:\\`. 
     */
    inclusions?: Array<string>;
    /**
     * Array of drive letters of volumes that must be excluded from the backup scope. > Drive letters must be specified in the following format: `C:\\`. 
     */
    exclusions?: Array<string>;
}
export namespace WindowsVolumeLevelBackupSource {
    export type ModeEnum = 'Unknown' | 'InclusionMode' | 'ExclusionMode';
    export const ModeEnum = {
        Unknown: 'Unknown' as ModeEnum,
        InclusionMode: 'InclusionMode' as ModeEnum,
        ExclusionMode: 'ExclusionMode' as ModeEnum
    };
}