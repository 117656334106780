import React from 'react';
import styled from 'styled-components';
import { constants, StackView, STACK_GAP, STACK_DIRECTION, Text, TEXT_VIEW } from '@veeam-vspc/components';

const Title = styled.div`
    font-family: ${constants.BASE_FONT_FAMILY};
    font-size: ${constants.FONT_SIZE_L};
    line-height: ${constants.FONT_LINE_HEIGHT_L};
    font-weight: ${constants.BASE_FONT_BOLD_WEIGHT};
    color: ${({ theme }) => theme.colorTextMain};
`;

interface PageWizardContentHeaderProps {
    title: string,
    description?: string;
}

export const PageWizardContentHeader = ({ title, description }: PageWizardContentHeaderProps) => {
    return (
        <StackView
            gap={STACK_GAP.s}
            direction={STACK_DIRECTION.column}
        >
            <Title>{title}</Title>
            {description && <Text view={TEXT_VIEW.secondary}>{description}</Text>}
        </StackView>
    );
};
