import { SidePanel, WizardPanelProps, SidePanelBody, DialogAction } from '@veeam-vspc/components';
import React from 'react';

interface Props extends WizardPanelProps {
    actions?: DialogAction[];
}

export const SidePanelForm = (props: Props) => {
    return (
        <SidePanel {...props}>
            <SidePanelBody
                growContent
                actions={props.actions}
                content={<>{props.children}</>}
            />
        </SidePanel>
    );
};
