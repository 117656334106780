import React, { useState } from 'react';
import type { WizardStep } from '@veeam-vspc/components';

import { PageWizard } from 'components/wizards/PageWizard';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { getLoginInfoStep } from '../Base/components/LoginInfo';
import { getUserInfoStep } from '../Base/components/UserInfo';
import { getLocationsStep } from './components/Locations';
import { getRoleStep } from './components/Role';
import type { CompanyUserModel } from './interfaces/company-user-model';
import type { CompanyUserProps } from './interfaces/company-user-props';
import { getSummaryStep } from './components/Summary';
import { Unit } from './enums/unit';
import { getQuotaStep } from './components/Quota';
import { getCompanyStep } from './components/Company';
import { getMultiFactorAuthStep } from '../Base/components/MultiFactorAuthentication';
import { saveUser } from 'features/RolesAndUsers/wizards/Base/helpers/saveUser';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const CompanyUser = (props: CompanyUserProps) => {
    const langs = useLangs();
    const [quota] = useState(() => {
        const repositoryQuota = props.model.repositoryQuota;

        if (repositoryQuota === undefined) {
            return {
                repositoryQuota: 100,
                repositoryQuotaUnit: Unit.GB,
            };
        }

        if (repositoryQuota >= 1024 && repositoryQuota % 1024 === 0) {
            return {
                repositoryQuota: repositoryQuota / 1024,
                repositoryQuotaUnit: Unit.TB,
            };
        } else {
            return {
                repositoryQuota: repositoryQuota,
                repositoryQuotaUnit: Unit.GB,
            };
        }
    });
    const data: CompanyUserModel = {
        userRole: RCOP.utils.Roles.isLocationAdmin() ? PortalUserRoles.LocationUser : PortalUserRoles.CompanyAdministrator,
        repositories: props.repositories,
        isUnlimited: false,
        repositoryFriendlyName: '',
        enableQuota: false,
        mfaPolicyStatus: props.mfaPolicyStatus,
        isAllowedToSwitchMfaPolicyStatus: true,
        sendInvoice: false,
        tenantRepositoryId: undefined,
        userTitle: undefined,
        locations: [],
        ...props.model,
        ...quota,
        password: '',
        confirmpassword: '',
        oldPassword: props.selfEdit ? '' : undefined,
        selfEdit: props.selfEdit,
        isCompanyAdminPortal: props.isCompanyAdminPortal || undefined,
    };
    const title = props.isEdit ? langs.EDIT_USER : langs.NEW_USER;

    return (
        <PageWizard
            title={title}
            deactivate={()=>{}}
            isEdit={props.isEdit}
            data={data}
            steps={[
                getCompanyStep(langs.COMPANY),
                getRoleStep(langs.ROLE),
                getUserInfoStep(langs.USER_INFO),
                getLoginInfoStep(langs.LOGIN_INFO),
                getLocationsStep(langs.LOCATIONS),
                getQuotaStep(langs.QUOTA),
                getMultiFactorAuthStep(langs.MULTI_FACTOR_AUTHENTICATION),
                getSummaryStep(langs.SUMMARY),
            ] as WizardStep<CompanyUserModel>[]}
            onFinish={async({ data }) => {
                const enableQuota = data.userRole === PortalUserRoles.Subtenant;
                const getRepositoryQuota = () => data.repositoryQuotaUnit === Unit.TB ? data.repositoryQuota * 1024 : data.repositoryQuota;

                const requestParams: { user: CompanyUserModel; } = {
                    user: {
                        ...data,
                        enableQuota,
                        quota: enableQuota && {
                            isUnlimited: data.isUnlimited,
                            repositoryFriendlyName: data.repositoryFriendlyName,
                            repositoryQuota: data.isUnlimited ? undefined : getRepositoryQuota(),
                            tenantRepositoryId: data.tenantRepositoryId,
                        },
                        userLocationIds: data.locations.map(location => location.iD),
                    },
                };

                return saveUser({ props, requestParams });
            }}
        />
    );
};
