/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CredentialsInfo } from './credentialsInfo';
import { UserProfile } from './userProfile';

export interface User { 
    /**
     * UID assigned to a user.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a user organization.
     */
    readonly organizationUid?: string;
    /**
     * Username.
     */
    readonly userName?: string;
    /**
     * User status.
     */
    status?: User.StatusEnum;
    /**
     * User MFA policy status.
     */
    mfaPolicyStatus?: User.MfaPolicyStatusEnum;
    /**
     * Status of user MFA policy configuration.
     */
    readonly mfaPolicyConfigurationStatus?: User.MfaPolicyConfigurationStatusEnum;
    /**
     * User role.
     */
    role: User.RoleEnum;
    /**
     * User profile.
     */
    profile: UserProfile;
    /**
     * User credentials.
     */
    credentials?: CredentialsInfo;
}
export namespace User {
    export type StatusEnum = 'Unknown' | 'Enabled' | 'Disabled';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Enabled: 'Enabled' as StatusEnum,
        Disabled: 'Disabled' as StatusEnum
    };
    export type MfaPolicyStatusEnum = 'Unknown' | 'Disabled' | 'Enabled' | 'EnabledByInheritance';
    export const MfaPolicyStatusEnum = {
        Unknown: 'Unknown' as MfaPolicyStatusEnum,
        Disabled: 'Disabled' as MfaPolicyStatusEnum,
        Enabled: 'Enabled' as MfaPolicyStatusEnum,
        EnabledByInheritance: 'EnabledByInheritance' as MfaPolicyStatusEnum
    };
    export type MfaPolicyConfigurationStatusEnum = 'Unknown' | 'NotConfigured' | 'Configured';
    export const MfaPolicyConfigurationStatusEnum = {
        Unknown: 'Unknown' as MfaPolicyConfigurationStatusEnum,
        NotConfigured: 'NotConfigured' as MfaPolicyConfigurationStatusEnum,
        Configured: 'Configured' as MfaPolicyConfigurationStatusEnum
    };
    export type RoleEnum = 'Unknown' | 'PortalAdministrator' | 'PortalOperator' | 'PortalReadonlyOperator' | 'SiteAdministrator' | 'CompanyLocationAdministrator' | 'CompanyLocationUser' | 'CompanyOwner' | 'CompanyAdministrator' | 'CompanyInvoiceAuditor' | 'CompanySubtenant' | 'ResellerOwner' | 'ResellerOperator' | 'ResellerUser' | 'ResellerInvoiceAuditor' | 'ResellerAdministrator';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        PortalAdministrator: 'PortalAdministrator' as RoleEnum,
        PortalOperator: 'PortalOperator' as RoleEnum,
        PortalReadonlyOperator: 'PortalReadonlyOperator' as RoleEnum,
        SiteAdministrator: 'SiteAdministrator' as RoleEnum,
        CompanyLocationAdministrator: 'CompanyLocationAdministrator' as RoleEnum,
        CompanyLocationUser: 'CompanyLocationUser' as RoleEnum,
        CompanyOwner: 'CompanyOwner' as RoleEnum,
        CompanyAdministrator: 'CompanyAdministrator' as RoleEnum,
        CompanyInvoiceAuditor: 'CompanyInvoiceAuditor' as RoleEnum,
        CompanySubtenant: 'CompanySubtenant' as RoleEnum,
        ResellerOwner: 'ResellerOwner' as RoleEnum,
        ResellerOperator: 'ResellerOperator' as RoleEnum,
        ResellerUser: 'ResellerUser' as RoleEnum,
        ResellerInvoiceAuditor: 'ResellerInvoiceAuditor' as RoleEnum,
        ResellerAdministrator: 'ResellerAdministrator' as RoleEnum
    };
}