/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BackupServerAgentJobSourceFileSystemItems } from './backupServerAgentJobSourceFileSystemItems';

export interface BackupServerAgentJobSource { 
    /**
     * Backup mode.
     */
    readonly backupMode?: BackupServerAgentJobSource.BackupModeEnum;
    /**
     * Indicates whether a backup job protects individual folders.
     */
    readonly backupUserFolders?: boolean;
    /**
     * Indicates whether agent operating system is included in a backup scope.
     */
    readonly backupOperatingSystem?: boolean;
    fileSystemItems?: BackupServerAgentJobSourceFileSystemItems;
}
export namespace BackupServerAgentJobSource {
    export type BackupModeEnum = 'Unknown' | 'EntireComputer' | 'Volume' | 'File';
    export const BackupModeEnum = {
        Unknown: 'Unknown' as BackupModeEnum,
        EntireComputer: 'EntireComputer' as BackupModeEnum,
        Volume: 'Volume' as BackupModeEnum,
        File: 'File' as BackupModeEnum
    };
}