/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsJobScript } from './windowsJobScript';
import { WindowsJobScriptExecutionAccount } from './windowsJobScriptExecutionAccount';

export interface WindowsJobScriptSettings { 
    /**
     * Script processing mode.
     */
    mode?: WindowsJobScriptSettings.ModeEnum;
    /**
     * Path to a pre-freeze script file.
     */
    preFreezeScript?: WindowsJobScript;
    /**
     * Path to a post-thaw script file.
     */
    postThawScript?: WindowsJobScript;
    /**
     * Credentials of a user account with permissions to run scripts.
     */
    credentials?: WindowsJobScriptExecutionAccount;
}
export namespace WindowsJobScriptSettings {
    export type ModeEnum = 'Disabled' | 'IgnoreErrors' | 'FailJobOnError';
    export const ModeEnum = {
        Disabled: 'Disabled' as ModeEnum,
        IgnoreErrors: 'IgnoreErrors' as ModeEnum,
        FailJobOnError: 'FailJobOnError' as ModeEnum
    };
}