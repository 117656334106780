import { NotificationDialogsTextKeys } from '@veeam-vspc/components';
import type { TransportConfigParams } from '@veeam-vspc/core';
import {
    LoggerService,
    NotificationService,
    TokenManagerService,
    TokenManagerTextKeys,
    TransportService,
    TransportTextKeys,
    NotificationDefaultTitles,
} from '@veeam-vspc/core';

import type { VspcTransportActionsServices } from '../services';
import { PortalService, GlobalFiltersService, LangsService, VspcTransportActions } from '../services';
import type { LangsServiceBase } from '../services/langs/interfaces';
import type { AppServices } from '../../views/layouts/AppLayout/interfaces';

export class CoreModule {

    protected readonly restPrefix = '/api/v3';

    notificationService: NotificationService;
    tokenManagerService: TokenManagerService;
    loggerService: LoggerService;
    transportService: TransportService;
    portalService: PortalService;
    globalFiltersService: GlobalFiltersService;

    // @TODO move to core service
    langsService: LangsService<LangsServiceBase>;

    protected isServicesCreated = false;

    getServices(): AppServices {
        if (!this.isServicesCreated) {
            this.createServiceInstances();
        }

        return {
            notificationService: this.notificationService,
            tokenManagerService: this.tokenManagerService,
            loggerService: this.loggerService,
            transportService: this.transportService,
            portalService: this.portalService,
            globalFiltersService: this.globalFiltersService,
            langsService: this.langsService,
        };
    }

    protected createServiceInstances(): void {
        const { restPrefix } = this;

        this.globalFiltersService = new GlobalFiltersService();
        this.notificationService = new NotificationService({
            [NotificationDialogsTextKeys.Ok]: RCOP.Lang.OK,
            [NotificationDialogsTextKeys.Yes]: RCOP.Lang.YES,
            [NotificationDialogsTextKeys.No]: RCOP.Lang.NO,
            [NotificationDialogsTextKeys.Cancel]: RCOP.Lang.CANCEL,
            [NotificationDialogsTextKeys.Close]: RCOP.Lang.CLOSE,
            [NotificationDefaultTitles.Error]: RCOP.Lang.ERROR,
            [NotificationDefaultTitles.Information]: RCOP.Lang.INFORMATION,
            [NotificationDefaultTitles.Success]: RCOP.Lang.SUCCESS,
            [NotificationDefaultTitles.Warning]: RCOP.Lang.WARNING,
        });
        this.tokenManagerService = new TokenManagerService(this.notificationService, {
            productPrefix: RCOP.Lang.VSPC,
            authUrl: `${restPrefix}/token`,
            texts: {
                [TokenManagerTextKeys.WebServerErrorTitle]: RCOP.Lang.CONNECTION_ERROR,
                [TokenManagerTextKeys.WebServerErrorMessage]: RCOP.Lang.WEB_SERVER_IS_NOT_RESPONDING,
                [TokenManagerTextKeys.AuthErrorTitle]: RCOP.Lang.SESSION_EXPIRED,
                [TokenManagerTextKeys.AuthErrorMessage]: RCOP.Lang.PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING,
                [TokenManagerTextKeys.AuthButtonMessage]: RCOP.utils.String.capitalize(RCOP.Lang.LOG_IN),
            },
        });
        this.loggerService = new LoggerService(this.tokenManagerService, '/Error/WriteLog');

        const services: VspcTransportActionsServices = {
            loggerService: this.loggerService,
            tokenManagerService: this.tokenManagerService,
            notificationService: this.notificationService,
            globalFiltersService: this.globalFiltersService,
        };
        const transportParams: TransportConfigParams = {
            restPrefix,
            authorizationCodeProps: {
                url: '/Login/AuthorizationCode',
                queryKey: 'x-authorization-code',
            },
            trustedUrlsWithNoError: [
                'LicenseReport/SaveNotificationSettings',
                'Setting/SetSMTPSettings',
                'CloudConnectServers/CheckAgent',
            ],
            texts: {
                [TransportTextKeys.DownloadErrorMessage]: RCOP.Lang.FAILED_TO_DOWNLOAD_LOG_FILES,
                [TransportTextKeys.ErrorTitle]: RCOP.Lang.ERROR,
            },
        };

        this.transportService = new TransportService({ services, transportParams, TransportActions: VspcTransportActions });
        this.langsService = new LangsService<LangsServiceBase>(this.transportService);
        this.portalService = new PortalService(this.transportService, {
            portalDataUrl: '/Login/IsLogged',
            enabledFeaturesUrl: '/Features/GetEnabledFeatures',
            identityProvidersUrl: '/authentication/identityProviders',
        });

        this.isServicesCreated = true;
    }

}

export const core = new CoreModule();
