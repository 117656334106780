/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrganizationLicenseUsage { 
    /**
     * Organization type.
     */
    readonly organizationType?: OrganizationLicenseUsage.OrganizationTypeEnum;
    /**
     * Organization name.
     */
    readonly organizationName?: string;
    /**
     * UID assigned to an organization.
     */
    readonly organizationUid?: string;
    /**
     * UID assigned to a tenant. > For resellers the property value is `null`. 
     */
    readonly tenantUid?: string;
    /**
     * Number of license points used by an organization.
     */
    readonly usedPoints?: number;
}
export namespace OrganizationLicenseUsage {
    export type OrganizationTypeEnum = 'Unknown' | 'Company' | 'Reseller';
    export const OrganizationTypeEnum = {
        Unknown: 'Unknown' as OrganizationTypeEnum,
        Company: 'Company' as OrganizationTypeEnum,
        Reseller: 'Reseller' as OrganizationTypeEnum
    };
}