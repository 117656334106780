/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SmtpSettingsOAuth2Credential } from './smtpSettingsOAuth2Credential';
import { SmtpSettingsPasswordCredential } from './smtpSettingsPasswordCredential';
import { SmtpSettingsServerCertificate } from './smtpSettingsServerCertificate';

/**
 * [TBD] SMTP settings.
 */
export interface SmtpSettings { 
    /**
     * [TBD] Server address with protocol, host and port. This should be valid URI. Example \"smtp://example.com:25\".
     */
    serverAddress: string;
    /**
     * [TBD] Type of secure socket comminucation used to connect to server.
     */
    tlsMode: SmtpSettings.TlsModeEnum;
    /**
     * [TBD] Connection timeout. Timeout of establishing connection and authenticating at server. If not set then no timeout (not recommended).
     */
    timeout: string;
    passwordCredential?: SmtpSettingsPasswordCredential;
    oAuth2Credential?: SmtpSettingsOAuth2Credential;
    /**
     * [TBD] Server X509 certificate hex-encoded hash. Format \"<hash-algorithm>:<hash-hex>\". Used to verify that server has valid self-signed/alternative PKI certificate.
     */
    exclusivelyAcceptedCertificateHash?: string;
    serverCertificate?: SmtpSettingsServerCertificate;
}
export namespace SmtpSettings {
    export type TlsModeEnum = 'auto' | 'none' | 'sslOnConnect' | 'startTls' | 'startTlsWhenAvailable';
    export const TlsModeEnum = {
        Auto: 'auto' as TlsModeEnum,
        None: 'none' as TlsModeEnum,
        SslOnConnect: 'sslOnConnect' as TlsModeEnum,
        StartTls: 'startTls' as TlsModeEnum,
        StartTlsWhenAvailable: 'startTlsWhenAvailable' as TlsModeEnum
    };
}