/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Body { 
    /**
     * Grant type according to RFC 6749.
     */
    grantType: Body.GrantTypeEnum;
    /**
     * User name. > Used with the `password` grant type. 
     */
    username?: string;
    /**
     * Password. > Used with the `password` grant type. 
     */
    password?: string;
    /**
     * Refresh token. > Used with the `refresh_token` grant type. 
     */
    refreshToken?: string;
    /**
     * Multi-factor authentication token. > Used with the `mfa` grant type. 
     */
    mfaToken?: string;
    /**
     * Multi-factor authentication code. > Used with the `mfa` grant type. 
     */
    mfaCode?: string;
    /**
     * Authorization code. > Used with the `authorization_code` grant type. 
     */
    code?: string;
    /**
     * Public key encoded in the Base64 format. > Used with the `public_key` grant type. 
     */
    publicKey?: string;
}
export namespace Body {
    export type GrantTypeEnum = 'password' | 'refresh_token' | 'authorization_code' | 'public_key' | 'mfa';
    export const GrantTypeEnum = {
        Password: 'password' as GrantTypeEnum,
        RefreshToken: 'refresh_token' as GrantTypeEnum,
        AuthorizationCode: 'authorization_code' as GrantTypeEnum,
        PublicKey: 'public_key' as GrantTypeEnum,
        Mfa: 'mfa' as GrantTypeEnum
    };
}