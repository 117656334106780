/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * [TBD] Attribute mapping
 */
export interface IdentityProviderAttributeMapping { 
    /**
     * [TBD]
     */
    claimType: string;
    /**
     * [TBD]
     */
    allowAliases?: boolean;
    /**
     * [TBD]
     */
    attribute: IdentityProviderAttributeMapping.AttributeEnum;
    /**
     * [TBD]
     */
    defaultValue?: string;
}
export namespace IdentityProviderAttributeMapping {
    export type AttributeEnum = 'Unknown' | 'FirstName' | 'LastName' | 'Name' | 'Address' | 'Phone';
    export const AttributeEnum = {
        Unknown: 'Unknown' as AttributeEnum,
        FirstName: 'FirstName' as AttributeEnum,
        LastName: 'LastName' as AttributeEnum,
        Name: 'Name' as AttributeEnum,
        Address: 'Address' as AttributeEnum,
        Phone: 'Phone' as AttributeEnum
    };
}