/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscoveryRuleDailyScheduleSettings } from './discoveryRuleDailyScheduleSettings';
import { DiscoveryRuleMonthlyScheduleSettings } from './discoveryRuleMonthlyScheduleSettings';
import { DiscoveryRulePeriodicalScheduleSettings } from './discoveryRulePeriodicalScheduleSettings';
import { TimeZone } from './timeZone';

export interface DiscoveryRuleScheduleSettings { 
    /**
     * Schedule type
     */
    scheduleType: DiscoveryRuleScheduleSettings.ScheduleTypeEnum;
    /**
     * Time zone settings.
     */
    timeZone?: TimeZone;
    /**
     * Scheduling settings required to run discovery daily.
     */
    dailyScheduleSettings?: DiscoveryRuleDailyScheduleSettings;
    /**
     * Scheduling settings required to run discovery monthly.
     */
    monthlyScheduleSettings?: DiscoveryRuleMonthlyScheduleSettings;
    /**
     * Scheduling settings required to run discovery repeatedly throughout a day.
     */
    periodicalScheduleSettings?: DiscoveryRulePeriodicalScheduleSettings;
}
export namespace DiscoveryRuleScheduleSettings {
    export type ScheduleTypeEnum = 'NotScheduled' | 'Daily' | 'Monthly' | 'Periodically' | 'Continuously';
    export const ScheduleTypeEnum = {
        NotScheduled: 'NotScheduled' as ScheduleTypeEnum,
        Daily: 'Daily' as ScheduleTypeEnum,
        Monthly: 'Monthly' as ScheduleTypeEnum,
        Periodically: 'Periodically' as ScheduleTypeEnum,
        Continuously: 'Continuously' as ScheduleTypeEnum
    };
}