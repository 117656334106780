/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriptionPlanCloudReplication { 
    /**
     * Charge rate for one VM replica registered on a cloud host, per month.
     */
    replicatedVmPrice?: number;
    /**
     * Charge rate for one GB or TB of cloud storage space consumed by VM replica files.
     */
    cloudStorageConsumedSpacePrice?: number;
    /**
     * Measurement units of cloud storage space consumed by VM replica files.
     */
    cloudStorageConsumedSpaceUnits?: SubscriptionPlanCloudReplication.CloudStorageConsumedSpaceUnitsEnum;
    /**
     * Amount of cloud storage space that can be consumed by VM replicas for free.
     */
    freeCloudStorageConsumedSpace?: number;
    /**
     * Measurement units of cloud storage space that can be consumed by VM replicas for free.
     */
    freeCloudStorageConsumedSpaceUnits?: SubscriptionPlanCloudReplication.FreeCloudStorageConsumedSpaceUnitsEnum;
    /**
     * Charge rate for a CPU and memory resources usage by a VM on a cloud host.
     */
    computeResourcesPrice?: number;
    /**
     * Measurement units of time period of CPU and memory resources usage by a VM on a cloud host
     */
    computeResourcesUnits?: SubscriptionPlanCloudReplication.ComputeResourcesUnitsEnum;
    /**
     * Amount of time during which VM replicas can consume compute resources on a cloud host for free.
     */
    freeComputeResources?: number;
    /**
     * Measurement units of time during which VM replicas can consume compute resources on a cloud host for free.
     */
    freeComputeResourcesUnits?: SubscriptionPlanCloudReplication.FreeComputeResourcesUnitsEnum;
    /**
     * Charge rate for one GB or TB of VM replica data downloaded from cloud storage.
     */
    replicationDataTransferOutPrice?: number;
    /**
     * Measurement units of VM replica data downloaded from cloud storage.
     */
    replicationDataTransferOutUnits?: SubscriptionPlanCloudReplication.ReplicationDataTransferOutUnitsEnum;
}
export namespace SubscriptionPlanCloudReplication {
    export type CloudStorageConsumedSpaceUnitsEnum = 'GB' | 'TB';
    export const CloudStorageConsumedSpaceUnitsEnum = {
        GB: 'GB' as CloudStorageConsumedSpaceUnitsEnum,
        TB: 'TB' as CloudStorageConsumedSpaceUnitsEnum
    };
    export type FreeCloudStorageConsumedSpaceUnitsEnum = 'GB' | 'TB';
    export const FreeCloudStorageConsumedSpaceUnitsEnum = {
        GB: 'GB' as FreeCloudStorageConsumedSpaceUnitsEnum,
        TB: 'TB' as FreeCloudStorageConsumedSpaceUnitsEnum
    };
    export type ComputeResourcesUnitsEnum = 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months';
    export const ComputeResourcesUnitsEnum = {
        Minutes: 'Minutes' as ComputeResourcesUnitsEnum,
        Hours: 'Hours' as ComputeResourcesUnitsEnum,
        Days: 'Days' as ComputeResourcesUnitsEnum,
        Weeks: 'Weeks' as ComputeResourcesUnitsEnum,
        Months: 'Months' as ComputeResourcesUnitsEnum
    };
    export type FreeComputeResourcesUnitsEnum = 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months';
    export const FreeComputeResourcesUnitsEnum = {
        Minutes: 'Minutes' as FreeComputeResourcesUnitsEnum,
        Hours: 'Hours' as FreeComputeResourcesUnitsEnum,
        Days: 'Days' as FreeComputeResourcesUnitsEnum,
        Weeks: 'Weeks' as FreeComputeResourcesUnitsEnum,
        Months: 'Months' as FreeComputeResourcesUnitsEnum
    };
    export type ReplicationDataTransferOutUnitsEnum = 'GB' | 'TB';
    export const ReplicationDataTransferOutUnitsEnum = {
        GB: 'GB' as ReplicationDataTransferOutUnitsEnum,
        TB: 'TB' as ReplicationDataTransferOutUnitsEnum
    };
}