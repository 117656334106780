import styled from 'styled-components';
import { FlexContainer } from '../FlexContainer';
import { constants, colors } from '@veeam-vspc/components';
import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT } from 'features/HeatMap/components/HeatMap/HeatMap.styled';

export const WEEK_TITLE_HEIGHT = constants.SIZE_XXL;
export const NARROW_WEEK_TITLE_HEIGHT = constants.SIZE_L;

export const WeekTitleWrapper = styled(FlexContainer)`
  height: ${WEEK_TITLE_HEIGHT};
  border-bottom: ${constants.BASE_BORDER_WIDTH} solid ${colors.G300};

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
    height: ${NARROW_WEEK_TITLE_HEIGHT};
  }
`;

export const DayTitleWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  font-weight: ${constants.BASE_FONT_BOLD_WEIGHT};
`;
