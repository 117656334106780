/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LicenseReportFinalizationStatus { 
    /**
     * Report finalization status.
     */
    reportApprovalStatus: LicenseReportFinalizationStatus.ReportApprovalStatusEnum;
    /**
     * Message.
     */
    message: string;
}
export namespace LicenseReportFinalizationStatus {
    export type ReportApprovalStatusEnum = 'Unknown' | 'NotApproved' | 'Approved' | 'Finalized' | 'Finalization' | 'NotApprovable';
    export const ReportApprovalStatusEnum = {
        Unknown: 'Unknown' as ReportApprovalStatusEnum,
        NotApproved: 'NotApproved' as ReportApprovalStatusEnum,
        Approved: 'Approved' as ReportApprovalStatusEnum,
        Finalized: 'Finalized' as ReportApprovalStatusEnum,
        Finalization: 'Finalization' as ReportApprovalStatusEnum,
        NotApprovable: 'NotApprovable' as ReportApprovalStatusEnum
    };
}