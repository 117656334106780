/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WorkloadLicenseUsage { 
    /**
     * Workload type.
     */
    readonly workloadType?: WorkloadLicenseUsage.WorkloadTypeEnum;
    /**
     * Number of managed agents.
     */
    readonly initialCount?: number;
    /**
     * Number of managed agents after report finalization.
     */
    readonly reportedCount?: number;
    /**
     * Number of managed agents that were activated within the current calendar month.
     */
    readonly newCount?: number;
    /**
     * Number of points a single workload uses.
     */
    readonly weight?: number;
    /**
     * Number of points used by all managed agents of the same type.
     */
    readonly usedPoints?: number;
}
export namespace WorkloadLicenseUsage {
    export type WorkloadTypeEnum = 'Unknown' | 'VBR_VM' | 'VBR_Cloud_VM' | 'VAC_Workstation_Agent' | 'VAC_Server_Agent' | 'VBR_Workstation_Agent' | 'VBR_Server_Agent' | 'VBR_Application_Plugins' | 'VBR_NAS_Backup' | 'CC_VM_Backup' | 'CC_Workstation_Backup' | 'CC_Server_Backup' | 'CC_VM_Replica';
    export const WorkloadTypeEnum = {
        Unknown: 'Unknown' as WorkloadTypeEnum,
        VBRVM: 'VBR_VM' as WorkloadTypeEnum,
        VBRCloudVM: 'VBR_Cloud_VM' as WorkloadTypeEnum,
        VACWorkstationAgent: 'VAC_Workstation_Agent' as WorkloadTypeEnum,
        VACServerAgent: 'VAC_Server_Agent' as WorkloadTypeEnum,
        VBRWorkstationAgent: 'VBR_Workstation_Agent' as WorkloadTypeEnum,
        VBRServerAgent: 'VBR_Server_Agent' as WorkloadTypeEnum,
        VBRApplicationPlugins: 'VBR_Application_Plugins' as WorkloadTypeEnum,
        VBRNASBackup: 'VBR_NAS_Backup' as WorkloadTypeEnum,
        CCVMBackup: 'CC_VM_Backup' as WorkloadTypeEnum,
        CCWorkstationBackup: 'CC_Workstation_Backup' as WorkloadTypeEnum,
        CCServerBackup: 'CC_Server_Backup' as WorkloadTypeEnum,
        CCVMReplica: 'CC_VM_Replica' as WorkloadTypeEnum
    };
}