/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LinuxActiveFullSettings } from './linuxActiveFullSettings';
import { LinuxDailyScheduleSettings } from './linuxDailyScheduleSettings';
import { LinuxMonthlyScheduleSettings } from './linuxMonthlyScheduleSettings';
import { LinuxPeriodicallyScheduleSettings } from './linuxPeriodicallyScheduleSettings';
import { LinuxScheduleRetrySettings } from './linuxScheduleRetrySettings';

export interface LinuxJobScheduleSettings { 
    /**
     * Type of periodicity.
     */
    scheduleType?: LinuxJobScheduleSettings.ScheduleTypeEnum;
    /**
     * Scheduling settings required for a daily running job.
     */
    dailyScheduleSettings?: LinuxDailyScheduleSettings;
    /**
     * Scheduling settings required for a monthly running job.
     */
    monthlyScheduleSettings?: LinuxMonthlyScheduleSettings;
    /**
     * Scheduling settings required to run a job repeatedly throughout a day.
     */
    periodicallyScheduleSettings?: LinuxPeriodicallyScheduleSettings;
    /**
     * Scheduling settings for periodically created active full backups. > The `null` value indicates that periodic creation of active full backups is disabled.' 
     */
    activeFullSettings?: LinuxActiveFullSettings;
    /**
     * Automatic retry settings.
     */
    retrySettings?: LinuxScheduleRetrySettings;
}
export namespace LinuxJobScheduleSettings {
    export type ScheduleTypeEnum = 'Unknown' | 'NotScheduled' | 'Daily' | 'Monthly' | 'Periodically';
    export const ScheduleTypeEnum = {
        Unknown: 'Unknown' as ScheduleTypeEnum,
        NotScheduled: 'NotScheduled' as ScheduleTypeEnum,
        Daily: 'Daily' as ScheduleTypeEnum,
        Monthly: 'Monthly' as ScheduleTypeEnum,
        Periodically: 'Periodically' as ScheduleTypeEnum
    };
}