/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerFileShareCopyJob { 
    /**
     * UID assigned to a job.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a source file share job.
     */
    readonly sourceFileShareJobUid?: string;
    /**
     * UID assigned to a target backup repository.
     */
    readonly targetRepositoryUid?: string;
    /**
     * Duration of backup file retention.
     */
    readonly retention?: number;
    /**
     * Measurement units of backup file retention duration.
     */
    readonly retentionUnit?: BackupServerFileShareCopyJob.RetentionUnitEnum;
}
export namespace BackupServerFileShareCopyJob {
    export type RetentionUnitEnum = 'Minutes' | 'Hours' | 'Days' | 'Months' | 'Years';
    export const RetentionUnitEnum = {
        Minutes: 'Minutes' as RetentionUnitEnum,
        Hours: 'Hours' as RetentionUnitEnum,
        Days: 'Days' as RetentionUnitEnum,
        Months: 'Months' as RetentionUnitEnum,
        Years: 'Years' as RetentionUnitEnum
    };
}