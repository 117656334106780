/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupReport { 
    /**
     * [TBD]
     */
    readonly name?: string;
    /**
     * [TBD]
     */
    readonly companyId?: number;
    /**
     * [TBD]
     */
    readonly statementUid?: string;
    /**
     * [TBD]
     */
    readonly type?: BackupReport.TypeEnum;
    /**
     * [TBD]Date and time when the invoice was generated
     */
    readonly creationDate?: Date;
}
export namespace BackupReport {
    export type TypeEnum = 'Unknown' | 'ProtectedVMs' | 'ProtectedComputers';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        ProtectedVMs: 'ProtectedVMs' as TypeEnum,
        ProtectedComputers: 'ProtectedComputers' as TypeEnum
    };
}