/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupProxy { 
    /**
     * UID assigned to a backup proxy.
     */
    readonly instanceUid?: string;
    /**
     * Name of a backup proxy.
     */
    readonly name?: string;
    /**
     * Version of a backup proxy service.
     */
    readonly version?: string;
    /**
     * UID assigned to a Veeam Backup & Replication server.
     */
    readonly backupServerUid?: string;
    /**
     * Indicates whether a backup proxy service is outdated.
     */
    readonly isOutOfDate?: boolean;
    /**
     * Indicates whether a backup proxy is disabled.
     */
    readonly isDisabled?: boolean;
    /**
     * UID assigned to a server that performs a role of a backup proxy.
     */
    readonly hostUid?: string;
    /**
     * Computer name of a server that performs a role of a backup proxy.
     */
    readonly hostName?: string;
    /**
     * Backup proxy status.
     */
    readonly status?: BackupProxy.StatusEnum;
    /**
     * Type of a backup proxy.
     */
    readonly type?: BackupProxy.TypeEnum;
}
export namespace BackupProxy {
    export type StatusEnum = 'Unknown' | 'Healthy' | 'Warning' | 'Error';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Healthy: 'Healthy' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Error: 'Error' as StatusEnum
    };
    export type TypeEnum = 'Unknown' | 'vSphere' | 'HyperV' | 'HyperVOffhost' | 'File';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        VSphere: 'vSphere' as TypeEnum,
        HyperV: 'HyperV' as TypeEnum,
        HyperVOffhost: 'HyperVOffhost' as TypeEnum,
        File: 'File' as TypeEnum
    };
}