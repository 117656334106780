import React, { useRef, useState } from 'react';
import type { GridStore, RequestParams } from '@veeam-vspc/components';
import {
    Grid,
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
    GRID_SORT_DIRECTIONS,
} from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import type { Company } from '../../../../interfaces/company';
import type { BaseResponse } from 'interfaces/base-response';
import type { ManagedCompaniesFilterModel } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/managed-companies-filter-model';
import type { ManagedCompaniesProps } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/managed-companies-props';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppServices } from 'views/layouts/AppLayout/hooks';

export const ManagedCompaniesPanel = (props: ManagedCompaniesProps) => {
    const langs = useLangs();
    const { transportService } = useAppServices();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<Company, {}, ManagedCompaniesFilterModel>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.selectedCompanies?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={langs.COMPANIES}
            description={langs.SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER}
            actions={[
                {
                    text: langs.APPLY,
                    onClick: () => {
                        data.selectedCompanies = api.current.selected;

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: langs.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <Grid
                initialState={{
                    selected: data.selectedCompanies,
                    sort: {
                        direction: GRID_SORT_DIRECTIONS.asc,
                        key: 'name',
                    },
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: langs.NAME },
                    { field: 'siteName', title: langs.SITE },
                    { field: 'locations', title: langs.LOCATIONS },
                    { field: 'companyStatus', title: langs.STATE },
                    { field: 'managerCompanyName', title: langs.MANAGED_BY },
                ]}
                data={(requestParams: RequestParams<ManagedCompaniesFilterModel>) => transportService
                    .request<typeof requestParams, BaseResponse<Company>>('React/GetWinCompanies', requestParams)}
                toggleable={false}
                paginationLimit={Number.MAX_SAFE_INTEGER}
                selection={{
                    field: 'iD',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () =>
                                        <Search
                                            name='companyName'
                                            placeholder={langs.COMPANY_NAME}
                                            hasSearchButton={true}
                                        />,
                                },
                            ]}
                        />
                    ),
                ]}
                api={api}
            />
        </SidePanelForm>
    );
};
