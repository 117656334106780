/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LicenseReportAppendix } from './licenseReportAppendix';
import { LicenseReportParameters } from './licenseReportParameters';
import { LicenseReportSummary } from './licenseReportSummary';
import { OrganizationLicenseUsage } from './organizationLicenseUsage';
import { OrganizationUsageOfLicensesWithSameSupportId } from './organizationUsageOfLicensesWithSameSupportId';

export interface SingleLicenseReport { 
    /**
     * License usage report status.
     */
    readonly reportStatus?: SingleLicenseReport.ReportStatusEnum;
    /**
     * Report parameters
     */
    readonly reportParemeters?: LicenseReportParameters;
    /**
     * Report summary information.
     */
    readonly reportSummary?: LicenseReportSummary;
    /**
     * Number of license points used by managed organizations.
     */
    readonly organizationsUsage?: Array<OrganizationLicenseUsage>;
    /**
     * Array of Veeam Cloud Connect servers from which Veeam Service Provider Console could not collect the license usage data.
     */
    readonly notCollectedCloudServers?: Array<string>;
    /**
     * Usage of licenses with the same support ID.
     */
    readonly usageDetails?: Array<OrganizationUsageOfLicensesWithSameSupportId>;
    /**
     * Usage of licenses issued to organizations other than report owner.
     */
    readonly appendices?: Array<LicenseReportAppendix>;
}
export namespace SingleLicenseReport {
    export type ReportStatusEnum = 'Unknown' | 'ApprovalRequired' | 'Finalization' | 'Approved';
    export const ReportStatusEnum = {
        Unknown: 'Unknown' as ReportStatusEnum,
        ApprovalRequired: 'ApprovalRequired' as ReportStatusEnum,
        Finalization: 'Finalization' as ReportStatusEnum,
        Approved: 'Approved' as ReportStatusEnum
    };
}