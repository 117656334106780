import styled from 'styled-components';
import { FlexContainer } from '../FlexContainer';
import { colors, constants } from '@veeam-vspc/components';
import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT } from 'features/HeatMap/components/HeatMap/HeatMap.styled';

export const LEGEND_HEIGHT = constants.SIZE_XXL;
export const NARROW_LEGEND_HEIGHT = constants.SIZE_L;
const LEGEND_BORDER = `2px solid ${colors.G300}`;

export const LegendWrapper = styled(FlexContainer)`
  height: ${LEGEND_HEIGHT};
  border-top: ${LEGEND_BORDER};

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
    height: ${NARROW_LEGEND_HEIGHT};
  }
`;

const Legend = styled.div`
  margin-right: ${constants.SPACING_M};

  &::before {
    content: '';
    display: inline-block;
    width: ${constants.SIZE_S};
    height: ${constants.SIZE_S};
    border-radius: ${constants.SIZE_S};
    margin-right: ${constants.SPACING_XS};
  }
`;

export const FailedLabel = styled(Legend)`
  &::before {
    background-color: ${colors.R800};
  }
`;

export const WarningLabel = styled(Legend)`
  &::before {
    background-color: #F2C973;
  }
`;

export const SuccessLabel = styled(Legend)`
  &::before {
    background-color: ${colors.Grn400};
  }
`;
