import styled from 'styled-components';
import { colors, constants } from '@veeam-vspc/components';
import { Week } from '../Week';
import { DayWrapper } from '../Day/components/DayBase';
import { NARROW_HEAT_MAP_HEIGHT_BREAKPOINT, MAX_INNER_HEIGHT_HEAT_MAP_CONTAINER } from 'features/HeatMap/components/HeatMap/HeatMap.styled';
import { LEGEND_HEIGHT, NARROW_LEGEND_HEIGHT } from 'features/HeatMap/components/Legend/Legend.styled';
import {
    NARROW_WEEK_TITLE_HEIGHT,
    WEEK_TITLE_HEIGHT
} from 'features/HeatMap/components/WeekTitle/WeekTitle.styled';

export const DaysWrapper = styled.div`
  border-color: ${colors.G300};
  border-style: solid;
  border-width: 0 0 0 ${constants.BASE_BORDER_WIDTH};
  height: calc(100% - ${LEGEND_HEIGHT} - ${WEEK_TITLE_HEIGHT});
  max-height: ${MAX_INNER_HEIGHT_HEAT_MAP_CONTAINER};

  @media only screen and (max-height: ${NARROW_HEAT_MAP_HEIGHT_BREAKPOINT}) {
      height: calc(100% - ${NARROW_LEGEND_HEIGHT} - ${NARROW_WEEK_TITLE_HEIGHT});
  }

  ${Week}:first-child > ${DayWrapper} { // disable the top border for the first week
    border-top: 0;
  }
`;
