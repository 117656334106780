/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinuxOracleArchivedLogsTruncationConfig { 
    /**
     * Archived log processing mode.
     */
    truncationMode?: LinuxOracleArchivedLogsTruncationConfig.TruncationModeEnum;
    /**
     * Maximum threshold for archived log file size, in GB. If an archived log file exceeds the limitation, it is deleted. > Required for the `TruncateBySize` archived log processing mode. 
     */
    sizeGB?: number;
    /**
     * Amount of time after which archived logs must be deleted, in hours. > Required for the `TruncateByAge` archived log processing mode. 
     */
    lifeTimeHours?: number;
}
export namespace LinuxOracleArchivedLogsTruncationConfig {
    export type TruncationModeEnum = 'TruncateDisabled' | 'TruncateByAge' | 'TruncateBySize';
    export const TruncationModeEnum = {
        TruncateDisabled: 'TruncateDisabled' as TruncationModeEnum,
        TruncateByAge: 'TruncateByAge' as TruncationModeEnum,
        TruncateBySize: 'TruncateBySize' as TruncationModeEnum
    };
}