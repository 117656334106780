const getProductTheme = (portalThemeName: string): string => {
    switch (portalThemeName) {
        case ('turquoise'): return 'teal';
        default: return portalThemeName;
    }
};

export {
    getProductTheme,
};
