/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinuxDailyScheduleSettings { 
    /**
     * Timestamp of a job start.
     */
    time?: string;
    /**
     * Type of daily schedule.
     */
    dailyMode?: LinuxDailyScheduleSettings.DailyModeEnum;
    /**
     * Name of the week day. Required for the SpecificDays type of daily schedule.
     */
    specificDays?: Array<LinuxDailyScheduleSettings.SpecificDaysEnum>;
}
export namespace LinuxDailyScheduleSettings {
    export type DailyModeEnum = 'Everyday' | 'SpecificDays';
    export const DailyModeEnum = {
        Everyday: 'Everyday' as DailyModeEnum,
        SpecificDays: 'SpecificDays' as DailyModeEnum
    };
    export type SpecificDaysEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const SpecificDaysEnum = {
        Sunday: 'Sunday' as SpecificDaysEnum,
        Monday: 'Monday' as SpecificDaysEnum,
        Tuesday: 'Tuesday' as SpecificDaysEnum,
        Wednesday: 'Wednesday' as SpecificDaysEnum,
        Thursday: 'Thursday' as SpecificDaysEnum,
        Friday: 'Friday' as SpecificDaysEnum,
        Saturday: 'Saturday' as SpecificDaysEnum
    };
}