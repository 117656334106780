/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsGfsYearlyRetentionSettings { 
    /**
     * Number of years during which restore points must not be modified or deleted.
     */
    keepYearlyBackupsForYears: number;
    /**
     * Month when Veeam Backup & Replication must assign the yearly GFS flag to a full restore point.
     */
    useMonthlyFullBackupForTheFollowingMonth?: WindowsGfsYearlyRetentionSettings.UseMonthlyFullBackupForTheFollowingMonthEnum;
}
export namespace WindowsGfsYearlyRetentionSettings {
    export type UseMonthlyFullBackupForTheFollowingMonthEnum = 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec';
    export const UseMonthlyFullBackupForTheFollowingMonthEnum = {
        Jan: 'Jan' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Feb: 'Feb' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Mar: 'Mar' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Apr: 'Apr' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        May: 'May' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Jun: 'Jun' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Jul: 'Jul' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Aug: 'Aug' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Sep: 'Sep' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Oct: 'Oct' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Nov: 'Nov' as UseMonthlyFullBackupForTheFollowingMonthEnum,
        Dec: 'Dec' as UseMonthlyFullBackupForTheFollowingMonthEnum
    };
}