import React from 'react';
import { observer } from 'mobx-react-lite';
import { NotificationDialogsTextKeys, PortalSpinner } from '@veeam-vspc/components';
import { TokenManagerTextKeys, TransportTextKeys } from '@veeam-vspc/core';

import { useAppServices, useAppStore } from '../../../AppLayout/hooks';
import { LangContext } from '../../stores';
import { useLangsService } from '../../hooks';

export interface LangLayoutProps {
    children: React.ReactElement;
}

/**
 * @description Should be under ThemeLayout because of PortalSpinner
 */
export const LangLayout = observer(({ children }: LangLayoutProps) => {
    const appStore = useAppStore();
    const { langsService, notificationService, tokenManagerService, transportService } = useAppServices();
    const { langs, locale, hasLangs } = useLangsService(langsService, appStore.locale);
    const isLoading = locale !== appStore.locale;

    if (hasLangs && !isLoading) {
        notificationService.setTexts({
            [NotificationDialogsTextKeys.Ok]: langs.OK,
            [NotificationDialogsTextKeys.Yes]: langs.YES,
            [NotificationDialogsTextKeys.No]: langs.NO,
            [NotificationDialogsTextKeys.Cancel]: langs.CANCEL,
            [NotificationDialogsTextKeys.Close]: langs.CLOSE,
        });
        tokenManagerService.setTexts({
            [TokenManagerTextKeys.WebServerErrorTitle]: langs.CONNECTION_ERROR,
            [TokenManagerTextKeys.WebServerErrorMessage]: langs.WEB_SERVER_IS_NOT_RESPONDING,
            [TokenManagerTextKeys.AuthErrorTitle]: langs.SESSION_EXPIRED,
            [TokenManagerTextKeys.AuthErrorMessage]: langs.PLEASE_LOG_IN_AGAIN_TO_CONTINUE_USING,
            [TokenManagerTextKeys.AuthButtonMessage]: langs.LOG_IN,
        });
        transportService.config.setTexts({
            [TransportTextKeys.DownloadErrorMessage]: langs.FAILED_TO_DOWNLOAD_LOG_FILES,
            [TransportTextKeys.ErrorTitle]: langs.ERROR,
        });
    }

    return (
        <LangContext.Provider value={langs}>
            {hasLangs && children}
            {isLoading && <PortalSpinner/>}
        </LangContext.Provider>
    );
});
