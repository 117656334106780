/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BackupServerAgentJobSource } from './backupServerAgentJobSource';

export interface BackupServerAgentJob { 
    /**
     * UID assigned to an agent backup job.
     */
    readonly instanceUid?: string;
    /**
     * Number of job sessions.
     */
    readonly totalJobsCount?: number;
    /**
     * Number of successful job sessions.
     */
    readonly successJobsCount?: number;
    /**
     * Location of backup files.
     */
    readonly destination?: string;
    /**
     * Backup scope settings.
     */
    readonly source?: BackupServerAgentJobSource;
    /**
     * Status of the latest job session
     */
    readonly jobMode?: BackupServerAgentJob.JobModeEnum;
    /**
     * Type of a protected computer operating system.
     */
    readonly osType?: BackupServerAgentJob.OsTypeEnum;
    /**
     * License type of a backup job.
     */
    readonly licenseType?: BackupServerAgentJob.LicenseTypeEnum;
}
export namespace BackupServerAgentJob {
    export type JobModeEnum = 'Unknown' | 'ManagedByBackupServer' | 'ManagedByAgent';
    export const JobModeEnum = {
        Unknown: 'Unknown' as JobModeEnum,
        ManagedByBackupServer: 'ManagedByBackupServer' as JobModeEnum,
        ManagedByAgent: 'ManagedByAgent' as JobModeEnum
    };
    export type OsTypeEnum = 'Unknown' | 'Windows' | 'Linux' | 'Mac';
    export const OsTypeEnum = {
        Unknown: 'Unknown' as OsTypeEnum,
        Windows: 'Windows' as OsTypeEnum,
        Linux: 'Linux' as OsTypeEnum,
        Mac: 'Mac' as OsTypeEnum
    };
    export type LicenseTypeEnum = 'Unknown' | 'Server' | 'Workstation' | 'Limited';
    export const LicenseTypeEnum = {
        Unknown: 'Unknown' as LicenseTypeEnum,
        Server: 'Server' as LicenseTypeEnum,
        Workstation: 'Workstation' as LicenseTypeEnum,
        Limited: 'Limited' as LicenseTypeEnum
    };
}