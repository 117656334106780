import React from 'react';
import { StackView, STACK_DIRECTION, STACK_GAP, RadiobuttonKit } from '@veeam-vspc/components';

interface RadioGroupButtons<T> {
    value: T,
    label?: string,
    disabled?: boolean
    render?: () => React.ReactNode
}

interface RadioGroupProps<T> {
    value: T
    onChange: (T) => void
    buttons: RadioGroupButtons<T>[]
}

export const RadioGroup = <T,>({ buttons, value, onChange }: RadioGroupProps<T>) => {
    return (
        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
            {buttons.map((button, index) =>
                <RadiobuttonKit
                    key={index}
                    disabled={button.disabled}
                    checked={button.value === value}
                    value={button.value}
                    onChange={(value) => onChange(value)}
                >
                    {button.render ? button.render() : button.label}
                </RadiobuttonKit>
            )}
        </StackView>
    );
};
