/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NotificationAlarmsSettings } from './notificationAlarmsSettings';
import { NotificationBillingSettings } from './notificationBillingSettings';
import { NotificationDiscoverySettings } from './notificationDiscoverySettings';
import { NotificationLicenseSettings } from './notificationLicenseSettings';
import { SmtpSettings } from './smtpSettings';

export interface NotificationSettings { 
    /**
     * SMTP server settings.
     */
    smtp: SmtpSettings;
    /**
     * Billing notification settings.
     */
    billing: NotificationBillingSettings;
    /**
     * Settings for notifications about discovery results.
     */
    discovery: NotificationDiscoverySettings;
    /**
     * Alarm notification settings.
     */
    alarms: NotificationAlarmsSettings;
    /**
     * License notification settings.
     */
    license: NotificationLicenseSettings;
    /**
     * Level of notifications.
     */
    level?: NotificationSettings.LevelEnum;
    /**
     * Default email address from which notification messages must be sent.
     */
    defaultFrom?: string;
}
export namespace NotificationSettings {
    export type LevelEnum = 'Unknown' | 'disabled' | 'summary' | 'all';
    export const LevelEnum = {
        Unknown: 'Unknown' as LevelEnum,
        Disabled: 'disabled' as LevelEnum,
        Summary: 'summary' as LevelEnum,
        All: 'all' as LevelEnum
    };
}