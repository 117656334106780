/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsBackupAgent { 
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a management agent that is deployed along with Veeam backup agent.
     */
    readonly managementAgentUid?: string;
    /**
     * CBT driver status.
     */
    readonly cbtDriverStatus?: WindowsBackupAgent.CbtDriverStatusEnum;
}
export namespace WindowsBackupAgent {
    export type CbtDriverStatusEnum = 'Unknown' | 'NotInstalled' | 'Installed' | 'Error' | 'Installing' | 'Uninstalling';
    export const CbtDriverStatusEnum = {
        Unknown: 'Unknown' as CbtDriverStatusEnum,
        NotInstalled: 'NotInstalled' as CbtDriverStatusEnum,
        Installed: 'Installed' as CbtDriverStatusEnum,
        Error: 'Error' as CbtDriverStatusEnum,
        Installing: 'Installing' as CbtDriverStatusEnum,
        Uninstalling: 'Uninstalling' as CbtDriverStatusEnum
    };
}