/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LinuxActiveFullSettings { 
    /**
     * Type of periodicity.
     */
    scheduleType?: LinuxActiveFullSettings.ScheduleTypeEnum;
    /**
     * Day of the month.
     */
    dayOfMonth?: number;
    /**
     * Name of the week day.
     */
    weeklyOnDays?: Array<LinuxActiveFullSettings.WeeklyOnDaysEnum>;
}
export namespace LinuxActiveFullSettings {
    export type ScheduleTypeEnum = 'Unknown' | 'NotScheduled' | 'Monthly' | 'Weekly';
    export const ScheduleTypeEnum = {
        Unknown: 'Unknown' as ScheduleTypeEnum,
        NotScheduled: 'NotScheduled' as ScheduleTypeEnum,
        Monthly: 'Monthly' as ScheduleTypeEnum,
        Weekly: 'Weekly' as ScheduleTypeEnum
    };
    export type WeeklyOnDaysEnum = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
    export const WeeklyOnDaysEnum = {
        Sunday: 'Sunday' as WeeklyOnDaysEnum,
        Monday: 'Monday' as WeeklyOnDaysEnum,
        Tuesday: 'Tuesday' as WeeklyOnDaysEnum,
        Wednesday: 'Wednesday' as WeeklyOnDaysEnum,
        Thursday: 'Thursday' as WeeklyOnDaysEnum,
        Friday: 'Friday' as WeeklyOnDaysEnum,
        Saturday: 'Saturday' as WeeklyOnDaysEnum
    };
}