/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupAgentAssignedBackupPolicy { 
    /**
     * UID assigned to a backup policy configuration.
     */
    readonly configUid?: string;
    /**
     * UID assigned to a Veeam backup agent.
     */
    readonly backupAgentUid?: string;
    /**
     * UID assigned to a backup policy.
     */
    readonly backupPolicyUid?: string;
    /**
     * Status of the policy assignment.
     */
    readonly status?: BackupAgentAssignedBackupPolicy.StatusEnum;
    /**
     * Indicates whether a backup policy is custom.
     */
    readonly isCustom?: boolean;
    /**
     * Indicates whether a newer revision of a backup policy exists that has not been assigned to an agent.
     */
    readonly isOutOfDate?: boolean;
    /**
     * Message that is displayed in case backup policy assignment fails.
     */
    readonly backupPolicyFailureMessage?: string;
    /**
     * Revision of a backup policy.
     */
    readonly backupPolicyRevision?: number;
    /**
     * Date of the policy assignment. > If the backup policy is assigned to a Linux or Mac computer, the value of this property is `null`. 
     */
    readonly assignedDate?: Date;
    /**
     * Organization or user who assigned a backup policy. > If the backup policy is assigned to a Linux or Mac computer, the value of this property is `null`. 
     */
    readonly assignedBy?: string;
}
export namespace BackupAgentAssignedBackupPolicy {
    export type StatusEnum = 'Unknown' | 'Applying' | 'Applied' | 'Warning' | 'Failed' | 'Removing';
    export const StatusEnum = {
        Unknown: 'Unknown' as StatusEnum,
        Applying: 'Applying' as StatusEnum,
        Applied: 'Applied' as StatusEnum,
        Warning: 'Warning' as StatusEnum,
        Failed: 'Failed' as StatusEnum,
        Removing: 'Removing' as StatusEnum
    };
}