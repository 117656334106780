/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsPersonalFilesBackupAdvancedSettings { 
    /**
     * Type of personal file protection.
     */
    mode?: WindowsPersonalFilesBackupAdvancedSettings.ModeEnum;
    /**
     * Profile folders that must be included in the backup scope.
     */
    inclusions?: Array<WindowsPersonalFilesBackupAdvancedSettings.InclusionsEnum>;
    /**
     * Exclusions configured for personal file backup.
     */
    exclusions?: Array<WindowsPersonalFilesBackupAdvancedSettings.ExclusionsEnum>;
}
export namespace WindowsPersonalFilesBackupAdvancedSettings {
    export type ModeEnum = 'Unknown' | 'All' | 'Granular';
    export const ModeEnum = {
        Unknown: 'Unknown' as ModeEnum,
        All: 'All' as ModeEnum,
        Granular: 'Granular' as ModeEnum
    };
    export type InclusionsEnum = 'Unknown' | 'Desktop' | 'Documents' | 'Pictures' | 'Video' | 'Music' | 'Favorites' | 'Downloads' | 'ApplicationData' | 'OtherFilesAndFolders';
    export const InclusionsEnum = {
        Unknown: 'Unknown' as InclusionsEnum,
        Desktop: 'Desktop' as InclusionsEnum,
        Documents: 'Documents' as InclusionsEnum,
        Pictures: 'Pictures' as InclusionsEnum,
        Video: 'Video' as InclusionsEnum,
        Music: 'Music' as InclusionsEnum,
        Favorites: 'Favorites' as InclusionsEnum,
        Downloads: 'Downloads' as InclusionsEnum,
        ApplicationData: 'ApplicationData' as InclusionsEnum,
        OtherFilesAndFolders: 'OtherFilesAndFolders' as InclusionsEnum
    };
    export type ExclusionsEnum = 'Unknown' | 'RoamingUsers';
    export const ExclusionsEnum = {
        Unknown: 'Unknown' as ExclusionsEnum,
        RoamingUsers: 'RoamingUsers' as ExclusionsEnum
    };
}