/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerFileJobObjectSource { 
    /**
     * Path to a location of protected data.
     */
    readonly path?: string;
    /**
     * Type of protected object.
     */
    readonly type?: BackupServerFileJobObjectSource.TypeEnum;
    /**
     * Names and name masks of files that must be included into a backup scope.
     */
    readonly inclusionMasks?: Array<string>;
    /**
     * Names and name masks of files that must be excluded from a backup scope.
     */
    readonly exclusionMasks?: Array<string>;
}
export namespace BackupServerFileJobObjectSource {
    export type TypeEnum = 'Unknown' | 'File' | 'Directory';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        File: 'File' as TypeEnum,
        Directory: 'Directory' as TypeEnum
    };
}