import { Link } from '@veeam-vspc/components/src/index';
import React from 'react';
import { useLangs } from 'views/layouts/LangLayout/hooks';

export const SelectUserLink = ({ openPanel }: { openPanel: () => void}) => {
    const langs = useLangs();

    return (
        <Link onClick={openPanel}>
            {langs.SELECT}
        </Link>
    );
};
