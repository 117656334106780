import React, { useRef } from 'react';
import type { GridStore, WizardStepData } from '@veeam-vspc/components';
import { Grid, Search, Toolbar, TOOLBAR_ITEM_TYPE } from '@veeam-vspc/components';

import { ToolbarFirst } from 'features/RolesAndUsers/wizards/AdminUser/components/Account/components/AccountGrid/components/ToolbarFirst';
import type { AdminUserModel } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/admin-user-model';
import type { UserType } from 'features/RolesAndUsers/wizards/AdminUser/enums/user-type';
import type { ActiveDirectoryAccount } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/active-directory-account';
import type { BaseResponse } from 'interfaces/base-response';
import type { AccountFilterModel } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/account-filter-model';
import type { DiscoverySamAccountRequest } from 'features/RolesAndUsers/wizards/AdminUser/interfaces/discovery-sam-account-request';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppServices } from 'views/layouts/AppLayout/hooks';

export const AccountGrid = ({ data, onDataChange }: WizardStepData<AdminUserModel>) => {
    const langs = useLangs();
    const { transportService } = useAppServices();
    const api = useRef<GridStore<ActiveDirectoryAccount, any, AccountFilterModel>>();

    return (
        <Grid
            columns={[
                { field: 'displayName', title: langs.ACCOUNT },
                { field: 'description', title: langs.DESCRIPTION },
            ]}
            initialState={{
                filters: {
                    userType: data.model.type,
                    samAccountName: '',
                },
                selected: data.model.sId ? [ data.model ] as ActiveDirectoryAccount[] : undefined,
            }}
            data={requestParams => transportService
                .request<DiscoverySamAccountRequest, BaseResponse<ActiveDirectoryAccount>>('AccountManagement/DiscoverySamAccount', {
                    ...requestParams,
                    discoveryModel: {
                        domain: data.domain,
                        password: data.password,
                        userName: data.userName,
                        samAccountName: requestParams.filter.samAccountName,
                        type: requestParams.filter.userType as UserType,
                    },
                })}
            toggleable={false}
            selection={{
                field: 'sId',
                multiple: false,
                checkbox: false,
            }}
            paginationLimit={Number.MAX_SAFE_INTEGER}
            onSelectionChange={(selected) => {
                const selectedAccount = selected[0];

                data.model = {
                    sId: selectedAccount?.sId,
                    type: api.current.filters.userType,
                    contextType: selectedAccount?.contextType,
                    name: selectedAccount?.name,
                    description: selectedAccount?.description,
                    roleType: data.model.roleType,
                } as ActiveDirectoryAccount;

                onDataChange(data);
            }}
            toolbars={[
                () => <ToolbarFirst />,
                () => (
                    <Toolbar
                        items={[
                            {
                                type: TOOLBAR_ITEM_TYPE.customControl,
                                render: () =>
                                    <Search
                                        name='samAccountName'
                                        placeholder={langs.ACCOUNT}
                                        hasSearchButton={false}
                                    />,
                            },
                        ]}
                    />
                ),
            ]}
            api={api}
        />
    );
};

