/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AggregatedUsage { 
    /**
     * Counter value.
     */
    readonly value?: number;
    /**
     * Counter type.
     */
    readonly type?: AggregatedUsage.TypeEnum;
}
export namespace AggregatedUsage {
    export type TypeEnum = 'Unknown' | 'VmCloudBackups' | 'VbrCloudBackupsDataTransferOut' | 'VbrCloudBackupsDataTransferIn' | 'ServerCloudBackups' | 'WorkstationCloudBackups' | 'AgentCloudBackupDataTransferOut' | 'AgentCloudBackupDataTransferIn' | 'CloudRepositoryUsageByServerAgent' | 'CloudRepositoryUsageByWorkstationAgent' | 'CloudRepositoryUsageByVm' | 'CloudRepositoryUsageByUnknownFiles' | 'ManagedVms' | 'ManagedCloudVms' | 'ManagedServerAgents' | 'ManagedWorkstationAgents' | 'ManagedAgentsWindowsDesktopOS' | 'ManagedAgentsWindowsServerOS' | 'ManagedAgentsLinuxOS' | 'ManagedAgentsMacOS' | 'VmCloudReplicas' | 'VbrCloudReplicaDataTransferOut' | 'VbrCloudReplicaDataTransferIn' | 'VmCloudReplicaStorageUsage' | 'VmCloudReplicaComputeTime' | 'FileShareBackupSize' | 'FileShareArchiveSize' | 'FileShareSourceSize' | 'CloudInsiderProtectionBackupSize' | 'CloudCapacityTierBackupSize' | 'CloudArchiveTierBackupSize' | 'CloudPerfomanceTierBackupSize';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        VmCloudBackups: 'VmCloudBackups' as TypeEnum,
        VbrCloudBackupsDataTransferOut: 'VbrCloudBackupsDataTransferOut' as TypeEnum,
        VbrCloudBackupsDataTransferIn: 'VbrCloudBackupsDataTransferIn' as TypeEnum,
        ServerCloudBackups: 'ServerCloudBackups' as TypeEnum,
        WorkstationCloudBackups: 'WorkstationCloudBackups' as TypeEnum,
        AgentCloudBackupDataTransferOut: 'AgentCloudBackupDataTransferOut' as TypeEnum,
        AgentCloudBackupDataTransferIn: 'AgentCloudBackupDataTransferIn' as TypeEnum,
        CloudRepositoryUsageByServerAgent: 'CloudRepositoryUsageByServerAgent' as TypeEnum,
        CloudRepositoryUsageByWorkstationAgent: 'CloudRepositoryUsageByWorkstationAgent' as TypeEnum,
        CloudRepositoryUsageByVm: 'CloudRepositoryUsageByVm' as TypeEnum,
        CloudRepositoryUsageByUnknownFiles: 'CloudRepositoryUsageByUnknownFiles' as TypeEnum,
        ManagedVms: 'ManagedVms' as TypeEnum,
        ManagedCloudVms: 'ManagedCloudVms' as TypeEnum,
        ManagedServerAgents: 'ManagedServerAgents' as TypeEnum,
        ManagedWorkstationAgents: 'ManagedWorkstationAgents' as TypeEnum,
        ManagedAgentsWindowsDesktopOS: 'ManagedAgentsWindowsDesktopOS' as TypeEnum,
        ManagedAgentsWindowsServerOS: 'ManagedAgentsWindowsServerOS' as TypeEnum,
        ManagedAgentsLinuxOS: 'ManagedAgentsLinuxOS' as TypeEnum,
        ManagedAgentsMacOS: 'ManagedAgentsMacOS' as TypeEnum,
        VmCloudReplicas: 'VmCloudReplicas' as TypeEnum,
        VbrCloudReplicaDataTransferOut: 'VbrCloudReplicaDataTransferOut' as TypeEnum,
        VbrCloudReplicaDataTransferIn: 'VbrCloudReplicaDataTransferIn' as TypeEnum,
        VmCloudReplicaStorageUsage: 'VmCloudReplicaStorageUsage' as TypeEnum,
        VmCloudReplicaComputeTime: 'VmCloudReplicaComputeTime' as TypeEnum,
        FileShareBackupSize: 'FileShareBackupSize' as TypeEnum,
        FileShareArchiveSize: 'FileShareArchiveSize' as TypeEnum,
        FileShareSourceSize: 'FileShareSourceSize' as TypeEnum,
        CloudInsiderProtectionBackupSize: 'CloudInsiderProtectionBackupSize' as TypeEnum,
        CloudCapacityTierBackupSize: 'CloudCapacityTierBackupSize' as TypeEnum,
        CloudArchiveTierBackupSize: 'CloudArchiveTierBackupSize' as TypeEnum,
        CloudPerfomanceTierBackupSize: 'CloudPerfomanceTierBackupSize' as TypeEnum
    };
}