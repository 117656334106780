/**
 * Copyright © Veeam Software Group GmbH
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {
    debounce,
    makeTwoDigit,
    makePercentFromNumber,
    deepEqual,
    deepCopy,
    safelyParseJson,
    delay,
    getParamValueFromUrl,
    prepareUrl,
    getDevelopmentHeaders,
    addParamsToQuery,
    download,
    AppStorage,
    generateGuid,
    generateRandomValue,
    htmlEncode,
    htmlDecode,
    getStrBetween,
    observe,
    copyTextToClipboard,
} from '@veeam-vspc/core';

import { HeatMap } from 'features/HeatMap';
import { AdminUser } from 'features/RolesAndUsers/wizards/AdminUser';
import { CompanyUser } from 'features/RolesAndUsers/wizards/CompanyUser';
import { SPUser } from 'features/RolesAndUsers/wizards/SPUser';
import { AdminSSORule } from 'features/RolesAndUsers/wizards/AdminSSORule';
import { SPSSORule } from 'features/RolesAndUsers/wizards/SPSSORule';
import { CompanySSORule } from 'features/RolesAndUsers/wizards/CompanySSORule';
import { CoreModule } from 'core/core-module';
import { sanitizeComplexObject } from 'core/utils';
import { AppLayout } from 'views/layouts/AppLayout';

import { getProductTheme, calcRoundingPercents } from '../../../utils';

export class ReactToExtService {

    private components: Record<string, (props: any) => JSX.Element> = {
        AppLayout,
        //tabs
        HeatMap,

        // wizards
        AdminUser,
        CompanyUser,
        SPUser,
        AdminSSORule,
        SPSSORule,
        CompanySSORule,
    };

    private utils: Record<string, any> = {
        debounce,
        makeTwoDigit,
        makePercentFromNumber,
        deepEqual,
        deepCopy,
        safelyParseJson,
        delay,
        getParamValueFromUrl,
        prepareUrl,
        getDevelopmentHeaders,
        addParamsToQuery,
        download,
        AppStorage,
        generateGuid,
        generateRandomValue,
        htmlEncode,
        htmlDecode,
        getStrBetween,
        observe,
        copyTextToClipboard,
        getProductTheme,
        calcRoundingPercents,
        sanitizeComplexObject,
    };

    constructor(private readonly coreModule: CoreModule) {}

    renderTo(componentName: string, container: HTMLElement, props: Record<string, any> = {}): void {
        ReactDOM.render(
            React.createElement(this.getComponent(componentName), props),
            container
        );
    }

    unmountFrom(container: HTMLElement): void {
        ReactDOM.unmountComponentAtNode(container);
    }

    getComponent(name: string): (props: any) => JSX.Element {
        if (!this.components[name]) {
            throw Error(`Failed to find ${name} component`);
        }

        return this.components[name];
    }

    getService(name: string): any {
        return this.coreModule.getServices()[name];
    }

    getUtils(name: string): any {
        return this.utils[name];
    }

}
