/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsOracleAccountSettings { 
    /**
     * Type of the account used to access Oracle database.
     */
    accountType?: WindowsOracleAccountSettings.AccountTypeEnum;
    /**
     * User name.
     */
    username: string;
    /**
     * Password.
     */
    password?: string;
}
export namespace WindowsOracleAccountSettings {
    export type AccountTypeEnum = 'Oracle' | 'Windows';
    export const AccountTypeEnum = {
        Oracle: 'Oracle' as AccountTypeEnum,
        Windows: 'Windows' as AccountTypeEnum
    };
}