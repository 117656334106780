import React, { useRef, useState } from 'react';
import type { GridStore, RequestParams } from '@veeam-vspc/components';
import {
    Grid,
    Search,
    Toolbar,
    TOOLBAR_ITEM_TYPE,
    ACTION_VIEW,
} from '@veeam-vspc/components';

import { SidePanelForm } from 'components/layouts/SidePanelForm';
import type { BaseResponse } from 'interfaces/base-response';
import type { CompanyRow } from 'features/RolesAndUsers/wizards/SPSSORule/interfaces/company-row';
import type { CompanyFilterModel } from 'features/RolesAndUsers/wizards/SPSSORule/interfaces/company-filter-model';
import type { ManagedCompaniesProps } from 'features/RolesAndUsers/wizards/SPSSORule/interfaces/managed-companies-props';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppServices } from 'views/layouts/AppLayout/hooks';

export const ManagedCompaniesPanel = (props: ManagedCompaniesProps) => {
    const langs = useLangs();
    const { transportService } = useAppServices();
    const { hidePanel, data, onDataChange } = props;
    const api = useRef<GridStore<CompanyRow, any, CompanyFilterModel>>();
    const [isApplyDisabled, setApplyToDisabledState] = useState(data.model.managedCompaniesUids?.length === 0);

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={langs.COMPANIES}
            description={langs.SELECT_COMPANIES_ACCESSIBLE_TO_THIS_USER}
            actions={[
                {
                    text: langs.APPLY,
                    onClick: () => {
                        data.model.managedCompaniesUids = api.current.selected.map(({ companyInstanceUid }) => companyInstanceUid);

                        onDataChange(data);

                        hidePanel();
                    },
                    disabled: isApplyDisabled,
                },
                {
                    text: langs.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <Grid
                initialState={{
                    selected: data.model.managedCompaniesUids?.map(companyInstanceUid => ({ companyInstanceUid })),
                }}
                defaultColumnWidth={100}
                columns={[
                    { field: 'name', title: langs.COMPANY },
                    { field: 'siteName', title: langs.SITE },
                ]}
                data={(requestParams: RequestParams<CompanyFilterModel>) => transportService
                    .request<typeof requestParams, BaseResponse<CompanyRow>>('React/GetCompanies', requestParams)}
                toggleable={false}
                selection={{
                    field: 'companyInstanceUid',
                    checkbox: true,
                    multiple: true,
                }}
                onSelectionChange={(selected) => {
                    if (selected.length === 0) {
                        setApplyToDisabledState(true);
                    } else {
                        setApplyToDisabledState(false);
                    }
                }}
                paginationLimit={Number.MAX_SAFE_INTEGER}
                toolbars={[
                    () => (
                        <Toolbar
                            items={[
                                {
                                    type: TOOLBAR_ITEM_TYPE.customControl,
                                    render: () =>
                                        <Search
                                            name='name'
                                            placeholder={langs.COMPANY_NAME}
                                            hasSearchButton={false}
                                        />,
                                },
                            ]}
                        />
                    ),
                ]}
                api={api}
            />
        </SidePanelForm>
    );
};
