/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IdentityProvider } from './identityProvider';
import { IdentityProviderAttributeMapping } from './identityProviderAttributeMapping';
import { IdentityProviderClaimMatchRule } from './identityProviderClaimMatchRule';

/**
 * [TBD]
 */
export interface IdentityProviderRoleMappingRule {
    /**
     * [TBD]
     */
    readonly instanceUid?: string;
    /**
     * [TBD] Rule name
     */
    name: string;
    /**
     * [TBD] Rule description
     */
    description?: string;
    role: IdentityProviderRoleMappingRule.RoleEnum;
    /**
     * [TBD] Is enabled
     */
    enabled?: boolean;
    /**
     * [TBD] MUST be passed for PortalOperator, PortalReadonlyOperator, ResellerOperator, ResellerUser  or ResellerAdministrator.
     */
    managedCompaniesUids?: Array<string>;
    /**
     * [TBD] Manage all companies (include newly added companies). Overrides managedCompaniesUids setting.
     */
    manageAllCompanies?: boolean;
    /**
     * [TBD] Company mapping claim type. For successfully mapping specified claim must be equal to CompanyName + CompanyAlias.
     */
    organizationMappingSourceClaimType: string;
    /**
     * [TBD] Type of claim that contains names of user's assigned locations in follow format: 'Location1;Location2'. This property MAY be passed for CompanyLocationUser, CompanyLocationAdministrator, CompanySubtenant.  Otherwise user will be assigned to the first company's location.
     */
    locationsMappingSourceClaimType?: string;
    /**
     * [TBD] Additional mappings. They must all match for successful user login.
     */
    additionalMappings?: Array<IdentityProviderClaimMatchRule>;
    /**
     * [TBD]
     */
    attributeMappings?: Array<IdentityProviderAttributeMapping>;
    /**
     * [TBD] Basic parent IdP information
     */
    readonly providerInfo?: IdentityProvider;
}
export namespace IdentityProviderRoleMappingRule {
    export type RoleEnum = 'Unknown' | 'PortalAdministrator' | 'PortalOperator' | 'PortalReadonlyOperator' | 'CompanyLocationUser' | 'CompanyLocationAdministrator' | 'CompanyOwner' | 'CompanyAdministrator' | 'CompanyInvoiceAuditor' | 'ResellerOwner' | 'ResellerOperator' | 'ResellerUser' | 'ResellerInvoiceAuditor' | 'ResellerAdministrator';
    export const RoleEnum = {
        Unknown: 'Unknown' as RoleEnum,
        PortalAdministrator: 'PortalAdministrator' as RoleEnum,
        PortalOperator: 'PortalOperator' as RoleEnum,
        PortalReadonlyOperator: 'PortalReadonlyOperator' as RoleEnum,
        CompanyLocationUser: 'CompanyLocationUser' as RoleEnum,
        CompanyLocationAdministrator: 'CompanyLocationAdministrator' as RoleEnum,
        CompanyOwner: 'CompanyOwner' as RoleEnum,
        CompanyAdministrator: 'CompanyAdministrator' as RoleEnum,
        CompanyInvoiceAuditor: 'CompanyInvoiceAuditor' as RoleEnum,
        ResellerOwner: 'ResellerOwner' as RoleEnum,
        ResellerOperator: 'ResellerOperator' as RoleEnum,
        ResellerUser: 'ResellerUser' as RoleEnum,
        ResellerInvoiceAuditor: 'ResellerInvoiceAuditor' as RoleEnum,
        ResellerAdministrator: 'ResellerAdministrator' as RoleEnum
    };
}
