/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerFileShareJobObjectLastSession { 
    /**
     * Status of a job.
     */
    readonly backupStatus?: BackupServerFileShareJobObjectLastSession.BackupStatusEnum;
    /**
     * Total number of files in all sources.
     */
    readonly sourceFilesCount?: number;
    /**
     * Number of processed files.
     */
    readonly changedFilesCount?: number;
    /**
     * Number of skipped files.
     */
    readonly skippedFilesCount?: number;
    /**
     * Number of backed up files.
     */
    readonly backedUpFilesCount?: number;
    /**
     * Total size of backed up file share data, in bytes.
     */
    readonly transferredSize?: number;
    /**
     * Total size of all source files, in bytes.
     */
    readonly sourceSize?: number;
    /**
     * Time taken to complete the latest job session, in seconds.
     */
    readonly duration?: number;
    /**
     * Message that is displayed after a job session finishes.
     */
    readonly messages?: Array<string>;
}
export namespace BackupServerFileShareJobObjectLastSession {
    export type BackupStatusEnum = 'Unknown' | 'Running' | 'Success' | 'Warning' | 'Failed';
    export const BackupStatusEnum = {
        Unknown: 'Unknown' as BackupStatusEnum,
        Running: 'Running' as BackupStatusEnum,
        Success: 'Success' as BackupStatusEnum,
        Warning: 'Warning' as BackupStatusEnum,
        Failed: 'Failed' as BackupStatusEnum
    };
}