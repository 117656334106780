/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BackupServerFileShareJob { 
    /**
     * UID assigned to a file share backup job.
     */
    readonly instanceUid?: string;
    /**
     * UID assigned to a target backup repository.
     */
    readonly targetRepositoryUid?: string;
    /**
     * UID assigned to an archive repository.
     */
    readonly archiveRepositoryUid?: string;
    /**
     * Duration of file retention.
     */
    readonly retention?: number;
    /**
     * Measurement units of file retention duration.
     */
    readonly retentionUnit?: BackupServerFileShareJob.RetentionUnitEnum;
    /**
     * Indicates whether long-term file retention is enabled.
     */
    readonly isArchiveRetentionEnabled?: boolean;
    /**
     * Duration of long-term file retention.
     */
    readonly archiveRetention?: number;
    /**
     * Measurement units of long-term file retention duration.
     */
    readonly archiveRetentionUnit?: BackupServerFileShareJob.ArchiveRetentionUnitEnum;
}
export namespace BackupServerFileShareJob {
    export type RetentionUnitEnum = 'Minutes' | 'Hours' | 'Days' | 'Months' | 'Years';
    export const RetentionUnitEnum = {
        Minutes: 'Minutes' as RetentionUnitEnum,
        Hours: 'Hours' as RetentionUnitEnum,
        Days: 'Days' as RetentionUnitEnum,
        Months: 'Months' as RetentionUnitEnum,
        Years: 'Years' as RetentionUnitEnum
    };
    export type ArchiveRetentionUnitEnum = 'Minutes' | 'Hours' | 'Days' | 'Months' | 'Years';
    export const ArchiveRetentionUnitEnum = {
        Minutes: 'Minutes' as ArchiveRetentionUnitEnum,
        Hours: 'Hours' as ArchiveRetentionUnitEnum,
        Days: 'Days' as ArchiveRetentionUnitEnum,
        Months: 'Months' as ArchiveRetentionUnitEnum,
        Years: 'Years' as ArchiveRetentionUnitEnum
    };
}