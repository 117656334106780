import { observable } from 'mobx';
import { DayJobsHeatMap } from '../interfaces/day-jobs-heat-map';
import { HeatMapDay } from './HeatMapDay';

export class HeatMapDays {
    @observable days: HeatMapDay[] = [];

    constructor(days: DayJobsHeatMap[]) {
        days.forEach(dayData => this.days.push(new HeatMapDay(dayData)));
    }

    findById(id: string) {
        return this.days.find(day => day.id === id);
    }
}
