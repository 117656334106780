import React from 'react';
import type {
    WizardStep,
} from '@veeam-vspc/components';
import {
    Combobox,
    CONTROL_SIZE,
    Form,
    FormValidator,
    isValid,
    STACK_DIRECTION,
    STACK_GAP,
    StackView,
} from '@veeam-vspc/components';
import { PortalUserRoles } from 'core/entries/portal-user/enums';
import { StepLayout } from 'components/layouts/StepLayout';
import type { SPUserModel } from '../../interfaces/sp-user-model';
import type { WizardStepData } from '@veeam-vspc/components/src/index';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { getResellerDocUrl } from '../../../Base/helpers/getResellerDocUrl';
import { NoteBar } from 'components/layouts/NoteBar';

export const stepValidate = (data: SPUserModel) => {
    const validator = new FormValidator(data);

    validator.validate('userRole').number();

    return validator.result();
};

export const Role: React.FC<WizardStepData<SPUserModel>> = ({ data, validationState, onDataChange }) => {
    const langs = useLangs();

    return (
        <Form
            value={data}
            validate={stepValidate}
            validationState={validationState}
            onChange={onDataChange}
        >
            <StepLayout
                title={langs.ROLE}
                description={langs.SPECIFY_A_ROLE_TO_ASSIGN}
            >
                <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                    <Combobox
                        name='userRole'
                        size={CONTROL_SIZE.m}
                        data={[
                            { value: PortalUserRoles.ServiceProviderAdministrator, text: langs.SERVICE_PROVIDER_ADMINISTRATOR },
                            { value: PortalUserRoles.ServiceProviderOperator, text: langs.SERVICE_PROVIDER_OPERATOR },
                            { value: PortalUserRoles.ServiceProviderUser, text: langs.SERVICE_PROVIDER_USER },
                            { value: PortalUserRoles.ServiceProviderInvoiceAuditor, text: langs.SERVICE_PROVIDER_INVOICE_AUDITOR },
                        ]}
                        valueGetter={item => item.value}
                        textGetter={item => item.text}
                        label={langs.ROLE}
                        inlineLabel
                    />
                    <NoteBar>
                        {data.userRole === PortalUserRoles.ServiceProviderAdministrator &&
                            <p>{langs.SERVICE_PROVIDER_ADMINISTRATOR_HAS_ACCESS}</p>
                        }
                        {data.userRole === PortalUserRoles.ServiceProviderOperator &&
                            <p>{langs.SERVICE_PROVIDER_OPERATOR_CAN_PERFORM}</p>
                        }
                        {data.userRole === PortalUserRoles.ServiceProviderUser &&
                            <p>{langs.SERVICE_PROVIDER_USER_HAS_ACCESS}</p>
                        }
                        {data.userRole === PortalUserRoles.ServiceProviderInvoiceAuditor &&
                            <p>{langs.SERVICE_PROVIDER_INVOICE_AUDITOR_HAS_ACCESS}</p>
                        }
                        <p>
                            {langs.CLICK} <a
                                href={getResellerDocUrl()}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {langs.HERE}
                            </a> {langs.TO_GET_DETAILED_INFORMATION}
                        </p>
                    </NoteBar>
                </StackView>
            </StepLayout>
        </Form>
    );
};

export const getRoleStep = (title: string): WizardStep<SPUserModel> => ({
    title,
    isHidden: ({ data }) => data.selfEdit || data.userRole === PortalUserRoles.ServiceProviderGlobalAdministrator,
    validate: ({ data }) => isValid(stepValidate, data),
    render: data => <Role {...data} />,
});
