/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CloudTenant { 
    /**
     * UID assigned to a Veeam Cloud Connect tenant.
     */
    readonly instanceUid?: string;
    /**
     * Name of a tenant account.
     */
    readonly name?: string;
    /**
     * Description of a tenant account.
     */
    readonly description?: string;
    /**
     * Password of a tenant account.
     */
    readonly hashedPassword?: string;
    /**
     * Type of a tenant account.
     */
    readonly type?: CloudTenant.TypeEnum;
    /**
     * UID assigned to a Veeam Cloud Connect server.
     */
    readonly backupServerUid?: string;
    /**
     * The last time when a tenant was active.
     */
    lastActive?: Date;
    /**
     * Type of gateway selection.
     */
    readonly gatewaySelectionType?: CloudTenant.GatewaySelectionTypeEnum;
    /**
     * Indicates whether a tenant account is enabled.
     */
    readonly isEnabled?: boolean;
    /**
     * Indicates whether a tenant account must be disabled automatically.
     */
    readonly isLeaseExpirationEnabled?: boolean;
    /**
     * Date and time when a company account must be disabled.
     */
    readonly leaseExpirationDate?: Date;
    /**
     * Indicates whether deleted backup file protection is enabled.
     */
    readonly isBackupProtectionEnabled?: boolean;
    /**
     * Number of days during which deleted backup files must be kept in the recycle bin on the Veeam Cloud Connect server.
     */
    readonly backupProtectionPeriod?: number;
    /**
     * Indicates whether a tenant is allowed to fail over to a cloud gateway that is not added to a selected cloud gateway pool.
     */
    readonly isGatewayFailoverEnabled?: boolean;
    /**
     * Collection of UIDs assigned to gateway pools that are allocated to a company. > If the collection is empty, company will automatically use a standalone gateway. 
     */
    readonly gatewayPoolsUids?: Array<string>;
    /**
     * Indicates whether incoming network traffic that will be accepted from a tenant is limited.
     */
    readonly isThrottlingEnabled?: boolean;
    /**
     * Maximum incoming network traffic bandwidth that will be accepted from a tenant. > If throttling is disabled, the property value is `null`. 
     */
    readonly throttlingValue?: number;
    /**
     * Measurement units for incoming network traffic accepted from a company. > If throttling is disabled, the property value is `null`. 
     */
    readonly throttlingUnit?: CloudTenant.ThrottlingUnitEnum;
    /**
     * Maximum number of concurrent tasks available to a tenant.
     */
    readonly maxConcurrentTask?: number;
    /**
     * Indicates whether cloud backup resources are allocated to a tenant.
     */
    readonly isBackupResourcesEnabled?: boolean;
    /**
     * Indicates whether cloud replication resources are allocated to a tenant.
     */
    readonly isNativeReplicationResourcesEnabled?: boolean;
    /**
     * Indicates whether Organization vDCs are allocated to a tenant as cloud hosts.
     */
    readonly isVcdReplicationResourcesEnabled?: boolean;
}
export namespace CloudTenant {
    export type TypeEnum = 'Unknown' | 'General' | 'VCD';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        General: 'General' as TypeEnum,
        VCD: 'VCD' as TypeEnum
    };
    export type GatewaySelectionTypeEnum = 'Unknown' | 'StandaloneGateways' | 'GatewayPool';
    export const GatewaySelectionTypeEnum = {
        Unknown: 'Unknown' as GatewaySelectionTypeEnum,
        StandaloneGateways: 'StandaloneGateways' as GatewaySelectionTypeEnum,
        GatewayPool: 'GatewayPool' as GatewaySelectionTypeEnum
    };
    export type ThrottlingUnitEnum = 'MbitPerSec' | 'KbytePerSec' | 'MbytePerSec';
    export const ThrottlingUnitEnum = {
        MbitPerSec: 'MbitPerSec' as ThrottlingUnitEnum,
        KbytePerSec: 'KbytePerSec' as ThrottlingUnitEnum,
        MbytePerSec: 'MbytePerSec' as ThrottlingUnitEnum
    };
}