import React from 'react';
import {
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { AccountGrid } from 'features/RolesAndUsers/wizards/AdminUser/components/Account/components/AccountGrid/AccountGrid';
import { AdminUserModel } from '../../interfaces/admin-user-model';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { core } from 'core/core-module';

const Account = (stepData: WizardStepData<AdminUserModel>) => {
    const langs = useLangs();

    if (stepData.validationState.isForce()) {
        stepData.validationState.markAsHandledForce();

        if (!stepData.data.model.sId) {
            core.notificationService.error(langs.ERROR, [langs.ACCOUNT_IS_NOT_SET]);
        }
    }

    return (
        <StepLayout
            title={langs.ACCOUNT}
            description={langs.SPECIFY_ACCOUNT_TYPE}
        >
            <AccountGrid {...stepData} />
        </StepLayout>
    );
};

export const getAccountStep = (title: string): WizardStep<AdminUserModel> => ({
    title,
    isHidden: ({ isEdit }) => isEdit,
    validate: ({ data }) => !!data.model.sId,
    render: data => <Account {...data} />,
});
