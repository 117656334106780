/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WindowsGfsMonthlyRetentionSettings { 
    /**
     * Number of months during which restore points must not be modified or deleted.
     */
    keepMonthlyBackupsForMonths: number;
    /**
     * Week when Veeam Backup & Replication must assign the monthly GFS flag to a full restore point.
     */
    useWeeklyFullBackupForTheFollowingWeekOfMonth?: WindowsGfsMonthlyRetentionSettings.UseWeeklyFullBackupForTheFollowingWeekOfMonthEnum;
}
export namespace WindowsGfsMonthlyRetentionSettings {
    export type UseWeeklyFullBackupForTheFollowingWeekOfMonthEnum = 'First' | 'Last';
    export const UseWeeklyFullBackupForTheFollowingWeekOfMonthEnum = {
        First: 'First' as UseWeeklyFullBackupForTheFollowingWeekOfMonthEnum,
        Last: 'Last' as UseWeeklyFullBackupForTheFollowingWeekOfMonthEnum
    };
}