/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriptionPlanFileShareBackup { 
    /**
     * Charge rate for one GB or TB of backup repository space consumed by file share backups.
     */
    fileShareBackupUsedSpacePrice?: number;
    /**
     * Measurement units of backup repository space consumed by file share backups.
     */
    fileShareBackupUsedSpaceUnits?: SubscriptionPlanFileShareBackup.FileShareBackupUsedSpaceUnitsEnum;
    /**
     * Amount of backup repository space that can be consumed by file share backups for free.
     */
    freeFileShareBackupUsedSpace?: number;
    /**
     * Measurement units of backup repository space that can be consumed by file share backups for free.
     */
    freeFileShareBackupUsedSpaceUnits?: SubscriptionPlanFileShareBackup.FreeFileShareBackupUsedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of archive repository space consumed by file share backups.
     */
    fileShareArchiveUsedSpacePrice?: number;
    /**
     * Measurement units of archive repository space consumed by file share backups.
     */
    fileShareArchiveUsedSpaceUnits?: SubscriptionPlanFileShareBackup.FileShareArchiveUsedSpaceUnitsEnum;
    /**
     * Amount of archive repository space that can be consumed by file share backups for free.
     */
    freeFileShareArchiveUsedSpace?: number;
    /**
     * Measurement units of archive repository space that can be consumed by file share backups for free.
     */
    freeFileShareArchiveUsedSpaceUnits?: SubscriptionPlanFileShareBackup.FreeFileShareArchiveUsedSpaceUnitsEnum;
    /**
     * Charge rate for one GB or TB of source data.
     */
    sourceAmountOfDataPrice?: number;
    /**
     * Measurement units of source data.
     */
    sourceAmountOfDataUnits?: SubscriptionPlanFileShareBackup.SourceAmountOfDataUnitsEnum;
    /**
     * Amount of source data that is processed for free.
     */
    freeSourceAmountOfData?: number;
    /**
     * Measurement units of source data that is processed for free.
     */
    freeSourceAmountOfDataUnits?: SubscriptionPlanFileShareBackup.FreeSourceAmountOfDataUnitsEnum;
}
export namespace SubscriptionPlanFileShareBackup {
    export type FileShareBackupUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const FileShareBackupUsedSpaceUnitsEnum = {
        GB: 'GB' as FileShareBackupUsedSpaceUnitsEnum,
        TB: 'TB' as FileShareBackupUsedSpaceUnitsEnum
    };
    export type FreeFileShareBackupUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const FreeFileShareBackupUsedSpaceUnitsEnum = {
        GB: 'GB' as FreeFileShareBackupUsedSpaceUnitsEnum,
        TB: 'TB' as FreeFileShareBackupUsedSpaceUnitsEnum
    };
    export type FileShareArchiveUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const FileShareArchiveUsedSpaceUnitsEnum = {
        GB: 'GB' as FileShareArchiveUsedSpaceUnitsEnum,
        TB: 'TB' as FileShareArchiveUsedSpaceUnitsEnum
    };
    export type FreeFileShareArchiveUsedSpaceUnitsEnum = 'GB' | 'TB';
    export const FreeFileShareArchiveUsedSpaceUnitsEnum = {
        GB: 'GB' as FreeFileShareArchiveUsedSpaceUnitsEnum,
        TB: 'TB' as FreeFileShareArchiveUsedSpaceUnitsEnum
    };
    export type SourceAmountOfDataUnitsEnum = 'GB' | 'TB';
    export const SourceAmountOfDataUnitsEnum = {
        GB: 'GB' as SourceAmountOfDataUnitsEnum,
        TB: 'TB' as SourceAmountOfDataUnitsEnum
    };
    export type FreeSourceAmountOfDataUnitsEnum = 'GB' | 'TB';
    export const FreeSourceAmountOfDataUnitsEnum = {
        GB: 'GB' as FreeSourceAmountOfDataUnitsEnum,
        TB: 'TB' as FreeSourceAmountOfDataUnitsEnum
    };
}