import React, { useEffect } from 'react';
import {
    WizardStep,
    WizardStepData,
} from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { CompanyUserModel } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/company-user-model';
import { CompanyGrid } from './components/CompanyGrid';
import { UserMfaStatuses } from 'features/RolesAndUsers/wizards/Base/components/MultiFactorAuthentication/user-mfa-statuses';
import type { Repository } from 'features/RolesAndUsers/wizards/CompanyUser/interfaces/repository';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { useAppServices } from 'views/layouts/AppLayout/hooks';

const Company = (stepData: WizardStepData<CompanyUserModel>) => {
    const langs = useLangs();
    const { transportService, notificationService } = useAppServices();
    const { data, onDataChange } = stepData;

    if (stepData.validationState.isForce()) {
        stepData.validationState.markAsHandledForce();

        if (!stepData.data.companyID) {
            notificationService.error(langs.ERROR, [langs.COMPANY_IS_NOT_SET]);
        }
    }

    useEffect(() => {
        async function checkMfaPolicy() {
            const { data: isMfaPolicyEnforced }: any = await transportService.request('User/IsEnforcedMfaPolicy', { companyId: data.companyID });

            data.mfaPolicyStatus = isMfaPolicyEnforced ? UserMfaStatuses.NotConfigured : UserMfaStatuses.Disabled;
            data.isAllowedToSwitchMfaPolicyStatus = !isMfaPolicyEnforced;

            onDataChange(data);
        }

        if (data.companyID) {
            checkMfaPolicy();
        }

    }, [data.companyID]);

    useEffect(() => {
        async function checkRepositories() {
            const { items: repositories }: any = await transportService.request('React/GetTenantRepositories', { companyId: data.companyID });

            data.repositories = repositories.length > 0 ? repositories as Repository[] : [];

            onDataChange(data);
        }

        if (data.companyID) {
            checkRepositories();
        }

    }, [data.companyID]);


    return (
        <StepLayout
            title={langs.COMPANY}
            description={langs.SELECT_COMPANY_THAT_WILL_BE_ASSIGNED}
        >
            <CompanyGrid {...stepData}/>
        </StepLayout>
    );
};

export const getCompanyStep = (title: string): WizardStep<CompanyUserModel> => ({
    title,
    isHidden: ({ isEdit, data }) => data.isCompanyAdminPortal || isEdit,
    validate: ({ data }) => !!data.companyID,
    render: data => <Company {...data} />,
});
