/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SubscriptionPlanCloudBackup } from './subscriptionPlanCloudBackup';
import { SubscriptionPlanCloudReplication } from './subscriptionPlanCloudReplication';
import { SubscriptionPlanFileShareBackup } from './subscriptionPlanFileShareBackup';
import { SubscriptionPlanManagedBackup } from './subscriptionPlanManagedBackup';

export interface SubscriptionPlan { 
    /**
     * UID assigned to a subscription plan.
     */
    readonly instanceUid?: string;
    /**
     * Name of a subscription plan.
     */
    name: string;
    /**
     * Name of an organization whose user created a subscription plan.
     */
    readonly organizationUid?: string;
    /**
     * Type of subscription plan.
     */
    readonly type?: SubscriptionPlan.TypeEnum;
    /**
     * Description of a subscription plan.
     */
    description?: string;
    /**
     * Currency chosen for a subscription plan.
     */
    currency: string;
    /**
     * Tax type specified for a subscription plan.
     */
    taxType: SubscriptionPlan.TaxTypeEnum;
    /**
     * Tax amount, in percent.
     */
    taxPercent: number;
    /**
     * Discount amount, in percent.
     */
    discountPercent: number;
    /**
     * Charge rates for managed backup services.
     */
    managedBackup?: SubscriptionPlanManagedBackup;
    /**
     * Charge rates for resources consumed by cloud VM replicas.
     */
    cloudReplication?: SubscriptionPlanCloudReplication;
    /**
     * Charge rates for managed file share backup services.
     */
    fileShareBackup?: SubscriptionPlanFileShareBackup;
    /**
     * Charge rates for storing backups in cloud repositories.
     */
    cloudBackup?: SubscriptionPlanCloudBackup;
}
export namespace SubscriptionPlan {
    export type TypeEnum = 'Unknown' | 'Predefined' | 'Provider' | 'Reseller';
    export const TypeEnum = {
        Unknown: 'Unknown' as TypeEnum,
        Predefined: 'Predefined' as TypeEnum,
        Provider: 'Provider' as TypeEnum,
        Reseller: 'Reseller' as TypeEnum
    };
    export type TaxTypeEnum = 'Unknown' | 'VAT' | 'GST' | 'SalesTax';
    export const TaxTypeEnum = {
        Unknown: 'Unknown' as TaxTypeEnum,
        VAT: 'VAT' as TaxTypeEnum,
        GST: 'GST' as TaxTypeEnum,
        SalesTax: 'SalesTax' as TaxTypeEnum
    };
}