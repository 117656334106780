/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AsyncActionInfo { 
    /**
     * UID assigned to an async action.
     */
    id: string;
    /**
     * UID assigned to a user who initiates an async action.
     */
    initiatorUid: string;
    /**
     * Name of async action. Used only for debug purposes.
     */
    actionName: string;
    /**
     * Current stage of a long running action.
     */
    status: AsyncActionInfo.StatusEnum;
}
export namespace AsyncActionInfo {
    export type StatusEnum = 'running' | 'succeed' | 'canceled' | 'failed';
    export const StatusEnum = {
        Running: 'running' as StatusEnum,
        Succeed: 'succeed' as StatusEnum,
        Canceled: 'canceled' as StatusEnum,
        Failed: 'failed' as StatusEnum
    };
}