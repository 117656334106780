import { StepLayout } from 'components/layouts/StepLayout';
import React from 'react';
import type { WizardStep } from '@veeam-vspc/components';
import { StackView, STACK_GAP, STACK_DIRECTION, ToggleKit } from '@veeam-vspc/components';
import { UserMfaStatuses } from 'features/RolesAndUsers/wizards/Base/components/MultiFactorAuthentication/user-mfa-statuses';
import type { MultiFactorAuthModel } from './multi-factor-auth-model';
import type { WizardStepData } from '@veeam-vspc/components/src/index';
import { useLangs } from 'views/layouts/LangLayout/hooks';
import { NoteBar } from 'components/layouts/NoteBar';

export const MultiFactorAuth: React.FC<WizardStepData<MultiFactorAuthModel>> = ({ data, isEdit, onDataChange }) => {
    const langs = useLangs();

    return (
        <StepLayout
            title={langs.MULTI_FACTOR_AUTHENTICATION}
            description={langs.CONFIGURE_MFA_ACCESS}
        >
            <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.m}>
                <ToggleKit
                    value={data.mfaPolicyStatus === UserMfaStatuses.NotConfigured || data.mfaPolicyStatus === UserMfaStatuses.Enabled}
                    onChange={(isMfaEnabled) => {
                        if (isMfaEnabled) {
                            data.mfaPolicyStatus = UserMfaStatuses.Enabled;
                        } else {
                            data.mfaPolicyStatus = UserMfaStatuses.Disabled;
                        }

                        onDataChange(data);
                    }}
                    showSuffix={true}
                    titles={{ on: langs.ENFORCE_THE_MFA_ACCESS, off: langs.ENFORCE_THE_MFA_ACCESS }}
                    disabled={!data.isAllowedToSwitchMfaPolicyStatus}
                />
                <NoteBar>
                    {isEdit ?
                        <>
                            <p>{langs.UPON_ENABLING_THIS_OPTION_USER_WILL_BE_ASKED}</p>
                            <p>{langs.IF_THIS_USER_IS_LEVERAGING_BACKUP_PORTAL}</p>
                            <p>{langs.TO_RESOLVE_THAT_THIS_CLIENT_NEEDS_TO_START}</p>
                        </> :
                        <p>{langs.UPON_ENABLING_THIS_OPTION_USER_WILL_BE_ASKED}</p>
                    }
                </NoteBar>
            </StackView>
        </StepLayout>
    );
};

export const getMultiFactorAuthStep = (title: string): WizardStep<MultiFactorAuthModel> => ({
    title,
    render: data => <MultiFactorAuth {...data} />,
});
