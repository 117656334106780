/**
 * VSPC REST
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WindowsBackupRepositoryTarget } from './windowsBackupRepositoryTarget';
import { WindowsCloudRepositoryTarget } from './windowsCloudRepositoryTarget';
import { WindowsSharedFolderTarget } from './windowsSharedFolderTarget';

export interface WindowsBackupTarget { 
    /**
     * Target location for the created backup. > To store entire computer backups on the `LocalFolder` target location, you must use an external drive. > The `OneDrive` target type cannot be assigned using RESTful API. 
     */
    targetType: WindowsBackupTarget.TargetTypeEnum;
    /**
     * Path to the folder where backup files must be stored. > Required for the `LocalFolder` target location.  
     */
    localPath?: string;
    /**
     * Shared folder settings. > Required for the `SharedFolder` target location. 
     */
    sharedFolder?: WindowsSharedFolderTarget;
    /**
     * Veeam Backup & Replication repository settings. > Required for the `BackupRepository` target location. 
     */
    backupRepository?: WindowsBackupRepositoryTarget;
    /**
     * Cloud repository settings. > Optional for the `CloudRepository` target location. 
     */
    cloudRepository?: WindowsCloudRepositoryTarget;
}
export namespace WindowsBackupTarget {
    export type TargetTypeEnum = 'Unknown' | 'LocalFolder' | 'SharedFolder' | 'BackupRepository' | 'CloudRepository' | 'OneDrive';
    export const TargetTypeEnum = {
        Unknown: 'Unknown' as TargetTypeEnum,
        LocalFolder: 'LocalFolder' as TargetTypeEnum,
        SharedFolder: 'SharedFolder' as TargetTypeEnum,
        BackupRepository: 'BackupRepository' as TargetTypeEnum,
        CloudRepository: 'CloudRepository' as TargetTypeEnum,
        OneDrive: 'OneDrive' as TargetTypeEnum
    };
}