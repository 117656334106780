import React from 'react';
import { observer } from 'mobx-react-lite';
import { BaseForm, LOGIN_FORM_WIDTH } from '@veeam-vspc/components';

import { useLangs } from 'views/layouts/LangLayout/hooks';

export interface BaseFactorProps {
    children: React.ReactElement | React.ReactElement[];
    alternativeButton?: React.ReactElement;
    width?: string;
}

export const BaseFactor = observer((props: BaseFactorProps) => {
    const langs = useLangs();
    const { children, alternativeButton, width = `${LOGIN_FORM_WIDTH}px` } = props;

    return (
        <BaseForm
            width={width}
            alternativeButton={alternativeButton}
            beforeBtnText={langs.OR}
        >
            {children}
        </BaseForm>
    );
});
